import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Drawer,
  Row,
  Col,
  Form,
  Upload,
  Input,
  Pagination,
  Select,
  message,
} from "antd";
import Search from "../components/common/Search";
import FormSelect from "../components/common/Select";
import FormInput from "../components/common/FormInput";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDatePicker from "../components/common/FormDatePicker";
import Images from "../themes/Images";
import { city, area } from "../utils/location";
import { PlusOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import config from "../config";

import "./Member.less";
import _ from "lodash";
import moment from "moment";

const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
];

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const normFile = (e) => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

// const roleList = [
//   {
//     id: "M002",
//     name: "管理員",
//   },
//   {
//     id: "M003",
//     name: "老師",
//   },
//   {
//     id: "M004",
//     name: "學士學生",
//   },
//   {
//     id: "M005",
//     name: "碩士學生",
//   },
// ];
let timer;

let hide = undefined;
const App = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [imageData, setImageData] = useState([]);
  const [mode, setMode] = useState("create");
  const [search, setSearch] = useState("");
  const [school, setSchool] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    let temp = [];
    city.map((item) => {
      temp.push({
        id: item,
        name: item,
      });
    });
    setCityList(temp);
    handleGetData();
    props.getRoleList();
    props.getSchoolListByForm({}, setIsLoading);
  }, []);

  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: "",
      page: 1,
      pageCount: 12,
      school: null,
      orderKeys: [],
    };
    if (param.get("search") !== null) {
      payload.search = param.get("search");
      setSearch(param.get("search"));
    }
    if (param.get("page") !== null) {
      payload.page = parseInt(param.get("page"));
    }
    if (param.get("school") !== null && param.get("school") !== "") {
      setSchool(param.get("school"));
      payload.school = param.get("school");
    }
    if (param.get("pageCount") !== null) {
      payload.pageCount = parseInt(param.get("pageCount"));
    }
    props.getMemberList(payload, setIsLoading);
  };

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleAvatarChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      setImageData(newFileList.splice(1, 1));
    } else {
      setImageData(newFileList);
    }
  };

  const handleCreate = (value) => {
    const { handleCreateMember, paging } = props;
    let formData = new FormData();
    formData.append("member_name", value.member_name);
    formData.append(
      "member_nickname",
      value.member_nickname !== undefined ? value.member_nickname : ""
    );
    formData.append(
      "member_email",
      value.member_email !== undefined ? value.member_email : ""
    );
    formData.append("member_account", value.member_account);
    formData.append("member_password", value.member_password);
    formData.append("member_remark", "");
    formData.append("role_id", value.role_id);
    formData.append("member_gender", 2);
    formData.append(
      "member_birth",
      value.member_birth !== undefined
        ? value.member_birth.format("YYYY-MM-DD")
        : ""
    );
    formData.append(
      "member_city",
      value.member_city !== undefined ? value.member_city : ""
    );
    formData.append(
      "member_area",
      value.member_area !== undefined ? value.member_area : ""
    );
    formData.append(
      "member_address",
      value.member_address !== undefined ? value.member_address : ""
    );
    formData.append(
      "member_nickname",
      value.member_nickname !== undefined ? value.member_nickname : ""
    );
    formData.append(
      "school_id",
      value.school_id !== undefined ? value.school_id : ""
    );
    formData.append(
      "grade_id",
      value.grade_id !== undefined ? value.grade_id : ""
    );
    if (imageData.length === 1) {
      formData.append("avatar", imageData[0].originFileObj);
    }

    const callback = () => {
      setDrawerVisible(false);
      setImageData([]);
    };
    let getData = {
      school: null,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
      orderKeys: [],
    };

    handleCreateMember(formData, setIsLoading, callback, getData);
  };

  const handleUpdate = (value) => {
    const { handleUpdateMember, paging } = props;
    let formData = new FormData();

    formData.append("member_id", currentData.member_id);
    formData.append("member_name", value.member_name);
    if (props.roleId === "M001") {
      formData.append("role_id", value.role_id);
    }
    formData.append(
      "member_nickname",
      value.member_nickname !== null ? value.member_nickname : ""
    );
    formData.append(
      "member_email",
      value.member_email !== null ? value.member_email : ""
    );
    formData.append("member_remark", "");
    formData.append("role_id", currentData.role_id);
    formData.append("member_gender", 2);
    formData.append(
      "member_birth",
      value.member_birth !== null && value.member_birth !== undefined
        ? value.member_birth.format("YYYY-MM-DD")
        : ""
    );
    formData.append(
      "member_city",
      value.member_city !== null ? value.member_city : ""
    );
    formData.append(
      "member_area",
      value.member_area !== null ? value.member_area : ""
    );
    formData.append(
      "member_address",
      value.member_address !== null ? value.member_address : ""
    );
    formData.append(
      "member_nickname",
      value.member_nickname !== null ? value.member_nickname : ""
    );
    if (value.school_id !== null && value.school_id !== undefined) {
      formData.append("school_id", value.school_id);
    }
    if (value.grade_id !== null && value.grade_id !== undefined) {
      formData.append("grade_id", value.grade_id);
    }
    if (imageData.length === 1) {
      if (_.has(imageData[0], "originFileObj")) {
        formData.append("avatar", imageData[0].originFileObj);
      } else {
      }
      formData.append("is_have", true);
    } else {
      formData.append("is_have", false);
    }

    const callback = () => {
      setDrawerVisible(false);
      setImageData([]);
      setCurrentData({});
    };
    let getData = {
      school: null,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
      orderKeys: [],
    };

    handleUpdateMember(formData, setIsLoading, callback, getData);
  };

  const handleUpdateAccount = (value) => {
    const { handleUpdateAccount, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setImageData([]);
      setCurrentData({});
    };
    let getData = {
      school: null,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
      orderKeys: [],
    };
    let payload = {
      member_account: value.member_account,
      role_id: value.role_id,
      member_id: currentData.member_id,
      member_new_password: "",
    };

    if (
      value.member_new_password !== "" &&
      value.member_new_password !== undefined
    ) {
      payload.member_new_password = value.member_new_password;
    }

    handleUpdateAccount(payload, setIsLoading, callback, getData);
  };

  const handleFormChange = (change, all) => {
    if (_.has(change, "member_city")) {
      area.map((item) => {
        if (item.key === change.member_city) {
          let temp = [];
          item.list.map((child) => {
            temp.push({ id: child, name: child });
          });
          createForm.current.setFieldsValue({ member_area: undefined });
          setAreaList(temp);
        }
      });
    }
    if (_.has(change, "school_id")) {
      props.getGradeBySchoolId(change.school_id, setIsLoading);
      createForm.current.setFieldsValue({ grade_id: undefined });
    }
    setFormValue(all);
  };

  const renderDrawerView = () => {
    if (mode === "view") {
    } else if (mode === "create") {
      return drawerVisible && renderCreateForm();
    } else if (mode === "updateDetail") {
      return drawerVisible && renderUpdateDetailForm();
    } else if (mode === "updateAccount") {
      return drawerVisible && renderUpdateAccountForm();
    }
  };

  const handleDeleteMember = (id) => {
    const { handleDeleteMember, paging } = props;
    let getData = {
      search,
      school,
      page: paging.page,
      pageCount: paging.pageCount,
      orderKeys: [],
    };

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: "#42C1B4",
    }).then((result) => {
      if (result.value) {
        handleDeleteMember(id, setIsLoading, () => {}, getData);
      }
    });
  };

  const handleShowUpdate = (data) => {
    const { getMemberById } = props;
    const callback = (value) => {
      setMode("updateDetail");
      if (value.member_avatar !== null) {
        setImageData([
          {
            uid: "1",
            name: "img",
            status: "done",
            url: value.member_avatar,
          },
        ]);
      }
      if (value.school_id !== null && value.school_id !== "") {
        props.getGradeBySchoolId(value.school_id);
      }
      setCurrentData({
        ...value,
        member_birth:
          value.member_birth !== null ? moment(value.member_birth) : undefined,
      });
      setFormValue({
        ...value,
        member_birth:
          value.member_birth !== null ? moment(value.member_birth) : undefined,
      });
      setDrawerVisible(true);
    };
    getMemberById(data.member_id, setIsLoading, callback);
  };

  const handleShowUpdateAccount = (data) => {
    const { getMemberById } = props;
    const callback = (value) => {
      setMode("updateAccount");
      setCurrentData(value);
      setFormValue(value);
      setDrawerVisible(true);
    };
    getMemberById(data.member_id, setIsLoading, callback);
  };
  const renderUpdateDetailForm = () => {
    return (
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...currentData,
        }}
        style={{ marginTop: "16px" }}
        onValuesChange={handleFormChange}
        onFinish={handleUpdate}
      >
        <Row
          gutter={24}
          className="scrollList"
          style={{
            height: `${props.screenHeight - 194}px`,
            marginRight: "0px",
            paddingBottom: "8px",
          }}
        >
          <Col span={24}>
            <Form.Item valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                listType="picture-card"
                accept=".PNG,.JPG,.JPEG"
                fileList={imageData}
                onChange={handleAvatarChange}
                customRequest={dummyRequest}
                beforeUpload={(file) => {
                  const isJPG =
                    file.type === "image/jpeg" || file.type === "image/png";
                  if (!isJPG) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8, color: props.color.textColor }}>
                    {props.i18n.upload}
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormInput
              name="member_name"
              required
              title={props.i18n.member_name}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_name}`}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name="member_nickname"
              title={props.i18n.member_nick_name}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_nick_name}`}
            />
          </Col>
          <Col span={12}>
            <FormDatePicker
              title={props.i18n.member_birth}
              name="member_birth"
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_birth}`}
            />
          </Col>
          <Col span={12}>
            {props.roleId === 'M001' && <FormInputSelect
              name="role_id"
              required
              options={props.roleList}
              title={props.i18n.member_role}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_role}`}
            />}
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="school_id"
              options={props.schoolFormList}
              title={props.i18n.member_school}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_school}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="grade_id"
              options={props.gradeList}
              title={props.i18n.member_grade}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_grade}`}
            />
          </Col>
          <Col span={24}>
            <FormInput
              type="email"
              required
              name="member_email"
              title={props.i18n.member_email}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_email}`}
            />
          </Col>
          <Col
            span={24}
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: props.color.inputTitleColor,
              marginBottom: "12px",
            }}
          >
            <div style={{ marginLeft: "16px" }}>
              {props.i18n.member_addr_title}
            </div>
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="member_city"
              options={cityList}
              title={props.i18n.member_city}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_city}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="member_area"
              options={areaList}
              title={props.i18n.member_area}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_area}`}
            />
          </Col>
          <Col span={24}>
            <FormInput
              name="member_address"
              title={props.i18n.member_address}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_address}`}
            />
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "30px", width: "100%", textAlign: "center" }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderDrawerTitle = () => {
    if (mode === "create") {
      return props.i18n.member_create;
    } else if (mode === "updateDetail") {
      return props.i18n.member_update;
    } else if (mode === "updateAccount") {
      return props.i18n.member_update_account;
    }
  };
  const renderUpdateAccountForm = () => {
    return (
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...currentData,
        }}
        style={{ marginTop: "16px" }}
        onValuesChange={handleFormChange}
        onFinish={handleUpdateAccount}
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormInput
              name="member_account"
              required
              title={props.i18n.member_account}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_account}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="role_id"
              required
              options={props.roleList}
              title={props.i18n.member_role}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_role}`}
            />
          </Col>
          <Col span={12}>
            <FormInput
              required={
                formValue.member_new_password !== "" &&
                formValue.member_new_password !== undefined
              }
              name="member_new_password"
              title={props.i18n.member_new_password}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_new_password}`}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <div
                  style={{
                    color: props.color.inputTitleColor,
                    fontSize: "18px",
                    padding: "0px",
                    fontWeight: "bold",
                  }}
                >
                  {props.i18n.member_new_password_confirm}
                </div>
              }
              name="member_new_password_confirm"
              rules={[
                {
                  required:
                    formValue.member_new_password !== "" &&
                    formValue.member_new_password !== undefined,
                  message: `${props.i18n.placeholder}${props.i18n.member_new_password_confirm}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("member_new_password") === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(props.i18n.member_password_diff)
                    );
                  },
                }),
              ]}
              style={{ width: "100%" }}
              colon={false}
            >
              <Input
                id="formInput"
                autoComplete="new-account"
                style={{
                  borderRadius: "15px",
                  height: "50px",
                  color: "#125346",
                  padding: "0px 16px",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  width: "100%",
                }}
                placeholder={`${props.i18n.placeholder}${props.i18n.member_password_confirm}`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "30px", width: "100%", textAlign: "center" }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderCreateForm = () => {
    return (
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          name: "",
        }}
        style={{ marginTop: "16px" }}
        onValuesChange={handleFormChange}
        onFinish={handleCreate}
      >
        <Row
          gutter={24}
          className="scrollList"
          style={{
            height: `${props.screenHeight - 194}px`,
            marginRight: "0px",
            paddingBottom: "8px",
          }}
        >
          <Col span={24}>
            <Form.Item valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                listType="picture-card"
                accept=".PNG,.JPG,.JPEG"
                fileList={imageData}
                onChange={handleAvatarChange}
                customRequest={dummyRequest}
                beforeUpload={(file) => {
                  const isJPG =
                    file.type === "image/jpeg" || file.type === "image/png";
                  if (!isJPG) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8, color: props.color.textColor }}>
                    {props.i18n.upload}
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormInput
              name="member_name"
              required
              title={props.i18n.member_name}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_name}`}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name="member_nickname"
              title={props.i18n.member_nick_name}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_nick_name}`}
            />
          </Col>
          <Col span={12}>
            <FormDatePicker
              title={props.i18n.member_birth}
              name="member_birth"
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_birth}`}
            />
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <FormInputSelect
              name="school_id"
              options={props.schoolFormList}
              title={props.i18n.member_school}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_school}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="grade_id"
              options={props.gradeList}
              title={props.i18n.member_grade}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_grade}`}
            />
          </Col>
          <Col span={24}>
            <FormInput
              type="email"
              required
              name="member_email"
              title={props.i18n.member_email}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_email}`}
            />
          </Col>
          <Col
            span={24}
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: props.color.inputTitleColor,
              marginBottom: "12px",
            }}
          >
            <div style={{ marginLeft: "16px" }}>
              {props.i18n.member_addr_title}
            </div>
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="member_city"
              options={cityList}
              title={props.i18n.member_city}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_city}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="member_area"
              options={areaList}
              title={props.i18n.member_area}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_area}`}
            />
          </Col>
          <Col span={24}>
            <FormInput
              name="member_address"
              title={props.i18n.member_address}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_address}`}
            />
          </Col>
          <Col
            span={24}
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: props.color.inputTitleColor,
              marginBottom: "12px",
            }}
          >
            <div style={{ marginLeft: "16px" }}>
              {props.i18n.member_account_title}
            </div>
          </Col>
          <Col span={12}>
            <FormInput
              name="member_account"
              required
              title={props.i18n.member_account}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_account}`}
            />
          </Col>
          <Col span={12}>
            <FormInputSelect
              name="role_id"
              required
              options={props.roleList}
              title={props.i18n.member_role}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_role}`}
            />
          </Col>
          <Col span={12}>
            <FormInput
              required
              name="member_password"
              title={props.i18n.member_password}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.member_password}`}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <div
                  style={{
                    color: props.color.inputTitleColor,
                    fontSize: "18px",
                    padding: "0px",
                    fontWeight: "bold",
                  }}
                >
                  {props.i18n.member_password_confirm}
                </div>
              }
              name="member_password_confirm"
              rules={[
                {
                  required: true,
                  message: `${props.i18n.placeholder}${props.i18n.member_password_confirm}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("member_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(props.i18n.member_password_diff)
                    );
                  },
                }),
              ]}
              style={{ width: "100%" }}
              colon={false}
            >
              <Input
                id="formInput"
                autoComplete="new-account"
                style={{
                  borderRadius: "15px",
                  height: "50px",
                  color: "#125346",
                  padding: "0px 16px",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  width: "100%",
                }}
                placeholder={`${props.i18n.placeholder}${props.i18n.member_password_confirm}`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "30px", width: "100%", textAlign: "center" }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const handleSizeChange = (e) => {
    navigate(
      `/member/user?search=${search}${
        school !== undefined ? `&school=${school}` : ""
      }&page=1${e !== 12 ? `&pageCount=${e}` : ""}`
    );
  };

  const handlePageChange = (e) => {
    navigate(
      `/member/user?search=${search}${
        school !== undefined ? `&school=${school}` : ""
      }${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }${e !== 1 ? `&page=${e}` : ""}`
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(
        `/member/user?search=${e}${
          school !== undefined ? `&school=${school}` : ""
        }${
          props.paging.pageCount !== 12
            ? `&pageCount=${props.paging.pageCount}`
            : ""
        }&page=1`
      );
    };
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay);
    }
    debounce(getSearch, 500);
  };

  const handleSchoolChange = (e) => {
    if (e === undefined) {
      setSchool(undefined);
      navigate(
        `/member/user?search=${search}${
          props.paging.pageCount !== 12
            ? `&pageCount=${props.paging.pageCount}`
            : ""
        }&page=1`
      );
    } else {
      setSchool(e);
      navigate(
        `/member/user?search=${search}${e !== undefined ? `&school=${e}` : ""}${
          props.paging.pageCount !== 12
            ? `&pageCount=${props.paging.pageCount}`
            : ""
        }&page=1`
      );
    }
  };

  return (
    <div id="member">
      <Row>
        <Col span={14}>
          <Search
            searchValue={search}
            handleSearch={(e) => handleSearchChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: "24px", width: "254px" }}
            mode="text"
            options={props.schoolFormList}
            value={school}
            onChange={(e) => handleSchoolChange(e)}
          />
        </Col>
        <Col span={10} style={{ textAlign: "right" }}>
          <Upload
            onChange={(e) => {
              const status = _.get(e, "file.status", "");

              if (status === "uploading") {
                if (_.isUndefined(hide)) {
                  hide = message.loading("檔案上傳中...", 0);
                }
              } else if (status === "done") {
                if (hide) hide();
                message.success("上傳成功!");
                hide = undefined;
              } else if (status === "error") {
                if (hide) hide();
                message.error("上傳失敗，請確認檔案格式是否正確!");
                hide = undefined;
              }
            }}
            showUploadList={false}
            method="post"
            action={`${config}/Member/import`}
            name="file"
            accept=".xls,.xlsx,.csv"
          >
            <Button
              style={{
                width: "128px",
                height: "50px",
                textAlign: "center",
                color: "white",
                backgroundColor: "#4A9E8F",
                border: `2px solid ${props.color.createBtnBorder}`,
                borderRadius: "15px",
                fontSize: "18px",
                marginRight: "24px",
              }}
            >
              {props.i18n.import_btn}
            </Button>
          </Upload>
          <Button
            onClick={() => {
              setCurrentData({});
              setMode("create");
              setDrawerVisible(true);
            }}
            style={{
              width: "128px",
              height: "50px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#4A9E8F",
              border: `2px solid ${props.color.createBtnBorder}`,
              borderRadius: "15px",
              fontSize: "18px",
            }}
          >
            {props.i18n.create_btn}
          </Button>
        </Col>
      </Row>
      <Row
        gutter={24}
        className="scrollList"
        style={{
          height: `${props.screenHeight - 263}px`,
          marginRight: "0px",
          paddingBottom: "8px",
        }}
      >
        {props.memberList.map((item) => {
          return (
            <Col
              span={8}
              style={{
                // height: '225px',
                width: "100%",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  backdropFilter: "blur(30px) brightness(1.5)",
                }}
              >
                <div style={{ display: "flex", padding: "24px 24px 0px 24px" }}>
                  <img
                    alt="avatar"
                    src={item.member_avatar || Images.avatar}
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "15px",
                      marginRight: "16px",
                    }}
                  />
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    <div
                      style={{ fontSize: "18px", color: props.color.textColor }}
                    >
                      {item.member_name}
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: props.color.textColor,
                        height: "29px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        wordBreak: "break-all",
                        width: "100%",
                      }}
                    >
                      {item.school_name} {item.grade_name}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#26695C",
                        margin: "4px 0px",
                      }}
                    ></div>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="user"
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.user_icon_dark
                              : Images.user_icon
                          }
                          style={{
                            width: "15px",
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: "16px",
                            color: props.color.textColor,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordBreak: "keep-all",
                          }}
                        >
                          {item.member_account}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="user"
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.address_icon_dark
                              : Images.address_icon
                          }
                          style={{
                            width: "13px",
                            height: "17px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          title={
                            item.member_city +
                            item.member_area +
                            item.member_address
                          }
                          style={{
                            fontSize: "16px",
                            color: props.color.textColor,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordBreak: "keep-all",
                          }}
                        >{`${
                          item.member_city +
                          item.member_area +
                          item.member_address
                        }`}</div>
                      </div>
                    </div>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="user"
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.birth_icon_dark
                              : Images.birth_icon
                          }
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: "16px",
                            color: props.color.textColor,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.member_birth !== null
                            ? moment(item.member_birth).format("MM/DD")
                            : "-"}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="user"
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.email_icon_dark
                              : Images.email_icon
                          }
                          style={{
                            width: "14px",
                            height: "11px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          title={item.member_email}
                          style={{
                            fontSize: "16px",
                            color: props.color.textColor,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordBreak: "keep-all",
                          }}
                        >
                          {item.member_email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#26695C",
                    margin: "4px 0px",
                  }}
                ></div>
                <div
                  style={{
                    height: "64px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 24px",
                    backgroundColor: "#ACF8EF",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: "18px", color: "#26695C" }}>
                    {props.i18n.member_role}：{item.role_name}
                  </div>
                  <div style={{ display: "flex" }}>
                    {/* <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#4A9E8F',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleShowUpdateAccount(item)}
                  >
                    <img alt="lock" src={Images.lock_closed} style={{ width: '15px', height: '20px'}} />
                  </Button> */}
                    {/* <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#4A9E8F',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                  </Button> */}
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#4A9E8F",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowUpdate(item)}
                    >
                      <img
                        alt="edit"
                        src={Images.edit}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#D53838",
                        border: `1px solid #F31616`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleDeleteMember(item.member_id)}
                    >
                      <img
                        alt="delete"
                        src={Images.delete}
                        style={{ width: "19.4px", height: "19.4px" }}
                      />
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    height: "39px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 24px",
                    backgroundColor: "rgba(66,193,180,0.32)",
                    alignItems: "center",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                >
                  <div
                    style={{ fontSize: "18px", color: props.color.textColor }}
                  >
                    {props.i18n.login_count} {item.login_count}
                  </div>
                  <div
                    style={{ fontSize: "18px", color: props.color.textColor }}
                  >
                    {props.i18n.login_time} {item.login_time}{" "}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <div
        className="pagination"
        style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          defaultCurrent={props.paging.page}
          total={props.paging.total}
          current={props.paging.page}
          pageSize={props.paging.pageCount}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
        <Select
          id="selectList"
          style={{ width: "112px", marginLeft: "16px" }}
          defaultValue={props.paging.pageCount}
          value={props.paging.pageCount}
          onChange={handleSizeChange}
        >
          {sizeList.map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {item.name} / {props.i18n.page}
            </Select.Option>
          ))}
        </Select>
      </div>

      <Drawer
        placement="right"
        className={
          props.color.currentTheme === "dark" ? "dark_member" : "light_member"
        }
        closable={false}
        maskClosable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={Images.close}
            alt="close"
            style={{ width: "65px", height: "74px", cursor: "pointer" }}
            onClick={() => setDrawerVisible(false)}
          />
          <div
            style={{
              fontSize: "24px",
              color: props.color.inputDrawerTitleColor,
              marginLeft: "30px",
            }}
          >
            {renderDrawerTitle()}
          </div>
        </div>
        {renderDrawerView()}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, "Global.theme", "light"),
    themeMode: _.get(state, "Global.themeMode", "dark"),
    color: _.get(state, "Global.color", {}),
    i18n: _.get(state, "Global.i18n", {}),
    i18nMode: _.get(state, "Global.i18nMode", {}),
    memberList: _.get(state, "Member.memberList", []),
    screenHeight: _.get(state, "Global.screenHeight", 0),
    paging: _.get(state, "Member.paging", {}),
    schoolFormList: _.get(state, "School.schoolFormList", []),
    gradeList: _.get(state, "School.gradeList", []),
    roleList: _.get(state, "User.roleList", []),
    roleId: _.get(state, "User.userData.role_id", ''),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateMember(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_CREATE_MEMBER",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleUpdateMember(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_UPDATE_MEMBER",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleUpdateAccount(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_UPDATE_ACCOUNT",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleDeleteMember(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_DELETE_MEMBER",
        payload,
        loading,
        callback,
        getData,
      });
    },
    getMemberList(payload, loading, callback) {
      dispatch({ type: "GET_MEMBER_LIST", payload, loading, callback });
    },
    getMemberById(payload, loading, callback) {
      dispatch({ type: "GET_MEMBER_BY_ID", payload, loading, callback });
    },
    getSchoolListByForm(payload, loading, callback) {
      dispatch({ type: "GET_SCHOOL_LIST_BY_FORM", payload, loading, callback });
    },
    getGradeBySchoolId(payload, loading, callback) {
      dispatch({ type: "GET_GRADE_BY_SCHOOL_ID", payload, loading, callback });
    },
    getRoleList(payload, loading, callback) {
      dispatch({ type: "GET_ROLE_LIST", payload, loading, callback });
    },
    handleImportSchoolMember(payload, loading, callback) {
      dispatch({
        type: "HANDLE_IMPORT_SCHOOL_MEMBER",
        payload,
        loading,
        callback,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
