import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Drawer, Upload } from 'antd';
import FormInput from "../components/common/FormInput";
import FormTextarea from "../components/common/FormTextarea";
import HtmlEditor from "../components/common/HtmlEditor";
import FormDatePicker from "../components/common/FormDatePicker";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import DataTable from "../components/common/DataTable";
import Spinner from "../components/common/Spinner";

import ReactPlayer from 'react-player'
import Images from "../themes/Images";
import "./TalkUpdate.less";
import Swal from 'sweetalert2';
import _, { isObject } from "lodash";
import moment from "moment";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const TalkUpdate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    is_random: false,
    is_practice: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [isSellValue, setIsSellValue] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [key, setKey] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [init, setInit] = useState(false);


  useEffect(() => {
    props.getTagFormList(11);
    let url = window.location.pathname.split('/knowledge/talk/update/')[1];
    const callback = (value) => {
      let tempTag = [];
      value.tags.map((item) => {
        tempTag.push(item.tag_id);
      });
      let tempAbility = [];
      value.abilities.map((item) => {
        tempAbility.push(item.ability_id);
      });
      setFormValue({
        ...value,
        talk_time: value.talk_time !== null ? moment(value.talk_time) : null,
        tags: tempTag,
        abilities: tempAbility,
      });
      setIsSellValue(value.is_sell);
      setTimeout(() => {
        setInit(true);
      }, 100);
    }
    props.getTalkById(url, setIsLoading, callback);
  }, []);

  useEffect(() => {
    setAudioUrl(props.talkDetail.talk_video);
    setImageUrl(props.talkDetail.talk_img);
  }, [props.talkDetail]);
  
  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList, file: newFile }, key) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      if (key === 'audio') {
        createForm.current.setFieldsValue({ audio: temp });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAudioUrl(reader.result);
        };
        reader.readAsDataURL(newFile);
        setAudioData(temp);
        handleUploadAudio(newFile);
      } else if (key === 'image') {
        createForm.current.setFieldsValue({ img: temp });
        const reader = new FileReader();
        if (newFile.status === 'done') {
          reader.readAsDataURL(newFile.originFileObj);
          reader.onload = () => {
            setImageUrl(reader.result);
          };
          handleUploadImg(newFile);
        }
        setImageData(temp);
      } else if (key === 'cc') {
        handleUploadCC(newFile);
      }
    } else {
      if (key === 'audio') {
        createForm.current.setFieldsValue({ audio: newFileList });
        const reader = new FileReader();
        reader.onloadend = () => {
          setAudioUrl(reader.result);
        };
        reader.readAsDataURL(newFile);
        setAudioData(newFileList);
        handleUploadAudio(newFile);
      } else if (key === 'image')  {
        createForm.current.setFieldsValue({ img: newFileList });
        const reader = new FileReader();
        if (newFile.status === 'done') {
          reader.readAsDataURL(newFile.originFileObj);
          reader.onload = () => {
            setImageUrl(reader.result);
          };
          handleUploadImg(newFile);
        }
        setImageData(newFileList);
      } else if (key === 'cc') {
        handleUploadCC(newFile);
      }
    }
  };
  
  const handleUploadAudio = (file) => {
    let formData = new FormData();
    formData.append('talk_id', props.talkDetail.talk_id);
    formData.append('file', file);
    props.handleUploadAudio(formData, setIsLoading, () => {}, props.talkDetail.talk_id);
  }

  
  const handleUploadImg = (file) => {
    let formData = new FormData();
    formData.append('talk_id', props.talkDetail.talk_id);
    formData.append('file', file.originFileObj);
    props.handleUploadImg(formData, setIsLoading, () => {}, props.talkDetail.talk_id);
  }

  const handleUploadCC = (file) => {
    let formData = new FormData();
    formData.append('talk_id', props.talkDetail.talk_id);
    formData.append('file', file.originFileObj);
    props.handleImportCC(formData, setIsLoading);
  }

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 11,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleCancel = () => {
    Swal.fire({
      title: props.i18n.update_cancel,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        navigate('/knowledge/talk');
      }
    });
  }

  const handleGetTalkCC = (key) => {
    setKey(key);
    const callback = () => {
      setDrawerVisible(true);
    }
    props.getTalkCC(props.talkDetail.talk_id, setIsLoading, callback);
  }

  const renderDrawerTitle = () => {
    if (key === 'en') {
      return props.i18n.talk_en_cc_view;
    } else if (key === 'zh') {
      return props.i18n.talk_zh_cc_view;
    } else if (key === 'module') {
      return props.i18n.talk_module;
    }
  }

  const handleUpdateModule = (value) => {
    const callback = () => {
      setDrawerVisible(false);
    }
    props.handleUpdateTalkModule({
      ...value,
      practice_times: parseInt(value.practice_times),
      exam_score: parseInt(value.exam_score),
      talk_id: props.talkDetail.talk_id,
    }, setIsLoading, callback);
  }
  
  const handleUploadTalk = (bool) => {
    let value = JSON.parse(JSON.stringify(formValue));
    if (_.has(value, 'audio')) {
      delete value.audio;
    }
    if (_.has(value, 'img')) {
      delete value.img;
    }
    const callback = () => {
      if (isSellValue !== props.talkDetail.is_sell) {
        const sellCallback = () => {
          if (bool) {
            navigate('/knowledge/talk');
          }
        }
        props.handleChangeTalkSell(props.talkDetail.talk_id, setIsLoading, sellCallback);
      } else {
        if (bool) {
          navigate('/knowledge/talk');
        }
      }
    }


    props.handleUpdateTalk({
      ...value,
      talk_time: _.isObject(value.talk_time) ? value.talk_time.format('YYYY-MM-DD') : value.talk_time,
      talk_length: parseInt(value.talk_length),
      talk_desc: _.isObject(formValue.talk_desc) ? formValue.talk_desc.toHTML() : '',
      talk_id: props.talkDetail.talk_id,
      talk_types: [],
      is_sell: isSellValue,
    }, setIsLoading, callback);
  }

  const handleFormChange = (change, all) => {
    setFormValue(all);
  }

  const enColumns = [
    {
      title: props.i18n.talk_cc_start,
      dataIndex: 'talk_cc_start',
      key: 'talk_cc_start',
      width: '100px',
    },
    {
      title: props.i18n.talk_cc_end,
      dataIndex: 'talk_cc_end',
      key: 'talk_cc_end',
      width: '100px',
    },
    {
      title: props.i18n.talk_cc_desc,
      dataIndex: 'talk_cc_eng',
      key: 'talk_cc_eng',
    },
    {
      title: props.i18n.talk_cc_audio,
      dataIndex: 'talk_cc_audio',
      key: 'talk_cc_audio',
      width: '320px',
      render: (value, record) => <div>
        <ReactPlayer width="300px" height="52px" controls url={value} />
      </div>
    },
  ];
  const zhColumns = [
    {
      title: props.i18n.talk_cc_start,
      dataIndex: 'talk_cc_start',
      key: 'talk_cc_start',
      width: '100px',
    },
    {
      title: props.i18n.talk_cc_end,
      dataIndex: 'talk_cc_end',
      key: 'talk_cc_end',
      width: '100px',
    },
    {
      title: props.i18n.talk_cc_desc,
      dataIndex: 'talk_cc_chinese',
      key: 'talk_cc_chinese',
    },
  ];
  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px' }} className="scrollList">
      {!init ? <Spinner mode={props.themeMode} />: <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue
        }}
        onValuesChange={handleFormChange}
        // onFinish={handleUploadTalk}
    >
      <Row gutter={24} style={{ marginRight: '0px' }}>
        <Col span={12}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding:'0px',
              fontWeight: 'bold',
              marginBottom: '16px',
            }}>{props.i18n.talk_video_upload}
          </div>
          {!_.isEmpty(audioUrl) ? <ReactPlayer controls url={audioUrl} style={{ marginBottom: '12px' }} /> : <video controls style={{ width: '100%', marginBottom: '12px'}} />}
          <Form.Item 
            valuePropName="audio"
            name="audio"
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="text"
              accept=".mp4,.avi"
              fileList={audioData}
              showUploadList={false}
              onChange={e => handleImageChange(e, 'audio')}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <Button
                style={{
                  height: '32px',
                  backgroundColor: '#4A9E8F',
                  padding: '0px 24px',
                  borderRadius: '15px',
                  color: 'white',
                  border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                }}
                loading={isLoading}
              >{props.i18n.talk_video_upload}</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding:'0px',
              fontWeight: 'bold',
              marginBottom: '16px',
            }}>{props.i18n.talk_video_cover_upload}
          </div>
          <div style={{ maxWidth: '500px', height: '280px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {!_.isEmpty(imageUrl) && <img src={imageUrl} style={{ height: 'auto', maxWidth: '100%', maxHeight: '100%'}} alt="cover" />}
          </div>
          <Form.Item 
            valuePropName="img"
            name="img"
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="text"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              showUploadList={false}
              onChange={e => handleImageChange(e, 'image')}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <Button
                style={{
                  height: '32px',
                  backgroundColor: '#4A9E8F',
                  padding: '0px 24px',
                  borderRadius: '15px',
                  color: 'white',
                  border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                }}
                loading={isLoading}
              >{props.i18n.talk_img}</Button>
            </Upload>
          </Form.Item>
          <div style={{ display: 'flex', marginTop: '16px' }}>
            
          <Form.Item 
            valuePropName="img"
            name="img"
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="text"
              accept=".xlsx"
              // fileList={imageData}
              showUploadList={false}
              onChange={e => handleImageChange(e, 'cc')}
              onPreview={handlePreview}
              customRequest={dummyRequest}
            >
                <Button
                  style={{
                    height: '32px',
                    backgroundColor: '#EE6C6C',
                    padding: '0px 24px',
                    borderRadius: '15px',
                    color: 'white',
                    border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4',
                    marginRight: '200px'
                  }}
                  loading={isLoading}
                >{props.i18n.talk_cc_import}</Button>
            </Upload>
          </Form.Item>
            <Button
              style={{
                height: '32px',
                backgroundColor: '#1A71E2',
                padding: '0px 24px',
                borderRadius: '15px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              }}
              loading={isLoading}
              onClick={() => {
                setKey('module');
                setDrawerVisible(true);
              }}
            >{props.i18n.talk_module}</Button>
          </div>
          <div style={{ display: 'flex', marginTop: '16px'}}>
            <Button
              style={{
                height: '32px',
                backgroundColor: '#5EAF21',
                padding: '0px 24px',
                borderRadius: '15px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              }}
              loading={isLoading}
              onClick={() => handleGetTalkCC('en')}
            >{props.i18n.talk_en_cc_view}</Button>
            <Button
              style={{
                height: '32px',
                backgroundColor: '#5EAF21',
                padding: '0px 24px',
                borderRadius: '15px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4',
                marginLeft: '16px',
              }}
              loading={isLoading}
              onClick={() => handleGetTalkCC('zh')}
            >{props.i18n.talk_zh_cc_view}</Button>
          </div>
        </Col>
        <Col span={24} style={{ margin: '30px 0px'}}>
          <div style={{ width: '100%', height: '2px', backgroundColor: props.color.textColor}}></div>
        </Col>
        <Col span={24}>
          <FormInput
            title={props.i18n.talk_title}
            name="talk_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.talk_title}`}
          />
        </Col>
        <Col span={24}>
          <HtmlEditor
            title={props.i18n.talk_desc}
            i18nMode={props.i18nMode}
            propName="talk_desc"
            value={formValue.talk_desc}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.talk_length}
            name="talk_length"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.talk_length}`}
          />
        </Col>
        <Col span={12}>
          <FormDatePicker
            title={props.i18n.talk_date}
            name="talk_time"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.talk_date}`}
          />
        </Col>
        <Col span={12}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
        <Col span={12}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold'
            }}
          >{props.i18n.talk_sell}</div>
          <div style={{ display: 'flex'}}>
            <Switch checked={isSellValue} onChange={setIsSellValue}></Switch><span style={{ color: props.color.textColor}}>{formValue.is_sell ?  '上架中': '已下架'}</span>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item style={{ width: '100%', textAlign: 'center'}}>
            <Button
              loading={isLoading}
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              onClick={() => handleUploadTalk(false)}
            >
              {props.i18n.save}
            </Button>
            <Button
              loading={isLoading}
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              onClick={() => handleUploadTalk(true)}
            >
              {props.i18n.save_and_back}
            </Button>
            <Button
              loading={isLoading}
              onClick={handleCancel}
              style={{
                height: '50px',
                backgroundColor: '#D53838',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
              }}
            >
            {props.i18n.swal_no}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>}

    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_talk' : 'light_talk'}
      closable={false}
      maskClosable={true}
      width={key !== 'module' ? 1200: 500}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{renderDrawerTitle()}</div>
      </div>
      {key !== 'module' &&<DataTable
        column={key === 'en' ? enColumns: zhColumns}
        data={props.talkCC}
        color={props.color}
        i18n={props.i18n}
        isRowSelection={false}
        showCreateButton={false}
        showPatchDeleteButton={false}
        showDeleteButton={false}
        openModal={() => { }}
        checkList={[]}
        showSearch={false}
        searchValue=""
        hasPagination={false}
        // paging={props.paging}
        isLoading={isLoading}
        hasFrontCustomChildren={false}
        handleCheckChange={(e) => {}}
      />}
    {(drawerVisible && key === 'module') && <Form
          {...layout}
          ref={createForm}
          name="basic"
          initialValues={{
            ...props.talkDetail,
          }}
          // onValuesChange={handleFormChange}
          onFinish={handleUpdateModule}
      >
        <Row gutter={24} style={{ marginRight: '0px' }}>

          <Col span={24} style={{ display: 'flex'}}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>{props.i18n.talk_learn}</div>}
              name="is_learn"
              valuePropName="checked"
            >
              <Switch></Switch>
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'flex'}}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>{props.i18n.talk_practice}</div>}
              name="is_practice"
              valuePropName="checked"
            >
              <Switch></Switch>
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'flex'}}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>{props.i18n.talk_exam}</div>}
              name="is_exam"
              valuePropName="checked"
            >
              <Switch></Switch>
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormInput
              title={props.i18n.talk_practice_count}
              name="practice_times"
              required
              type="number"
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.talk_practice_count}`}
            />
          </Col>
          <Col span={24}>
            <FormInput
              title={props.i18n.talk_exam_score}
              name="exam_score"
              required
              type="number"
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.talk_exam_score}`}
            />
          </Col>
          <Col span={24} style={{ marginTop: '16px'}}>
            <Form.Item style={{ width: '100%', textAlign: 'center'}}>
              <Button
                loading={isLoading}
                htmlType="submit"
                style={{
                  height: '50px',
                  backgroundColor: '#4A9E8F',
                  width: '128px',
                  borderRadius: '20px',
                  color: 'white',
                  border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                  marginRight: '30px'
                }}
              >
                {props.i18n.update_btn}
              </Button>
              <Button
                loading={isLoading}
                onClick={() => setDrawerVisible(false)}
                style={{
                  height: '50px',
                  backgroundColor: '#D53838',
                  width: '128px',
                  borderRadius: '20px',
                  color: 'white',
                  border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
                }}
              >
              {props.i18n.swal_no}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    talkDetail: _.get(state, 'Talk.talkDetail', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
    talkCC: _.get(state, 'Talk.talkCC', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTalkById(payload, loading, callback) {
      dispatch({ type: 'GET_TALK_BY_ID', payload, loading, callback });
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback });
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback });
    },
    handleUpdateTalk(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_TALK', payload, loading, callback });
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload });
    },
    handleImportCC(payload, loading, callback) {
      dispatch({ type: 'HANDLE_IMPORT_CC', payload, loading, callback });
    },
    getTalkCC(payload, loading, callback) {
      dispatch({ type: 'GET_TALK_CC', payload, loading, callback });
    },
    handleUpdateTalkModule(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_TALK_MODULE', payload, loading, callback });
    },
    handleUploadAudio(payload, loading, callback, id) {
      dispatch({ type: 'HANDLE_UPLOAD_AUDIO', payload, loading, callback, id});
    },
    handleUploadImg(payload, loading, callback, id) {
      dispatch({ type: 'HANDLE_UPLOAD_IMG', payload, loading, callback, id});
    },
    handleChangeTalkSell(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CHANGE_TALK_SELL', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkUpdate);
