const Reducer = (state = {
  topicGroupList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  topicGroupFormList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_TOPIC_GROUP_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.topic_group_id,
        });
      })
      return { ...state, topicGroupList: temp, paging: payload.paging };
    case "GET_TOPIC_GROUP_LIST_BY_FORM_SUCCESS":
      let tempGroup = [];
      payload.results.map((item) => {
        tempGroup.push({
          id: item.topic_group_id,
          name: item.topic_group_name,
        });
      });
      return { ...state, topicGroupFormList: tempGroup };
    case "SAVE_TOPIC_GROUP":
      return {
        ...state,
        topicGroupFormList:[
          ...state.topicGroupFormList,
          { id: payload.topic_group_id, name: payload.topic_group_name }
        ],
      };
    default:
      return state;
  }
};

export default Reducer;
