import request from "../utils/request";

export function CREATE_School(token, payload) {
  return request.post("/school", payload, {
    headers: {Authorization: token },
  });
}

export function UPDATE_School(token, payload) {
  return request.put("/school", payload, {
    headers: {Authorization: token },
  });
}

export function GET_School(token, payload) {
  return request.post(`school/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_SchoolById(token, payload) {
  return request.get(`school?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_School(token, payload) {
  return request.delete(`school?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_SchoolByForm(token,) {
  return request.get(`school/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_GradeBySchoolId(token, payload) {
  return request.get(`school/grade/form?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function POST_ImportMember(token, payload) {
  return request.post(`member/import`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_RoleList(token,) {
  return request.get(`auth/role/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_SchoolPermissionForm(token, payload) {
  return request.get(`auth/permission/form?school_id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function PUT_ChangePermission(token, payload) {
  return request.put(`auth/permission`, payload, {
    headers: {Authorization: token },
  });
}