/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";

import "./Login.less";
import Images from "../themes/Images";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const App = (props) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const handleLogin = (value) => {
    props.handleLogin(value, setIsLoading, () => {});
  };

  const changePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div id="Home">
      <div
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#D9D9D9",
        }}
      >
        Login to your account
      </div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          account: "",
          password: "",
        }}
        autoComplete="new-password"
        style={{ marginTop: "16px" }}
        onFinish={(value) => handleLogin(value)}
      >
        {/* 為了解決autoComplete 在chrome自動填字問題 */}
        <Input
          style={{ display: "none" }}
          type="text"
          name="fakeusernameremembered"
        />
        <Input
          style={{ display: "none" }}
          type="password"
          name="fakepasswordremembered"
        />
        <Form.Item
          label={
            <div
              style={{
                color: "#2EC1A4",
                fontSize: "16px",
                padding: "0px 16px",
              }}
            >
              使用者名稱
            </div>
          }
          name="account"
          colon={false}
        >
          <Input
            autoComplete="new-password"
            style={{
              borderRadius: "40px",
              height: "45px",
              color: "white",
              border: "0px",
              padding: "0px 16px",
              backgroundColor: "#44474B",
              boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
              width: "100%",
            }}
            placeholder="請輸入使用者名稱"
          />
        </Form.Item>
        <Form.Item
          label={
            <div
              style={{
                color: "#2EC1A4",
                fontSize: "16px",
                padding: "0px 16px",
              }}
            >
              密碼
            </div>
          }
          name="password"
          className="login_password"
          colon={false}
        >
          <Input
            autoComplete="new-password"
            style={{
              borderRadius: "40px",
              height: "45px",
              width: "100%",
              color: "white",
              border: "0px",
              padding: "0px 16px",
              backgroundColor: "#44474B",
              boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
            }}
            type={passwordType}
            placeholder="請輸入密碼"
            suffix={
              <img
                onClick={changePasswordType}
                src={passwordType === "text" ? Images.eye : Images.eye_open}
                style={{ width: "20px", height: "16px" }}
              />
            }
          />
        </Form.Item>
        <p
          onClick={() => navigate("/forget")}
          style={{
            color: "white",
            cursor: "pointer",
            padding: "0px 16px",
            marginTop: "-16px",
          }}
        >
          忘記密碼？
        </p>
        <Form.Item style={{ marginTop: "30px" }}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "40px",
              backgroundColor: "#5BDFC6",
              width: "100%",
              borderRadius: "20px",
              color: "black",
            }}
          >
            登入
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin(payload, loading, callback) {
      dispatch({ type: "HANDLE_LOGIN", payload, loading, callback });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
