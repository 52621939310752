import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Upload, Checkbox, Input } from 'antd';
import FormInput from "../components/common/FormInput";
import CommonInput from "../components/common/CommonInput";
import CommonHtmlEditor from "../components/common/CommonHtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import { arrayMoveImmutable } from "array-move";
import Swal from 'sweetalert2';

import Images from "../themes/Images";
import "./BookChapter.less";
import _ from "lodash";
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { showMessage } from "../utils/message";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BookChapter = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    is_random: false,
    is_practice: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('create');
  const [bookMode, setBookMode] = useState('teacher');
  const [currentPage, setCurrentPage] = useState({
    page_topics: [],
    index: 0
  });
  const [currentPageType, setCurrentPageType] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [teacherChapter, setTeacherChapter] = useState([]);
  const [studentChapter, setStudentChapter] = useState([]);
  const [removes, setRemoves] = useState([]);

  useEffect(() => {
    let url = window.location.pathname.split('/book/chapter/')[1];
    let book_id = url.split('/')[0];
    let chapter_id = url.split('/')[1];
    props.getBookById(book_id, setIsLoading);
    const callback = (value) => {
      let tempTeacher = [], tempStudent = [];
      if (value.teacher !== null) {
        value.teacher.map((item, index) => {
          tempTeacher.push({
            index: index,
            ...item,
          })
        });
      }
      if (value.student !== null) {
        value.student.map((item, index) => {
          tempStudent.push({
            index: index,
            ...item,
          })
        });
      }
      setTeacherChapter(tempTeacher);
      setStudentChapter(tempStudent);
      if (value.teacher !== null && value.teacher.length > 0) {
        setCurrentPage({...value.teacher[0], index: 0});
        setCurrentPageType(value.teacher[0].page_type);
      }
    }
    props.getBookChapterById({
      id: chapter_id,
      mode: -1,
      is_front: false,
      page: -1,
      pageCount: 10000,
    }, setIsLoading, callback);
  }, []);

  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      createForm.current.setFieldsValue({ img: temp });
      setImageData(temp);
    } else {
      createForm.current.setFieldsValue({ img: newFileList });
      setImageData(newFileList);
    }
  };

  const renderBookDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleBookFinish}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_name}
            name="book_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_name}`}
          />
        </Col>
        <Col span={12}>
          <Form.Item 
            valuePropName="imgs"
            name="imgs"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.img}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.book_img}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="picture-card"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              onChange={handleImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#292C33'}}>{props.i18n.upload}</div>
              </div>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.book_effective}
            name="book_effective"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_effective}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.origin_price}
            name="book_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.discount_price}
            name="book_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>
        <Col span={12} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>上架狀態</div>}
            name="is_sell"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={24}>  
          <CommonHtmlEditor
            title={props.i18n.book_desc}
            i18nMode={props.i18nMode}
            propName="book_desc"
            value={formValue.book_desc}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const renderCreateChapter = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleCreateChapter}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_part_content}
            name="chapter_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_part_content}`}
          />
        </Col>

        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 7,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }

  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }


  const handleCreateChapter = (value) => {
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleCreateChapter({
      ...value,
      book_id: props.bookDetail.book_id,
    }, setIsLoading, callback);
  }

  const handleBookFinish = (value) => {
    let formData = new FormData();
    formData.append("book_title", formValue.book_title);
    formData.append("book_id", props.bookDetail.book_id);
    formData.append("book_desc", _.isObject(formValue.book_desc) ? formValue.book_desc.toHTML(): formValue.book_desc);
    if (formValue.book_effective !== undefined) {
      formData.append("book_effective", formValue.book_effective);
    } 
    formData.append("book_price", parseInt(formValue.book_price));
    if (formValue.book_discount !== undefined) {
      formData.append("book_discount", formValue.book_discount);
    } 
    formData.append("is_sell", formValue.is_sell);
    if (imageData.length > 0) {
      if (_.has(imageData[0], 'originFileObj')) {
        formData.append("imgs[0].file", imageData[0].originFileObj);
      }
      formData.append("imgs[0].file_id", props.bookDetail.imgs[0].file_id);
      formData.append("imgs[0].file_order", 1);
    }
    formData.append("book_title", formValue.book_title);
    formValue.chapters.map((item, index) => {
      formData.append(`chapters[${index}].chapter_title`, item.chapter_title);
      item.tags.map((child) => {
        formData.append(`chapters[${index}].tags`, child);
      });
      item.abilities.map((child) => {
        formData.append(`chapters[${index}].abilities`, child);
      });
    });
    formValue.tags.map((child) => {
      formData.append(`tags`, child);
    });
    formValue.abilities.map((child) => {
      formData.append(`abilities`, child);
    });
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleUpdateBook(formData, setIsLoading, callback, props.bookDetail.book_id);
  }

  const handleSave = () => {
    const callback = (value) => {
      setRemoves([]);
      if (value !== null && value !== undefined) {
        let tempTeacher = [], tempStudent = [];
        if (value.teacher !== null) {
          value.teacher.map((item, index) => {
            tempTeacher.push({
              index: index,
              ...item,
            })
          });
        }
        if (value.student !== null) {
          value.student.map((item, index) => {
            tempStudent.push({
              index: index,
              ...item,
            })
          });
        }
        setTeacherChapter(tempTeacher);
        setStudentChapter(tempStudent);
        if (value.teacher !== null && value.teacher.length > 0) {
          setCurrentPage({...value.teacher[0], index: 0});
          setCurrentPageType(value.teacher[0].page_type);
        }
      }
      setIsLoading(false);
    }
    let formData = new FormData();
    formData.append('chapter_id', props.chapterDetail.chapter_id);
    if (removes.length > 0) {
      removes.map((item, index) => {
        formData.append(`removes[${index}]`, item);
      });
    } else {
      formData.append(`removes`, []);
    }
    const tempTeacher = [...teacherChapter];
    const tempStudent = [...studentChapter];
    tempTeacher.map((item, index) => {
      if (_.has(item, 'page_id') && item.page_id !== undefined && item.page_id !== ''&& item.page_id !== null) {
        formData.append(`teacher[${index}].page_id`, item.page_id);
      }
      formData.append(`teacher[${index}].page_content`, item.page_content);
      formData.append(`teacher[${index}].page_title`, item.page_title === null ? '': item.page_title);
      formData.append(`teacher[${index}].page_type`, item.page_type);
      formData.append(`teacher[${index}].page_order`, index + 1);
      formData.append(`teacher[${index}].is_hide`, item.is_hide);

      if (_.has(item, 'remove_topic')) {
        item.remove_topic.map((child, childIndex) => {
          formData.append(`teacher[${index}].remove_topics[${childIndex}]`, child);
        })
      }
      if (_.isArray(item.page_topics)) {
        item.page_topics.map((topic, topicIndex) => {
          if (_.has(topic, 'page_topic_id')) {
            formData.append(`teacher[${index}].page_topics[${topicIndex}].page_topic_id`, topic.page_topic_id)
          }
          formData.append(`teacher[${index}].page_topics[${topicIndex}].audio`, []);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].img`, []);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].is_have_audio`, false);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].is_have_img`, false);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].is_edit`, topic.is_edit === undefined ? false : topic.is_edit);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].page_topic_answer`, topic.page_topic_answer=== null ? '' : topic.page_topic_answer);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].page_topic_detail`, topic.page_topic_detail === null ? '' : topic.page_topic_detail);
          formData.append(`teacher[${index}].page_topics[${topicIndex}].page_topic_title`, topic.page_topic_title);
          topic.options.map((child, childIndex) => {
            formData.append(`teacher[${index}].page_topics[${topicIndex}].options[${childIndex}].is_answer`, child.is_answer);
            formData.append(`teacher[${index}].page_topics[${topicIndex}].options[${childIndex}].page_topic_option_title`, child.page_topic_option_title);
          });
        });
      }
    });
    tempStudent.map((item, index) => {
      if (_.has(item, 'page_id') && item.page_id !== undefined && item.page_id !== ''&& item.page_id !== null) {
        formData.append(`student[${index}].page_id`, item.page_id);
      }
      formData.append(`student[${index}].page_content`, item.page_content);
      formData.append(`student[${index}].page_title`, item.page_title === null ? '': item.page_title);
      formData.append(`student[${index}].page_type`, item.page_type);
      formData.append(`student[${index}].page_order`, index + 1);
      formData.append(`student[${index}].is_hide`, item.is_hide);
      if (_.has(item, 'remove_topic')) {
        item.remove_topic.map((child, childIndex) => {
          formData.append(`student[${index}].remove_topics[${childIndex}]`, child);
        })
      }
      if (_.isArray(item.page_topics)) {
        item.page_topics.map((topic, topicIndex) => {
          if (_.has(topic, 'page_topic_id')) {
            formData.append(`student[${index}].page_topics[${topicIndex}].page_topic_id`, topic.page_topic_id)
          }
          formData.append(`student[${index}].page_topics[${topicIndex}].audio`, []);
          formData.append(`student[${index}].page_topics[${topicIndex}].img`, []);
          formData.append(`student[${index}].page_topics[${topicIndex}].is_have_audio`, false);
          formData.append(`student[${index}].page_topics[${topicIndex}].is_have_img`, false);
          formData.append(`student[${index}].page_topics[${topicIndex}].is_edit`, topic.is_edit === undefined ? false : topic.is_edit);
          formData.append(`student[${index}].page_topics[${topicIndex}].page_topic_answer`, topic.page_topic_answer);
          formData.append(`student[${index}].page_topics[${topicIndex}].page_topic_detail`, topic.page_topic_detail);
          formData.append(`student[${index}].page_topics[${topicIndex}].page_topic_title`, topic.page_topic_title);
          topic.options.map((child, childIndex) => {
            formData.append(`student[${index}].page_topics[${topicIndex}].options[${childIndex}].is_answer`, child.is_answer);
            formData.append(`student[${index}].page_topics[${topicIndex}].options[${childIndex}].page_topic_option_title`, child.page_topic_option_title);
          });
        });
      }
    });

    props.handleUpdateChapter(formData, setIsLoading, callback, { chapter_id: props.chapterDetail.chapter_id});
  }

  const handleDeletePage = (item, index, e) => {
    e.stopPropagation();
    if (_.has(item, 'page_id') && item.page_id !== "") {
      setRemoves([...removes, item.page_id]);
    }

    if (bookMode === 'teacher') {
      let temp = teacherChapter.filter((child, childIndex) => childIndex !== index);
      setTeacherChapter(temp);
    } else {
      let temp = studentChapter.filter((child, childIndex) => childIndex !== index);
      setStudentChapter(temp);
    }


  }

  const handleCopyPage = () => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      setStudentChapter([...temp, {...tempPage, page_id: ''}]);
    } else {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      setTeacherChapter([...temp, {...tempPage, page_id: ''}]);
    }
    showMessage({ content: '複製成功', type: 'success' });
  }

  const handleCopyAllPage = () => {
    Swal.fire({
      title: '此功能複製時，僅限於另一方完全沒有資料才可以實作此功能，如另一方有其他資料則會導致資料重複問題。',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (bookMode === 'teacher') {
        let temp = JSON.parse(JSON.stringify(teacherChapter));
        let tempList = [];
        temp.map((item) => {
          tempList.push({ ...item, page_id: '' });
        })
        setStudentChapter(tempList);
      } else {
        let temp = JSON.parse(JSON.stringify(studentChapter));
        let tempList = [];
        temp.map((item) => {
          tempList.push({ ...item, page_id: '' });
        })
        setTeacherChapter(tempList);
      }
      showMessage({ content: '複製成功', type: 'success' });
    });
  }


  const handleCreatePage = () => {
    if (bookMode === 'teacher') {
      setTeacherChapter([...teacherChapter, {
        page_content: '',
        is_hide: false,
        page_remark: null,
        index: teacherChapter.length,
        page_type: 0,
        page_topics: [],
      }]);
      setCurrentPageType(0);
      setCurrentPage({
        page_content: '',
        is_hide: false,
        page_remark: null,
        index: teacherChapter.length,
        page_type: 0,
        page_topics: [],
      });
    } else {
      setStudentChapter([...studentChapter, {
        page_content: '',
        is_hide: false,
        page_remark: null,
        index: studentChapter.length,
        page_type: 0,
        page_topics: [],
      }]);
      setCurrentPage({
        page_content: '',
        is_hide: false,
        page_remark: null,
        index: studentChapter.length,
        page_type: 0,
        page_topics: [],
      });
      setCurrentPageType(0);
    }
  }


  const handleChangePageHtml = (value) => {
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_content = value.toHTML();
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_content = value.toHTML();
        }
      });
      setStudentChapter(temp);
    }
  }

  const handleChangePageTitle = (e) => {
    let value = e.target.value;
  
    setCurrentPage({ ...currentPage, page_title: value });
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_title = value;
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_title = value;
        }
      });
      setStudentChapter(temp);
    }
  }

  const handleGoBack = () => {
    let url = window.location.pathname.split('/book/chapter/')[1];
    let book_id = url.split('/')[0];
    navigate(`/book/update/${book_id}`);
  }

  const handleChangePageType = (checked) => {
    let bool = (checked ? 1: 0);
    setCurrentPageType(bool);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_type = bool;
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (index === currentPage.index) {
          temp[index].page_type = bool;
        }
      });
      setStudentChapter(temp);
    }

  }

  const handleAddTopic = () => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    let init = {
      page_topic_title: '',
      page_topic_answer: '',
      page_topic_detail: '',
      img: [],
      is_have_img: false,
      audio: [],
      is_have_audio: false,
      items: [],
      page_topic_id: '',
      options: [],
      is_edit: true
    }
    if (tempPage.page_topics === null) {
      tempPage.page_topics = [];
    }
    tempPage.page_topics.push(init);
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          if (temp[index].page_topics === null) {
            temp[index].page_topics = [init];
          } else {
            temp[index].page_topics.push(init);
          }
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          if (temp[index].page_topics === null) {
            temp[index].page_topics = [init];
          } else {
            temp[index].page_topics.push(init);
          }
        }
      });
      setStudentChapter(temp);
    }
  }


  const handleAddTopicOption = (topicIndex) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    let init = {
      page_topic_option_title: '',
      is_answer: false,
    }
    tempPage.page_topics[topicIndex].options.push(init);
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {

        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          temp[index].page_topics[topicIndex].options.push(init);
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {

        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          temp[index].page_topics[topicIndex].options.push(init);
        }
      });
      setStudentChapter(temp);
    }
  }

  const handleChangeTopicOption = (value, topicIndex, optionIndex, key) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));

    tempPage.page_topics[topicIndex].options[optionIndex][key] = value;
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          if (key === 'is_answer' && value) {
            temp[index].page_topics[topicIndex].options.map((item, itemIndex) => {
              if (itemIndex !== optionIndex) {
                temp[index].page_topics[topicIndex].options[itemIndex][key] = false;
                tempPage.page_topics[topicIndex].options[itemIndex][key] = false;
              } else {
                temp[index].page_topics[topicIndex].options[itemIndex][key] = value;
                tempPage.page_topics[topicIndex].options[itemIndex][key] = value;
              }
            });
          }
          temp[index].page_topics[topicIndex].options[optionIndex][key] = value;
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          if (key === 'is_answer' && value) {
            temp[index].page_topics[topicIndex].options.map((item, itemIndex) => {
              if (itemIndex !== optionIndex) {
                temp[index].page_topics[topicIndex].options[itemIndex][key] = false;
                tempPage.page_topics[topicIndex].options[itemIndex][key] = false;
              } else {
                temp[index].page_topics[topicIndex].options[itemIndex][key] = value;
                tempPage.page_topics[topicIndex].options[itemIndex][key] = value;
              }
            });
          }
          temp[index].page_topics[topicIndex].options[optionIndex][key] = value;
        }
      });
      setStudentChapter(temp);
    }
  }

  const handleChangeTopicOptionSort = (topicIndex, optionIndex, key) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));

    let tempData;
    tempData = tempPage.page_topics[topicIndex].options[optionIndex];
    tempPage.page_topics[topicIndex].options[optionIndex] = tempPage.page_topics[topicIndex].options[optionIndex + key];
    tempPage.page_topics[topicIndex].options[optionIndex + key] = tempData;
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          tempData = temp[index].page_topics[topicIndex].options[optionIndex];
          temp[index].page_topics[topicIndex].options[optionIndex] = temp[index].page_topics[topicIndex].options[optionIndex + key];
          temp[index].page_topics[topicIndex].options[optionIndex + key] = tempData;
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          tempData = temp[index].page_topics[topicIndex].options[optionIndex];
          temp[index].page_topics[topicIndex].options[optionIndex] = temp[index].page_topics[topicIndex].options[optionIndex + key];
          temp[index].page_topics[topicIndex].options[optionIndex + key] = tempData;        }
      });
      setStudentChapter(temp);
    }
  }

  const handleRemoveTopicOption = (topicIndex, optionIndex) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    tempPage.page_topics[topicIndex].options.splice(optionIndex, 1);
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          temp[index].page_topics[topicIndex].options.splice(optionIndex, 1);
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, index) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[index].page_topics[topicIndex].is_edit = true;
          temp[index].page_topics[topicIndex].options.splice(optionIndex, 1);       }
      });
      setStudentChapter(temp);
    }
  }

  const handleChangeTopic = (value, index, key) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    tempPage.page_topics[index][key] = value;
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, itemIndex) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[itemIndex].page_topics[index].is_edit = true;
          temp[itemIndex].page_topics[index][key] = value;
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, itemIndex) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          temp[itemIndex].page_topics[index].is_edit = true;
          temp[itemIndex].page_topics[index][key] = value;
        }
      });
      setStudentChapter(temp);
    }
  }

  const handleRemoveTopic = (index) => {
    let tempPage = JSON.parse(JSON.stringify(currentPage));
    tempPage.page_topics.splice(index, 1);
    setCurrentPage(tempPage);
    if (bookMode === 'teacher') {
      let temp = JSON.parse(JSON.stringify(teacherChapter));
      temp.map((item, itemIndex) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          if (_.has(temp[itemIndex].page_topics[index],'page_topic_id')) {
            if (_.has(temp[itemIndex], 'remove_topic')) {
              temp[itemIndex]['remove_topic'].push(temp[itemIndex].page_topics[index].page_topic_id);
            } else {
              temp[itemIndex]['remove_topic'] = [temp[itemIndex].page_topics[index].page_topic_id];
            }
          }
          temp[itemIndex].page_topics.splice(index, 1);
        }
      });
      setTeacherChapter(temp);
    } else {
      let temp = JSON.parse(JSON.stringify(studentChapter));
      temp.map((item, itemIndex) => {
        if (item.page_id === currentPage.page_id && item.index === currentPage.index) {
          if (_.has(temp[itemIndex].page_topics[index], 'page_topic_id')) {
            if (_.has(temp[itemIndex], 'remove_topic')) {
              temp[itemIndex]['remove_topic'].push(temp[itemIndex].page_topics[index].page_topic_id);
            } else {
              temp[itemIndex]['remove_topic'] = [temp[itemIndex].page_topics[index].page_topic_id];
            }
          }
          temp[itemIndex].page_topics.splice(index, 1);
        }
      });
      setStudentChapter(temp);
    }
  }

  const SortableItem = SortableElement(({ value }) => <div onClick={() => {
    setCurrentPage({...value.item, index: value.index});
    setCurrentPageType(value.item.page_type);
  }} style={{ width: '100%', height: '82px', borderRadius: '5px', padding: '4px', backgroundColor: currentPage.index === value.index ? '#7BFFE6': '#90DCD4', marginBottom: '16px', cursor:'pointer' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ fontSize: '12px', color: props.color.inputTitleColor }}>#{value.index + 1}</div>
      <div onClick={e => handleDeletePage(value.item, value.index, e)} style={{ width: '18px', height: '18px', borderRadius: '9px', backgroundColor: '#D53838', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img alt="delete" src={Images.delete} style={{ width: '11px', height: '11px'}} />
      </div>
    </div>
    <div
      style={{ width: '1366px', height: '768px', marginTop: '8px', zoom: '0.07', overflow: 'hidden' }}
      dangerouslySetInnerHTML={{__html: value.item.page_content}}
    >
    </div>
  </div>);

  const SortableList = SortableContainer(({items}) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={{ item, index }}
          />
        ))}
      </div>
    );
  });
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (bookMode === 'teacher') {
      let tempData = JSON.parse(JSON.stringify(teacherChapter));
      let temp = arrayMoveImmutable(tempData, oldIndex, newIndex);
      setTeacherChapter(temp);
    } else {
      let tempData = JSON.parse(JSON.stringify(studentChapter));
      let temp = arrayMoveImmutable(tempData, oldIndex, newIndex);
      setStudentChapter(temp)
    }
  };

  const renderTopic = () => {
    return <div>
      <div
        style={{
          fontSize: '18px',
          color: props.color.inputTitleColor,
          height: '22px',
          marginBottom: '4px',
        }}
      >
        教科書內容
      </div>
      <CommonHtmlEditor
        // title={props.i18n.exam_desc}
        i18nMode={props.i18nMode}
        // propName="exam_desc"
        wrapperHeight={props.screenHeight - 400}
        defaultValue={currentPage.page_content}
        textColor={props.color.inputTitleColor}
        onEditorStateChange={handleChangePageHtml}
      />
      <div
        style={{
          fontSize: '18px',
          color: props.color.inputTitleColor,
          height: '22px',
          marginBottom: '4px',
          marginTop: '30px'
        }}
      >
        題目列表
      </div>
      <div>
        {
          (_.has(currentPage, 'page_topics') && _.isArray(currentPage.page_topics)) &&currentPage.page_topics.map((topic, topicIndex) => {
            return <Row>
              <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '40px',
                    color: props.color.inputTitleColor,
                    fontWeight: 'bold',
                    marginRight: '12px',
                  }}>第{topicIndex + 1}題</div>
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '15px',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#D53838',
                    border: '1px solid #F31616',
                    marginLeft: '8px',
                  }}
                  onClick={() => handleRemoveTopic(topicIndex)}
                >
                  <img
                    src={Images.delete}
                    style={{ width: '22px', height: '14px'}}
                    alt={props.i18n.delete}
                  />
                </Button>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    color: props.color.inputTitleColor,
                    fontSize: '18px',
                    padding:'0px 16px',
                    fontWeight: 'bold'
                  }}>{props.i18n.topic_manager_title}</div>
                <CommonInput
                  value={topic.page_topic_title}
                  formItemStyle={{
                    marginRight: '16px'               
                  }}
                  props={{
                    wrapperCol: {
                      span: 24,
                    },
                    labelCol: {
                      span: 24,
                    }
                  }}
                  onChange={(e) => handleChangeTopic(e.target.value, topicIndex, 'page_topic_title')}
                  style={{ width: '100%'}}
                  textColor={props.color.inputTitleColor}
                  placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_title}`}
                />
              </Col>
              <Col span={24} style={{ marginTop: '16px', marginBottom: '16px'}}>
                <div
                  style={{
                    color: props.color.inputTitleColor,
                    fontSize: '18px',
                    padding:'0px 16px',
                    fontWeight: 'bold'
                  }}>{props.i18n.topic_manager_answer}</div>
                <CommonInput
                  value={topic.page_topic_answer}
                  title={props.i18n.topic_manager_answer}
                  formItemStyle={{
                    marginRight: '16px'               
                  }}
                  props={{
                    wrapperCol: {
                      span: 24,
                    },
                    labelCol: {
                      span: 24,
                    }
                  }}
                  onChange={(e) => handleChangeTopic(e.target.value, topicIndex, 'page_topic_answer')}
                  style={{ width: '100%'}}
                  textColor={props.color.inputTitleColor}
                  placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_title}`}
                />
              </Col>
              <Col span={24}>
                  <div style={{
                    width: '100%',
                    backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
                    borderRadius: '20px',
                    height: '70px',
                    padding: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                  }}>
                    <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'left'}}>{props.i18n.answer}</div>
                    <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.option_content}</div>
                    <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'center'}}>{props.i18n.operation}</div>
                  </div>
                  {topic.options.map((optionItem, optionIndex) => {
                    return <div style={{ display: 'flex', width: '100%', padding: '0px 16px 0px 8px', marginBottom: '16px' }}>
                      <div style={{ minWidth: '60px', marginRight: '16px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Checkbox
                          checked={optionItem.is_answer}
                          onChange={(e) => handleChangeTopicOption(e.target.checked, topicIndex, optionIndex, 'is_answer')}
                          className={props.color.currentTheme === 'dark' ? 'dark-checkbox' : 'light-checkbox'}
                        />
                      </div>
                      <CommonInput
                        style={{ width: '100% !important' }}
                        value={optionItem.page_topic_option_title}
                        onChange={(e) => handleChangeTopicOption(e.target.value, topicIndex, optionIndex, 'page_topic_option_title')}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.option_content}`}
                      />
                      <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center', marginRight: '24px', marginLeft: '16px'}}>
                        <div
                          style={{
                            width: '12px',
                            lineHeight: '12px',
                            marginLeft: '8px',
                            cursor: 'pointer',
                            userSelect: 'none',
                          }}
                        >
                          <img
                            src={props.color.currentTheme === 'dark' ? Images.up_dark : Images.up_light}
                            alt="sort_up"
                            style={{ cursor: ((optionIndex === topic.options.length - 1 && optionIndex === 0) || optionIndex === 0) ? 'not-allowed' :'pointer' }}
                            disabled={(optionIndex === topic.options.length - 1 && optionIndex === 0) || optionIndex === 0}
                            onClick={((optionIndex === topic.options.length - 1 && optionIndex === 0) || optionIndex === 0) ? () => { } :() => handleChangeTopicOptionSort(topicIndex,optionIndex, -1)}
                          />
                          <img
                            src={props.color.currentTheme === 'dark' ? Images.down_dark : Images.down_light}
                            alt="sort_down"
                            disabled={topic.options.length - 1 === optionIndex}
                            style={{ cursor: topic.options.length - 1 === optionIndex ? 'not-allowed' :'pointer' }}
                            onClick={topic.options.length - 1 === optionIndex ? () =>{} :() => handleChangeTopicOptionSort(topicIndex,optionIndex, 1)}
                          />
                        </div>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#D53838',
                            border: '1px solid #F31616',
                            marginLeft: '8px',
                          }}
                          onClick={() => handleRemoveTopicOption(topicIndex,optionIndex)}
                        >
                          <img
                            src={Images.delete}
                            style={{ width: '22px', height: '14px'}}
                            alt={props.i18n.delete}
                          />
                        </Button>
                      </div>
                    </div>
                  })}
                  <Button
                    type="dashed"
                    onClick={() => handleAddTopicOption(topicIndex)}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                      marginBottom: '30px'
                    }}
                  >
                    + {props.i18n.create_option}
                  </Button>
                
              </Col>
              <Col span={24} style={{ marginBottom: '16px'}}>
                <div
                  style={{
                    color: props.color.inputTitleColor,
                    fontSize: '18px',
                    padding:'0px 16px',
                    fontWeight: 'bold'
                  }}>{props.i18n.topic_manager_answer_desc}</div>
                <CommonInput
                  value={topic.page_topic_detail}
                  title={props.i18n.topic_manager_answer_desc}
                  formItemStyle={{
                    marginRight: '16px'               
                  }}
                  props={{
                    wrapperCol: {
                      span: 24,
                    },
                    labelCol: {
                      span: 24,
                    }
                  }}
                  onChange={(e) => handleChangeTopic(e.target.value, topicIndex, 'page_topic_detail')}
                  style={{ width: '100%'}}
                  textColor={props.color.inputTitleColor}
                  placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_answer_desc}`}
                />
              </Col>
            </Row>
          })
        }
        <div>
          <Button
            type="dashed"
            onClick={handleAddTopic}
            block
            style={{
              fontSize: '18px',
              border: '2px dashed #0EA285',
              height: '50px',
              lineHeight: '50px',
              padding: '0px',
              color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
              backgroundColor: 'transparent',
              borderRadius: '15px',
              marginTop: '12px'
            }}
          >
            + {props.i18n.create_btn}
          </Button>
        </div>
      </div>
    </div>
  }
  
  return <div style={{ height: '100%', marginRight: '16px' }}>
    <div style={{ display: 'flex',}}>
      <div style={{ width: '100%'}}>
        <div style={{ fontSize: '18px', color: props.color.textColor, fontWeight: 'bold'}}>
          {props.chapterDetail.chapter_title}
        </div>
        {/* <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: '16px', color: props.color.textColor, fontWeight: 'bold', marginRight: '16px'}}>{props.chapterDetail.chapter_title}</div>
          <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.tags.map((child, index) => {
                return index < 3 && <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCF1DF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.tag_name}
                  </div>
              })
            }
            {props.bookDetail.tags.length > 3 && <div style={{
              width: '36px',
              height: '40px',
              lineHeight: '32px',
              borderRadius: '15px',
              backgroundColor: '#FCF1DF', 
              color: '#242933',
              textAlign: 'center',
              marginRight: '16px',
            }}>
              ...
            </div>}
          </div>
          {(props.bookDetail.tags.length > 0 && props.bookDetail.abilities.length > 0) &&<span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
          <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.abilities.map((child, index) => {
                return <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCD9BF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.ability_name}
                  </div>
              })
            }
          </div>
          <Button
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateBook}
          >
            <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
          </Button>
        </div> */}
      </div>
    </div>
    <div style={{ width: '100%', height: '1px', backgroundColor: '#26695C', marginTop: '8px', marginBottom: '16px' }}></div>
    <Row gutter={24}>
      <Col span={24} style={{ display: 'flex', marginBottom: '16px'}}>
        {/* <div
          style={{
            display: 'flex',
            width: '119px',
            borderRadius: '15px',
            backgroundColor: '#42C1B4',
            border: '1px solid #C1EFD6',
            height: '40px',
            overflow: 'hidden',
          }}>
          <div
            style={{
              width: '39px',
              borderRight: '1px solid #26695C',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              background: gridMode === '1' ? 'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
            }}
            onClick={() => setGridMode('1')}
          >
            <img alt="1" src={Images.grid1} style={{ width: '23px', height: '15px'}} />
          </div>
          <div
            style={{
              width: '39px',
              borderLeft: '1px solid #2DA498',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              background: gridMode === '2' ? 'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
            }}
            onClick={() => setGridMode('2')}
          >
            <img alt="1" src={Images.grid2} style={{ width: '23px', height: '15px' }} />
          </div>
          <div
            style={{
              width: '39px',
              borderLeft: '1px solid #2DA498',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              background: gridMode === '3' ? 'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
            }}
            onClick={() => setGridMode('3')}
          >
            <img alt="1" src={Images.grid3} style={{ width: '23px', height: '15px' }} />
          </div>
        </div> */}

        <div
          style={{
            display: 'flex',
            width: '125px',
            borderRadius: '15px',
            backgroundColor: '#42C1B4',
            border: '1px solid #C1EFD6',
            height: '40px',
            overflow: 'hidden',
            // marginLeft: '16px'
          }}>
          <div
            style={{
              width: '62px',
              borderRight: '1px solid #26695C',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              background: bookMode === 'teacher' ? 'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
            }}
            onClick={() => {
              setBookMode('teacher');
              setCurrentPage({});
            }}
          >
            老師
          </div>
          <div
            style={{
              width: '62px',
              borderLeft: '1px solid #2DA498',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              background: bookMode === 'student' ? 'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
            }}
            onClick={() => {
              setBookMode('student');
              setCurrentPage({});
            }}
          >
            學生
          </div>
        </div>

        <Button
          loading={isLoading}
          style={{
            height: '40px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '15px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginLeft: '16px'
          }}
          onClick={handleCreatePage}
        >
          建立新頁面
        </Button>
        {<Button
          loading={isLoading}
          style={{
            height: '40px',
            backgroundColor: '#4A9E8F',
            width: '160px',
            borderRadius: '15px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginLeft: '16px'
          }}
          onClick={handleCopyPage}
        >
          {bookMode === 'teacher' ? '複製該頁至學生頁面': '複製至教師頁面'}
        </Button>}
        {<Button
          loading={isLoading}
          style={{
            height: '40px',
            backgroundColor: '#4A9E8F',
            width: '160px',
            borderRadius: '15px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginLeft: '16px'
          }}
          onClick={handleCopyAllPage}
        >
          {bookMode === 'teacher' ? '複製全部至學生頁面': '複製全部至教師頁面'}
        </Button>}


        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px'}}>
          <Switch checked={currentPageType === 1} onChange={handleChangePageType}></Switch><span style={{ marginLeft: '8px'}}>是否為測驗</span>
        </div>
      </Col>
    </Row>
    <Row gutter={24} style={{ marginRight: '12px'}} >
      <Col span={3} >
        <div style={{ fontSize: '18px', color: props.color.inputTitleColor, height: '22px', marginBottom: '4px' }}>頁數</div>
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 3px  6px rgba(0,0,0,0.16)',
            borderRadius: '15px',
            padding: '8px',
            paddingRight: '12px',
            height: `${props.screenHeight - 276}px`, 
          }}
          className="scrollList"
        >
          {bookMode === 'teacher' ? <SortableList
            items={teacherChapter}
            onSortEnd={onSortEnd}
            distance={1}
          />: <SortableList
            items={studentChapter}
            onSortEnd={onSortEnd}
            distance={1}
          />}
        </div>
       
      </Col>
      {/* <Col span={6}>
        <div style={{ fontSize: '18px', color: props.color.inputTitleColor, height: '22px', marginBottom: '4px' }}>剪貼簿</div>
        <CommonHtmlEditor
          title=""
          // title={props.i18n.exam_desc}
          i18nMode={props.i18nMode}
          // propName="exam_desc"
          value={copyHtml}
          showControl={false}
          textColor={props.color.inputTitleColor}
          onEditorStateChange={(e) => setCopyHtml(e.toHTML())}
        />
      </Col> */}
      <Col span={21} >
        <div style={{ fontSize: '18px', color: props.color.inputTitleColor, height: '22px', marginBottom: '4px' }}></div>
        <div className="scrollList" style={{ height: `${props.screenHeight - 276}px`, paddingBottom: '8px'}}>
          
          <div
            style={{
              fontSize: '18px',
              color: props.color.inputTitleColor,
              height: '22px',
              marginBottom: '4px',
            }}
          >
            {props.i18n.book_page_title}
          </div>
          <Input
            id="formInput"
            autoComplete="off"
            style={{
              borderRadius: '15px',
              height: '50px',
              color: '#125346',
              padding: '0px 16px',
              backgroundColor: '#FFF',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
              width: '100%',
              marginBottom: '16px',
            }}
            value={currentPage.page_title}
            onChange={handleChangePageTitle}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_page_title}`}
          />
          {currentPageType === 0 ? <CommonHtmlEditor
            title=""
            // title={props.i18n.exam_desc}
            i18nMode={props.i18nMode}
            // propName="exam_desc"
            wrapperHeight={props.screenHeight - 276}
            defaultValue={currentPage.page_content}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangePageHtml}
          />: renderTopic()}
        </div>
      </Col>
    </Row>
    <Row>
      <Col span={24} style={{ marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
        <Button
          loading={isLoading}
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
          onClick={handleSave}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={handleGoBack}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Col>
    </Row>
     {/* {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={mode === 'view' ? true :false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{renderDrawerTitle()}</div>
      </div>
      {renderDrawerView()}
    </Drawer>} */}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    bookDetail: _.get(state, 'Book.bookDetail', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
    chapterDetail: _.get(state, 'Book.chapterDetail', {}),
    chapterTeacher: _.get(state, 'Book.chapterTeacher', []),
    chapterStudent: _.get(state, 'Book.chapterStudent', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    handleDeletePartItem(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART_ITEM', payload, loading, callback});
    },
    getBookById(payload, loading, callback) {
      dispatch({ type: 'GET_BOOK_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleUpdateBook(payload, loading, callback, id) {
      dispatch({ type: 'HANDLE_UPDATE_BOOK', payload, loading, callback, id});
    },
    getExamPartTopic(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_PART_TOPIC', payload, loading, callback});
    },
    handleDeletePart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART', payload, loading, callback});
    },
    handleDeleteChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_CHAPTER', payload, loading, callback});
    },
    handleCreateChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_CHAPTER', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    handleSortPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_SORT_PART', payload, loading, callback});
    },
    getBookChapterById(payload, loading, callback) {
      dispatch({ type: 'GET_BOOK_CHAPTER_BY_ID', payload, loading, callback});
    },
    handleUpdateChapter(payload, loading, callback, callData) {
      dispatch({ type: 'HANDLE_UPDATE_CHAPTER', payload, loading, callback, callData});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookChapter);
