import _ from "lodash";

const onReSize = ({ dispatch }) => {
  const screenWidth = _.get(window, "innerWidth", 1920);
  dispatch({ type: "CHANGE_WindowScreenSize", payload: screenWidth });
};

const onLoad = (store) => {
  const { dispatch } = store;
  onReSize(store);
  dispatch({ type: "INITIAL_Theme" });
  dispatch({ type: "INITIAL_User" });
  dispatch({ type: "SAVE_InitialLoad", payload: true });
};

const root = (store) => {
  window.addEventListener("resize", () => onReSize(store));
  window.addEventListener("load", () => onLoad(store));
};

export default root;
