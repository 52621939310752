import _ from "lodash";
import {
  GET_Example,
  POST_Example,
  PUT_Example,
  PATCH_Example,
  DELETE_Example,
} from "../services/Example";
import { takeLatest, put, call } from "redux-saga/effects";
import { getToken } from "../utils/token";
import { handleError } from "../utils/error";

function* GET_ExampleEffect({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const token = yield call(getToken);
    const { Result } = yield call(GET_Example, token, payload);
    yield put({ type: "SAVE_Example", payload: Result });

    if (loading) loading(false);
    if (callback) callback();
  } catch (error) {
    if (loading) loading(false);
    yield handleError(error);
  }
}

function* POST_ExampleEffect({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const body = _.get(payload, "body", {});
    const query = _.get(payload, "query", {});

    const token = yield call(getToken);
    yield call(POST_Example, token, body);
    const { Result } = yield call(GET_Example, token, query);
    yield put({ type: "SAVE_Example", payload: Result });

    if (loading) loading(false);
    if (callback) callback();
  } catch (error) {
    if (loading) loading(false);
    yield handleError(error);
  }
}

function* PUT_ExampleEffect({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const body = _.get(payload, "body", {});
    const query = _.get(payload, "query", {});

    const token = yield call(getToken);
    yield call(PUT_Example, token, body);
    const { Result } = yield call(GET_Example, token, query);
    yield put({ type: "SAVE_Example", payload: Result });

    if (loading) loading(false);
    if (callback) callback();
  } catch (error) {
    if (loading) loading(false);
    yield handleError(error);
  }
}

function* PATCH_ExampleEffect({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const body = _.get(payload, "body", {});
    const query = _.get(payload, "query", {});

    const token = yield call(getToken);
    yield call(PATCH_Example, token, body);
    const { Result } = yield call(GET_Example, token, query);
    yield put({ type: "SAVE_Example", payload: Result });

    if (loading) loading(false);
    if (callback) callback();
  } catch (error) {
    if (loading) loading(false);
    yield handleError(error);
  }
}

function* DELETE_ExampleEffect({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const body = _.get(payload, "body", {});
    const query = _.get(payload, "query", {});

    const token = yield call(getToken);
    yield call(DELETE_Example, token, body);
    const { Result } = yield call(GET_Example, token, query);
    yield put({ type: "SAVE_Example", payload: Result });

    if (loading) loading(false);
    if (callback) callback();
  } catch (error) {
    if (loading) loading(false);
    yield handleError(error);
  }
}

export default function* Example() {
  yield takeLatest("GET_Example", GET_ExampleEffect);
  yield takeLatest("POST_Example", POST_ExampleEffect);
  yield takeLatest("PUT_Example", PUT_ExampleEffect);
  yield takeLatest("PATCH_Example", PATCH_ExampleEffect);
  yield takeLatest("DELETE_Example", DELETE_ExampleEffect);
}
