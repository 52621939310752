/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_UseLogList,
} from "../services/Statistics";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getUseLogList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_UseLogList, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_USE_LOG_LIST_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleExportLog({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_UseLogList, token, payload);
    if (res.isSuccess) {
    const i18n = yield select((state) => state.Global.i18n);
      window.open(res.result);
      showMessage({ type: 'success', content: i18n.export_success });
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}
export default function* Example() {
  yield takeLatest("GET_USE_LOG_LIST", getUseLogList);
  yield takeLatest("HANDLE_EXPORT_LOG", handleExportLog);
}
