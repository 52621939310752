import request from "../utils/request";

export function CREATE_Exam(token, payload) {
  return request.post("/exam", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_Exam(token, payload) {
  return request.put("/exam", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function UPDATE_Part(token, payload) {
  return request.put("/exam/part", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function COPY_Exam(token, payload) {
  return request.get(`/exam/copy?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function UPDATE_ExamTopic(token, payload) {
  return request.put("/exam/parttopic", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function SORT_Part(token, payload) {
  return request.put("/exam/order", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_ExamPartTopic(token, payload) {
  return request.post("/exam/topic/form", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_Exam(token, payload) {
  return request.post(`/exam/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_ExamForm(token, payload) {
  return request.get(`/exam/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_ExamById(token, payload) {
  return request.get(`/exam?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Exam(token, payload) {
  return request.delete(`/exam?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Part(token, payload) {
  return request.delete(`/exam/part?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}


export function DELETE_PartItem(token, payload) {
  return request.delete(`/exam/partitem?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}


export function GET_ExamByForm(token,) {
  return request.get(`/exam/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_ExamTypeList(token,) {
  return request.post(`/examtype/all`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_ExamTypeByForm(token,) {
  return request.get(`/examtype/form`, {}, {
    headers: {Authorization: token },
  });
}