import React from 'react';
import { Select } from 'antd';
import './Select.less';

const SearchInput = (props) => {
  return (
    <Select
      className={props.mode === 'multiple' ? "multipleFormSelectList": "formInputSelect"}
      allowClear={props.allowClear}
      showSearch
      style={props.style}
      mode={props.mode}
      placeholder={props.placeholder}
      // defaultValue={defaultValue}
      // onSearch={this.handleSearch}
      value={props.value}
      maxTagCount="responsive"
      onChange={props.onChange}
      // optionLabelProp="label"
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {
        props.options.map((item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
      }
    </Select>
  );

}

export default SearchInput;
