const Reducer = (state = {
  tagList: [],
  paging: {
    page: 1,
    pageCount: 10,
  },
  formTagList: [],
  rankData: [],
  rankTitle: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_TAG_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.tag_id,
        });
      })
      return { ...state, tagList: temp, paging: payload.paging };
    case "GET_TAG_FORM_LIST_SUCCESS":
      let tempForm = [];
      payload.results.map((item) => {
        tempForm.push({
          id: item.tag_id,
          name: item.tag_name,
        });
      })
      return { ...state, formTagList: tempForm };
    case "SAVE_TAG_LIST":
      return { ...state, formTagList: [...state.formTagList, {id: payload.tag_id, name: payload.tag_name}] };
    case "GET_TAG_RANK_SUCCESS":
      let tempData = [];
      let tempTitle = [];
      payload.map((item) => {
        tempData.push(item.count);
        tempTitle.push(item.tag_name);
      })
      return { ...state, rankData: tempData, rankTitle: tempTitle };
    default:
      return state;
  }
};

export default Reducer;
