import request from "../utils/request";

export function GET_ReviewList(token, payload) {
  return request.post("/exam/all/log", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_GetExamLogDetail(token, payload) {
  return request.get(`/exam/checked?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_UpdateReview(token, payload) {
  return request.put(`/exam/checked`, payload, {
    headers: {
      Authorization: token,
    },
  });
}
