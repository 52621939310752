import request from "../utils/request";

export function CREATE_Knowledge(token, payload) {
  return request.post("/knowledge", payload, {
    headers: {Authorization: token },
  });
}

export function UPDATE_Knowledge(token, payload) {
  return request.put("/knowledge", payload, {
    headers: {Authorization: token },
  });
}

export function GET_Knowledge(token, payload) {
  return request.post(`knowledge/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_KnowledgeById(token, payload) {
  return request.get(`knowledge?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Knowledge(token, payload) {
  return request.delete(`knowledge?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_KnowledgeByForm(token,) {
  return request.get(`knowledge/form`, {}, {
    headers: {Authorization: token },
  });
}
