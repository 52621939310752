import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Drawer, Upload } from 'antd';
import FormInput from "../components/common/FormInput";
import FormTextarea from "../components/common/FormTextarea";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import "./BookUpdate.less";
import _ from "lodash";
import { PlusOutlined } from '@ant-design/icons';
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BookUpdate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    is_random: false,
    is_practice: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('create');
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    props.getTopicTypeFormList();
    props.getTagFormList(8);
    let url = window.location.pathname.split('/book/update/')[1];
    const callback = (value) => {
      setItems(value.parts);
    }
    props.getBookById(url, setIsLoading, callback);
  }, []);

  useEffect(() => {
    setItems(props.bookDetail.parts);
  }, [props.bookDetail]);
  
  const renderDrawerTitle = () => {
    if (mode === 'create') {
      return props.i18n.character_create;
    } else if (mode === 'update') {
      return props.i18n.character_update;
    }  else if (mode === 'view') {
      return props.i18n.character_view;
    }
  }

  const renderDrawerView = () => {
    if (mode === 'updateBook') {
      return drawerVisible && renderBookDrawer();
    } else if (mode === 'createChapter') {
      return drawerVisible && renderCreateChapter();
    }
  }

  const handleUpdateBook = () => {
    setMode('updateBook');
    let tempTag = [];
    props.bookDetail.tags.map((item) => {
      tempTag.push(item.tag_id);
    });
    let tempAbility = [];
    props.bookDetail.abilities.map((item) => {
      tempAbility.push(item.ability_id);
    });
    let payload = {
      ...props.bookDetail,
      tags: tempTag,
      abilities: tempAbility,
    };
    let urlList = [];
    props.bookDetail.imgs.map((item) => {
      urlList.push({
        uid: item.file_id,
        url: item.file_url,
        name: 'img',
        status: 'done',
      });
    });
    setImageData(urlList);
    setFormValue(payload);
    setDrawerVisible(true);
  }

  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      createForm.current.setFieldsValue({ img: temp });
      setImageData(temp);
    } else {
      createForm.current.setFieldsValue({ img: newFileList });
      setImageData(newFileList);
    }
  };

  const renderBookDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleBookFinish}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_name}
            name="book_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_name}`}
          />
        </Col>
        <Col span={12}>
          <Form.Item 
            valuePropName="imgs"
            name="imgs"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.img}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.book_img}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="picture-card"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              onChange={handleImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#292C33'}}>{props.i18n.upload}</div>
              </div>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.book_effective}
            name="book_effective"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_effective}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.origin_price}
            name="book_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.discount_price}
            name="book_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>
        <Col span={12} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>上架狀態</div>}
            name="is_sell"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={24}>  
          <FormTextarea
            name="book_desc"
            title={props.i18n.exam_desc}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_desc}`}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const renderCreateChapter = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleCreateChapter}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_part_content}
            name="chapter_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_part_content}`}
          />
        </Col>

        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 8,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }

  const handleDeleteChapter = (item) => {
    let payload = {
      chapter_id: item.chapter_id,
      book_id: props.bookDetail.book_id,
    };
    props.handleDeleteChapter(payload, setIsLoading);
  }
  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleGetExamPartTopic = (item) => {
    // let tempTag = [];
    // let tempAbility = [];
    // items[key].tags.map((item) => {
    //   tempTag.push(item.tag_id);
    // })
    // items[key].abilities.map((item) => {
    //   tempAbility.push(item.ability_id);
    // })
    // let payload = {
    //   id: items[key].part_id,
    //   type: item.part_item_type,
    //   tags: tempTag,
    //   abilities: tempAbility,
    // };
    // const callback = () => {
    //   navigate(`/exam/topic/${props.bookDetail.exam_id}/${items[key].part_id}/${item.part_item_type}`);
    // }
    // props.getExamPartTopic(payload, setIsLoading, callback);
  }

  const handleCreateChapter = (value) => {
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleCreateChapter({
      ...value,
      book_id: props.bookDetail.book_id,
    }, setIsLoading, callback);
  }

  const handleBookFinish = (value) => {
    let formData = new FormData();
    formData.append("book_title", formValue.book_title);
    formData.append("book_id", props.bookDetail.book_id);
    formData.append("book_desc", (formValue.book_desc !== null && formValue.book_desc !== undefined ? formValue.book_desc: ''));
    if (formValue.book_effective !== undefined) {
      formData.append("book_effective", formValue.book_effective);
    } 
    formData.append("book_price", parseInt(formValue.book_price));
    if (formValue.book_discount !== undefined && formValue.book_discount !== null) {
      formData.append("book_discount", formValue.book_discount);
    }
    formData.append("is_sell", formValue.is_sell);
    if (imageData.length > 0) {
      if (_.has(imageData[0], 'originFileObj')) {
        formData.append("imgs[0].file", imageData[0].originFileObj);
      }
      if (props.bookDetail.imgs.length > 0) {
        formData.append("imgs[0].file_id", props.bookDetail.imgs[0].file_id);
      }
      formData.append("imgs[0].file_order", 1);
    }
    formData.append("book_title", formValue.book_title);
    formValue.chapters.map((item, index) => {
      formData.append(`chapters[${index}].chapter_title`, item.chapter_title);
      if (_.isArray(item.tags)) {
        item.tags.map((child) => {
          formData.append(`chapters[${index}].tags`, child);
        });
      }
      if (_.isArray(item.abilities)) {
        item.abilities.map((child) => {
          formData.append(`chapters[${index}].abilities`, child);
        });
      }
    });
    formValue.tags.map((child) => {
      formData.append(`tags`, child);
    });
    formValue.abilities.map((child) => {
      formData.append(`abilities`, child);
    });
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleUpdateBook(formData, setIsLoading, callback, props.bookDetail.book_id);
  }

  const handleShowCreateChapter = () => {
    setMode('createChapter');
    let tempTag = [];
    props.bookDetail.tags.map((item) => {
      tempTag.push(item.tag_id);
    });
    let tempAbility = [];
    props.bookDetail.abilities.map((item) => {
      tempAbility.push(item.ability_id);
    });
    setFormValue({
      tags: tempTag,
      abilities: tempAbility,
    });
    setDrawerVisible(true);
  }

  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px' }} className="scrollList">
    <div style={{ display: 'flex',}}>
      <div style={{ width: '228px', marginRight: '16px' }}>
        {(_.has(props.bookDetail, 'imgs') && props.bookDetail.imgs.length > 0) && <img alt="book" src={props.bookDetail.imgs[0].file_url} style={{ height: '153px', maxWidth: '228px', objectFit: 'co'}} />}
      </div>
      <div style={{ width: '100%'}}>
        <div style={{ fontSize: '18px', color: props.color.textColor}}>
          {props.bookDetail.book_title}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
          <img alt="book" src={Images.book_icon} style={{ width: '26.5px', height: '25px', marginRight: '8px' }} />
          <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>{props.bookDetail.is_sell ? '上架中': '已下架'}/有效天數： {props.bookDetail.book_effective} 天</span>
          <img alt="money" src={Images.money} style={{ width: '26px', height: '26px', marginRight: '8px', marginLeft: '16px' }} />
          {props.bookDetail.book_discount !== null ? <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>${props.bookDetail.book_discount} / $<span style={{ textDecoration: 'line-through'}}>{props.bookDetail.book_price}</span></span> : <span>${props.bookDetail.book_price}</span>}
        </div>
        <div style={{ fontSize: '18px', color: props.color.textColor, height: '32px', overflow: 'hidden'}} dangerouslySetInnerHTML={{ __html: (_.has(props.bookDetail, 'book_desc') && props.bookDetail.book_desc !== null) ? props.bookDetail.book_desc.replaceAll('\n', '<br />'): ''}}>
        </div>
        <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center'}}>
          <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.tags.map((child, index) => {
                return index < 3 && <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCF1DF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.tag_name}
                  </div>
              })
            }
            {props.bookDetail.tags.length > 3 && <div style={{
              width: '36px',
              height: '40px',
              lineHeight: '32px',
              borderRadius: '15px',
              backgroundColor: '#FCF1DF', 
              color: '#242933',
              textAlign: 'center',
              marginRight: '16px',
            }}>
              ...
            </div>}
          </div>
          {(props.bookDetail.tags.length > 0 && props.bookDetail.abilities.length > 0) &&<span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
          <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.abilities.map((child, index) => {
                return <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCD9BF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.ability_name}
                  </div>
              })
            }
          </div>
          <Button
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateBook}
          >
            <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
          </Button>
        </div>
      </div>
    </div>
    <div style={{ width: '100%', height: '1px', backgroundColor: '#26695C', marginTop: '8px', marginBottom: '16px' }}></div>
    <Row>
      <Col span={24}>
        <div style={{
          width: '100%',
          backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          height: '70px',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.book_part_content}</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '400px', minWidth: '400px', textAlign: 'left'}}>{props.i18n.tag}</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%', textAlign: 'left'}}>{props.i18n.ability}</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '100px', textAlign: 'center'}}>{props.i18n.operation}</div>
        </div>
        {props.bookDetail.chapters.map((item, index) => {
            return <div style={{ display: 'flex', alignItems: 'center', width: '100%',padding: '0px 16px 16px 8px', marginBottom: '16px', borderBottom: '1px solid #45C5AC'}}>
              <div style={{ fontSize: '16px', color: props.color.inputTitleColor, width: '100%', paddingLeft: '16px', }}><span style={{ fontWeight: 'bold', marginRight: '16px', fontSize: '24px'}}>{index +1 }</span>{item.chapter_title}</div>
              <div style={{ display: 'flex', minWidth: '400px', width: '400px', alignItems: 'center', overflow: 'hidden'}}>
                  {
                    item.tags.map((child, index) => {
                      return index < 3 && <div 
                        style={{ 
                          fontSize: '16px', 
                          padding: '0px 12px', 
                          backgroundColor: '#FCF1DF', 
                          marginRight: '12px',
                          color: '#242933',
                          height: '40px',
                          lineHeight: '40px',
                          textAlign: 'center',
                          borderRadius: '15px',
                        }}
                        >
                        {child.tag_name}
                        </div>
                    })
                  }
                  {item.tags.length > 3 && <div style={{
                    width: '36px',
                    height: '40px',
                    lineHeight: '32px',
                    borderRadius: '15px',
                    backgroundColor: '#FCF1DF', 
                    color: '#242933',
                    textAlign: 'center',
                    marginRight: '16px',
                  }}>
                    ...
                  </div>}
                </div>
              <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', width: '100%'}}>
                {
                  props.bookDetail.abilities.map((child, index) => {
                    return <div 
                      style={{ 
                        fontSize: '16px', 
                        padding: '0px 12px', 
                        backgroundColor: '#FCD9BF', 
                        marginRight: '12px',
                        color: '#242933',
                        height: '40px',
                        lineHeight: '40px',
                        textAlign: 'center',
                        borderRadius: '15px',
                      }}
                      >
                      {child.ability_name}
                      </div>
                  })
                }
              </div>
              <div style={{ minWidth: '100px', height: '50px', display: 'flex', alignItems: 'center'}}>
                <Button
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#42C1B4',
                    border: `1px solid #C1EFD6`,
                    borderRadius: '15px',
                    marginLeft: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => navigate(`/book/chapter/${props.bookDetail.book_id}/${item.chapter_id}`)}
                >
                  <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                </Button>
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '15px',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#D53838',
                    border: '1px solid #F31616',
                    marginLeft: '8px',
                  }}
                  onClick={() => handleDeleteChapter(item)}
                >
                  <img
                    src={Images.delete}
                    style={{ width: '22px', height: '14px'}}
                    alt={props.i18n.delete}
                  />
                </Button>
              </div>
            </div>
          })
        }

        <Button
          type="dashed"
          onClick={handleShowCreateChapter}
          block
          style={{
            fontSize: '18px',
            border: '2px dashed #0EA285',
            height: '50px',
            lineHeight: '50px',
            padding: '0px',
            color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
            backgroundColor: 'transparent',
            borderRadius: '15px',
          }}
        >
          + {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
     {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={mode === 'view' ? true :false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{renderDrawerTitle()}</div>
      </div>
      {renderDrawerView()}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    bookDetail: _.get(state, 'Book.bookDetail', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    handleDeletePartItem(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART_ITEM', payload, loading, callback});
    },
    getBookById(payload, loading, callback) {
      dispatch({ type: 'GET_BOOK_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleUpdateBook(payload, loading, callback, id) {
      dispatch({ type: 'HANDLE_UPDATE_BOOK', payload, loading, callback, id});
    },
    getExamPartTopic(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_PART_TOPIC', payload, loading, callback});
    },
    handleDeletePart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART', payload, loading, callback});
    },
    handleDeleteChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_CHAPTER', payload, loading, callback});
    },
    handleCreateChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_CHAPTER', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    handleSortPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_SORT_PART', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookUpdate);
