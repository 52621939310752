const THEME_KEY = "THEME";

export function getTheme() {
  return localStorage.getItem(THEME_KEY);
}

export function setTheme(theme) {
  return localStorage.setItem(THEME_KEY, theme);
}

export function removeTheme() {
  return localStorage.removeItem(THEME_KEY);
}
