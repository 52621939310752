import request from "../utils/request";

export function POST_Forget(payload) {
  return request.post("/auth/forget", payload, {
    headers: {},
  });
}

export function POST_Login(payload) {
  return request.post("/auth/login", payload, {
    headers: {},
  });
}

export function PUT_PasswordChange(payload) {
  return request.put("/auth/changePassword", payload, {
    headers: {},
  });
}

export function GET_AllPermission(token) {
  return request.get(`/auth/permission/form`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_SchoolPermission(token, payload) {
  return request.get(`/auth/permission/school?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_SchoolPermission(token, payload) {
  return request.put(`/auth/kgroup`, payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_RoleList(token) {
  return request.get(`/auth/role/form`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_RoleByPermission(token) {
  return request.get(`/auth/permission?is_front=false`, {}, {
    headers: {
      Authorization: token,
    },
  });
}