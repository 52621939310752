const Reducer = (state = {
  paging: {
    page: 1,
    pageCount: 12,
  },
  newsList: [],
  newsDetail: {}
}, { type, payload }) => {
  switch (type) {
    case "GET_NEWS_LIST_SUCCESS":
      return { ...state, newsList: payload.results, paging: payload.paging };
    case "GET_NEWS_DETAIL_SUCCESS":
      return { ...state, newsDetail: payload };
    default:
      return state;
  }
};

export default Reducer;
