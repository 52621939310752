const Reducer = (state = {
  talkList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  talkFormList: [],
  talkDetail: {
    tags: [],
    abilities: [],
    chapters: [],
  },
  talkCC: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_TALK_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.talk_id,
        });
      })
      return { ...state, talkList: temp, paging: payload.paging };
    case "GET_TALK_FORM_LIST_SUCCESS":
      return { ...state, talkFormList: payload };
    case "GET_TALK_BY_ID_SUCCESS":
      return { ...state, talkDetail: payload };
    case "GET_TALK_CC_SUCCESS":
      return { ...state, talkCC: payload };
    default:
      return state;
  }
};

export default Reducer;
