const Reducer = (state = {
  bookList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  bookFormList: [],
  bookDetail: {
    tags: [],
    abilities: [],
    chapters: [],
  },
  topicDetail: {
    part_topics: [],
    topics: [],
    checkList: [],
  },
  chapterDetail: {
  },
  chapterTeacher: [],
  chapterStudent: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_BOOK_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.book_id,
        });
      })
      return { ...state, bookList: temp, paging: payload.paging };
    case "GET_BOOK_FORM_LIST_SUCCESS":
      return { ...state, bookFormList: payload };
    case "GET_BOOK_TOPIC_SUCCESS":
      let tempList = [];
      payload.topics.map((item) => {
        tempList.push({
          ...item,
          key: item.topic_id,
        });
      })
      let checkList = [];
      payload.part_topics.map((item) => {
        checkList.push(item.topic_id);
      });
      return { ...state, topicDetail: {...payload, topics: tempList, checkList} };
    case "GET_BOOK_BY_ID_SUCCESS":
      return { ...state, bookDetail: payload };
    case "GET_BOOK_CHAPTER_BY_ID_SUCCESS":
      return { ...state, chapterDetail: payload.chapter, chapterTeacher: payload.teacher !== null ? payload.teacher: [], chapterStudent: payload.student !== null ? payload.student: [] };
    default:
      return state;
  }
};

export default Reducer;
