import _ from 'lodash';
const Reducer = (state = {
  downloadList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
}, { type, payload }) => {
  switch (type) {
    case "GET_DOWNLOAD_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        let tempUrl = item.url.split('.');
        let fileType = '';
        if (_.isArray(tempUrl)) {
          fileType = tempUrl[tempUrl.length - 1];
        }
        temp.push({
          ...item,
          key: item.download_id,
          fileType,
        });
      });
      return { ...state, downloadList: temp, paging: payload.paging };
    default:
      return state;
  }
};

export default Reducer;
