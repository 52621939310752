/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_TopicGroup,
  CREATE_TopicGroup,
  UPDATE_TopicGroup,
  DELETE_TopicGroup,
  GET_TopicGroupById,
  GET_TopicGroupByForm, 
  GET_TopicTypeByForm,
} from "../services/TopicGroup";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getTopicGroupList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_TopicGroup, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_TOPIC_GROUP_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getTopicGroupListByForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_TopicGroupByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_TOPIC_GROUP_LIST_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getTopicGroupById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_TopicGroupById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateTopicGroup({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_TopicGroup, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_TOPIC_GROUP_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateFormTopicGroup({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_TopicGroup, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success })
      yield put({ type: 'SAVE_TOPIC_GROUP', payload: res.result})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateTopicGroup({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_TopicGroup, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_TOPIC_GROUP_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteTopicGroup({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_TopicGroup, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_TOPIC_GROUP_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_TOPIC_GROUP_LIST", getTopicGroupList);
  yield takeLatest("GET_TOPIC_GROUP_BY_ID", getTopicGroupById);
  yield takeLatest("HANDLE_CREATE_FORM_TOPIC_GROUP", handleCreateFormTopicGroup);
  yield takeLatest("HANDLE_CREATE_TOPIC_GROUP", handleCreateTopicGroup);
  yield takeLatest("HANDLE_UPDATE_TOPIC_GROUP", handleUpdateTopicGroup);
  yield takeLatest("HANDLE_DELETE_TOPIC_GROUP", handleDeleteTopicGroup);
  yield takeLatest("GET_TOPIC_GROUP_LIST_BY_FORM", getTopicGroupListByForm);
}
