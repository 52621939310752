/* eslint-disable import/no-anonymous-default-export */

// prettier-ignore
const api = {
  protocol: process.env.REACT_APP_API_PROTOCOL || "http",
  host: process.env.REACT_APP_API_HOST || "localhost",
  port: process.env.REACT_APP_API_PORT ? ":" + process.env.REACT_APP_API_PORT : "",
  prefix: process.env.REACT_APP_API_PREFIX || "",
};

export default `${api.protocol}://${api.host}${api.port}${api.prefix}`;
