import React from 'react';
import { Select, Form } from 'antd';
import './FormInputSelect.less';
import _ from "lodash";
import { connect } from "react-redux";

const FormInputSelect = (props = { required: false, rules: [], type: 'text',formItemStyle: {}, ...props }) => {
  return (
    <Form.Item
      label={
        (props.title !== "" && props.title !== undefined) ? <div
          style={{
            color: props.textColor,
            fontSize: '18px',
            padding: props.required ? '0px' :'0px 16px',
            fontWeight: 'bold'
          }}>{props.title}</div>: null
      }
      name={props.name}
      colon={false}
      rules={[
        {
          required: props.required,
          message: (props.requiredMessage !== '' && props.requiredMessage !== undefined) ? props.requiredMessage :`${props.i18n.placeholder}${props.title}`,
        },
        {
          type: props.type,
          message: props.i18n.typeError, 
        }
      ]}
      style={{ width: '100%', ...props.formItemStyle}}
      className={props.mode === 'multiple' ? "multipleFormSelectList": "formInputSelect"}
    >
      <Select
        allowClear={props.allowClear}
        showSearch
        maxTagCount="responsive"
        mode={props.mode}
        placeholder={props.placeholder}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          props.options.map((item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
        }
      </Select>
    </Form.Item>
  );

}


const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormInputSelect);
