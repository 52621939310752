import request from "../utils/request";

export function CREATE_Talk(token, payload) {
  return request.post("/talk", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function UPDATE_Talk(token, payload) {
  return request.put("/talk", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function DELETE_Talk(token, payload) {
  return request.delete(`/talk?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function UPDATE_TalkSell(token, payload) {
  return request.put(`/talk/sell?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_Talk(token, payload) {
  return request.post(`/talk/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_TalkById(token, payload) {
  return request.get(`/talk?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function POST_ImportCC(token, payload) {
  return request.post(`/talk/cc`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_TalkCC(token, payload) {
  return request.get(`/talk/cc?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_TalkModule(token, payload) {
  return request.put(`/talk/module`, payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function POST_UploadAudio(token, payload) {
  return request.post(`/talk/video`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function POST_UploadImg(token, payload) {
  return request.post(`/talk/img`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}