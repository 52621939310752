/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_DownloadList,
  GET_DownloadById,
  CREATE_Download,
  PUT_Download,
  DELETE_Download,
} from "../services/Download";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getDownloadList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_DownloadList, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_DOWNLOAD_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getDownloadById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_DownloadById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateDownload({ payload, loading, callback, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Download, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success });
      yield put({ type: 'GET_DOWNLOAD_LIST', payload: getData})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateDownload({ payload, loading, callback, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_Download, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success });
      yield put({ type: 'GET_DOWNLOAD_LIST', payload: getData})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* handleDeleteDownload({ payload, loading, callback, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Download, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.delete_success });
      yield put({ type: 'GET_DOWNLOAD_LIST', payload: getData})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Download() {
  yield takeLatest("GET_DOWNLOAD_LIST", getDownloadList);
  yield takeLatest("GET_DOWNLOAD_BY_ID", getDownloadById);
  yield takeLatest("HANDLE_CREATE_DOWNLOAD", handleCreateDownload);
  yield takeLatest("HANDLE_UPDATE_DOWNLOAD", handleUpdateDownload);
  yield takeLatest("HANDLE_DELETE_DOWNLOAD", handleDeleteDownload);
}
