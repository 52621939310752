import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Pagination, Select } from 'antd';
import Search from "../components/common/Search";
import Images from "../themes/Images";
import Swal from 'sweetalert2';


import "./Lottery.less";
import _ from "lodash";

let timer;
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
]

const Lottery = (props) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: null,
      page: 1,
      tags: [],
      abilities: [],
      pageCount: 12,
      orderKeys: [],
      is_front: false,
    };
    if (param.get('search') !== null) {
      setSearch(param.get('search'));
      payload.search = param.get('search');
    }
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    props.getLotteryList(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleDeleteLottery = (id) => {
    const { handleDeleteLottery, paging } = props;

    let getData = {
      is_front: false,
      search: search,
      tags: [],
      abilities: [],
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteLottery(id, setIsLoading, () => {}, getData);
      }
    });
  }


  const handleSizeChange = (e) => {
    navigate(`/lottery?search=${search}&page=1${e !== 12 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/lottery?search=${search}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/lottery?search=${e}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }
 
  return <div id="lottery">
    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={() => navigate('/lottery/create')}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 273}px`, marginRight: '0px !important', paddingBottom: '8px' }}>
      {
        props.lotteryList.map((item) => {
          return <Col key={item.act_id} span={8} style={{
            // height: '225px',
            width: '100%',
            marginTop: '30px'
          }}>
            <div
              style={{
                width: '100%',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                backdropFilter: 'blur(30px) brightness(1.5)',
                overflow: 'hidden'
              }}
            >
              <div style={{ fontSize: '18px', color: props.color.textColor, padding: '0px 24px', height: '40px', lineHeight: '40px', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all', marginBottom: '8px'}}>
                {item.act_title}
              </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#85D1C2', margin: '4px 0px' }}></div>
              <div style={{
                height: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 24px',
                backgroundColor: '#ACF8EF',
                alignItems: 'center',
                borderBottomLeftRadius: '30px',
                borderBottomRightRadius: '30px',
              }}>
                <div style={{ display: 'flex'}}>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/lottery/log/${item.act_id}`)}
                  >
                    <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/lottery/update/${item.act_id}`)}
                  >
                    <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#D53838',
                      border: `1px solid #F31616`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDeleteLottery(item.act_id)}
                  >
                    <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
                  </Button>
                </div>
              </div>
            </div>

          </Col>
        })
      }
    </Row>

    <div className="pagination" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
      <Pagination
        defaultCurrent={props.paging.page}
        total={props.paging.total}
        current={props.paging.page}
        pageSize={props.paging.pageCount}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
      <Select
        id="selectList"
        style={{ width: '112px', marginLeft: '16px'}}
        defaultValue={props.paging.pageCount}
        value={props.paging.pageCount}
        onChange={handleSizeChange}
      >
        {
          sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {props.i18n.page}</Select.Option>)
        }
      </Select>
    </div>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    lotteryList: _.get(state, 'Lottery.lotteryList', []),
    paging: _.get(state, 'Lottery.paging', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLotteryList(payload, loading, callback) {
      dispatch({ type: 'GET_LOTTERY_LIST', payload, loading, callback});
    },
    handleDeleteLottery(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_LOTTERY', payload, loading, callback, getData});
    },
    getLotteryById(payload, loading, callback) {
      dispatch({ type: 'GET_LOTTERY_BY_ID', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lottery);
