/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import FormInput from "../components/common/FormInput";

import "./Login.less";
import Images from "../themes/Images";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const App = (props) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleForget = (value) => {
    const callback = () => {
      navigate('/login');
    }
    props.handleForget(value, callback, setIsLoading);
  }

  return <div id="Home">   
    <div style={{ width: '100%', textAlign: 'center', fontSize: '18px', color: '#D9D9D9'}}>忘記密碼</div>
    <Form
      {...layout}
      name="basic"
      initialValues={{
        account: '',
        password: '',
      }}
      style={{ marginTop: '16px'}}
      onFinish={value => handleForget(value)}
    >
      <Form.Item
        label={<div style={{ color: '#2EC1A4', fontSize: '16px', padding: '0px 16px'}}>請輸入你申請的E-mail</div>}
        name="email"
        colon={false}
      >
        <Input
          autoComplete="new-account"
          style={{
            borderRadius: '40px',
            height: '45px',
            color: 'white',
            border: '0px',
            padding: '0px 16px',
            backgroundColor: '#44474B',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
            width: '100%',
          }}
          placeholder='請輸入信箱'
        />
      </Form.Item>
      <Form.Item style={{ marginTop: '30px'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{ height: '40px', backgroundColor: '#5BDFC6', width: '100%', borderRadius: '20px', color: 'black' }}
        >
          送出
        </Button>
        <Button
          loading={isLoading}
          onClick={() => navigate('/login')}
          style={{
            height: '40px',
            backgroundColor: '#83ABA3',
            width: '100%',
            borderRadius: '20px',
            color: 'black',
            marginTop: '16px',
          }}
        >
          返回
        </Button>
      </Form.Item>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleForget(payload, callback, loading) {
      dispatch({ type: 'HANDLE_FORGET', payload, callback, loading });
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
