import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Pagination, Select, Drawer, Form, Upload } from 'antd';
import Search from "../components/common/Search";
import Images from "../themes/Images";
import Swal from 'sweetalert2';
import FormInput from "../components/common/FormInput";
import FormInputSelect from "../components/common/FormInputSelect";



import "./Download.less";
import _ from "lodash";

let timer;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const sizeList = [
  {
    id: 30,
    name: 30,
  },
  {
    id: 60,
    name: 60,
  },
  {
    id: 90,
    name: 90,
  },
]

const Download = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [search, setSearch] = useState('');
  const [fileData, setFileData] = useState([]);
  const [formMode, setFormMode] = useState('create');
  const [formValue, setFormValue] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    handleGetData();
    props.getRoleList();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: null,
      page: 1,
      pageCount: 30,
      orderKeys: [],
    };
    if (param.get('search') !== null) {
      setSearch(param.get('search'));
      payload.search = param.get('search');
    }
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    props.getDownloadList(payload, props.saveLoading,);
  }

  const handleFileChange = ({ fileList: newFileList }) => {
    let file;
    if (newFileList.length > 1) {
      file = newFileList.splice(1, 1);
      setFileData(file);
    } else {
      file = newFileList;
      setFileData(newFileList);
    }
    if (_.isArray(file) && file.length > 0) {
      createForm.current.setFieldsValue({ name: file[0].name })
    }
  };

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleDeleteDownload = (e, id) => {
    const { handleDeleteDownload, paging } = props;

    e.stopPropagation();
    let getData = {
      is_front: false,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteDownload(id, props.saveLoading, () => {}, getData);
      }
    });
  }
  
  const handleSubmit = (value) => {
    if (formMode === 'create') {
      handleCreate(value);
    } else {
      handleUpdateDownload(value);
    }
  }

  const handleUpdateDownload = (value) => {
    const { handleUpdateDownload, paging } = props;
    let getData = {
      is_front: false,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    }
    let formData = new FormData();
    formData.append('id', formValue.id);
    formData.append('name', value.name);
    formData.append('file', null);
    if (_.isArray(value.roles)) {
      value.roles.map((item) => {
        formData.append('roles', item);
      });
    }
    const callback = () => {
      setDrawerVisible(false);
      setFormMode('create');
      setFileData([]);
    }
    handleUpdateDownload(formData, props.saveLoading, callback, getData)
  }

  const handleCreate = (value) => {
    const { handleCreateDownload, paging } = props;
    let getData = {
      is_front: false,
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    }
    let formData = new FormData();
    formData.append('file', fileData[0].originFileObj);
    formData.append('name', value.name);
    if (_.isArray(value.roles)) {
      value.roles.map((item) => {
        formData.append('roles', item);
      });
    }
    const callback = () => {
      setDrawerVisible(false);
      setFormMode('create');
      setFileData([]);
    }
    handleCreateDownload(formData, props.saveLoading, callback, getData)
  }


  const handleSizeChange = (e) => {
    navigate(`/download?search=${search}&page=1${e !== 30 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/download?search=${search}${props.paging.pageCount !== 30 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/download?search=${e}${props.paging.pageCount !== 30 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }

  const renderFileType = (type) => {
    switch(type) {
      case 'xls': 
      case 'xlsx': 
        return Images.download_xls;
      case 'flv':
      case 'mp4':
      case 'mov':
      case 'wmv':
      case 'mpeg':
        return Images.download_movie;
      case 'wav':
      case 'mp3':
      case 'aac':
      case 'flac':
      case 'ape':
      case 'alac':
      case 'wavpack':
        return Images.download_audio;
      case 'bmp':
      case 'png':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'mng':
      case 'xpm':
      case 'psd':
      case 'ufo':
      case 'xcf':
      case 'pcx':
      case 'ppm':
      case 'WebP':
      case 'svg':
        return Images.download_image;
      case 'txt':
      case 'rtf':
      case 'inf':
      case 'tex':
      case 'ltx':
        return Images.download_txt;
      case 'pdf':
        return Images.download_pdf;
      case 'doc':
        return Images.download_doc;
      case 'ppt':
      case 'pptx':
        return Images.download_ppt;
      case '7z':
      case 'zip':
      case 'rar':
        return Images.download_zip;
      default:
        return Images.download_unKnow;
    }
  }
 
	const downloadEmployeeData = (name, url) => {
		fetch(url)
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = name;
					a.click();
				});
		});
	}

  const handleUpdate = (data) => {
    const callback = (value) => {
      setFormMode('update');
      setFormValue(value);
      setDrawerVisible(true);
    }
    props.getDownloadById(data.id, props.saveLoading, callback);
  }

  return <div id="download">
    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={() => {
            setFormMode('create')
            setDrawerVisible(true);
          }}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 273}px`, marginRight: '0px !important', paddingBottom: '8px' }}>
      {
        props.downloadList.map((item) => {
          return <Col
            key={item.act_id}
            xs={24}
            xm={24}
            md={8}
            lg={6}
            xl={4}
            style={{
              marginTop: '30px',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: '100%',
                borderRadius: '15px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                backdropFilter: 'blur(30px) brightness(1.5)',
                overflow: 'hidden',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px 12px'}}>
                <div style={{ width: '22px'}}>
                  <img alt="img" src={renderFileType(item.fileType)} style={{ width: '21px', height: '21px' }} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    width: '100%',
                    color: props.color.textColor,
                    lineHeight: '75px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    textAlign: 'left',
                    margin: '0px 8px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.name}
                </div>
              </div>

              <div
                style={{ 
                  height: '64px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0px 12px',
                  backgroundColor: '#ACF8EF',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#42C1B4',
                    border: `1px solid #C1EFD6`,
                    borderRadius: '15px',
                    marginLeft: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => downloadEmployeeData(item.name, item.url)}
                >
                  <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                </Button>
                <Button
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#42C1B4',
                    border: `1px solid #C1EFD6`,
                    borderRadius: '15px',
                    marginLeft: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleUpdate(item)}
                >
                  <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                </Button>
                <Button
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#D53838',
                    border: `1px solid #F31616`,
                    borderRadius: '15px',
                    marginLeft: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={(e) => handleDeleteDownload(e, item.id)}
                >
                  <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
                </Button>
              </div>
            </div>

          </Col>
        })
      }
    </Row>

    <div className="pagination" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
      <Pagination
        defaultCurrent={props.paging.page}
        total={props.paging.total}
        current={props.paging.page}
        pageSize={props.paging.pageCount}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
      <Select
        id="selectList"
        style={{ width: '112px', marginLeft: '16px'}}
        defaultValue={props.paging.pageCount}
        value={props.paging.pageCount}
        onChange={handleSizeChange}
      >
        {
          sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {props.i18n.page}</Select.Option>)
        }
      </Select>
    </div>

    <Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.download_create_title}</div>
      </div>
      {drawerVisible && <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          name: '',
          ...formValue,
        }}
        style={{ marginTop: '16px',}}
        onFinish={handleSubmit}
      >
        <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 194}px`, marginRight: '0px', paddingBottom: '8px' }}>
          {formMode === 'create' && <Col span={24}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px',
                  fontWeight: 'bold'
                }}>{props.i18n.download_file}</div>}  
              valuePropName="fileList"
              required
              getValueFromEvent={normFile}
            >
              <Upload 
                onChange={handleFileChange}
                fileList={fileData}
                customRequest={dummyRequest}
              >
                <Button
                  style={{
                    height: '50px',
                    backgroundColor: '#4A9E8F',
                    width: '128px',
                    borderRadius: '20px',
                    color: 'white',
                    border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                    marginRight: '30px'
                  }}
                >
                  {props.i18n.download_create_title}
                </Button>
              </Upload>
            </Form.Item>
          </Col>}
          <Col span={24}>
            <FormInput
              name="name"
              required
              title={props.i18n.download_name}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.download_name}`}
            />
          </Col>
          <Col span={24}>
            <FormInputSelect
              name="roles"
              mode="multiple"
              type="array"
              required
              options={props.roleList}
              allowClear={true}
              title={props.i18n.download_role}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.download_role}`}
            />
          </Col>
        </Row>
        <Form.Item style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
          <Button
            loading={props.loading}
            htmlType="submit"
            style={{
              height: '50px',
              backgroundColor: '#4A9E8F',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              marginRight: '30px'
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={props.loading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: '50px',
              backgroundColor: '#D53838',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
            }}
          >
            
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>}
    </Drawer>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    loading: _.get(state, 'Global.loading', false),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    downloadList: _.get(state, 'Download.downloadList', []),
    paging: _.get(state, 'Download.paging', {}),
    roleList: _.get(state, 'User.roleList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDownloadList(payload, loading, callback) {
      dispatch({ type: 'GET_DOWNLOAD_LIST', payload, loading, callback});
    },
    handleCreateDownload(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CREATE_DOWNLOAD', payload, loading, callback, getData});
    },
    handleUpdateDownload(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_UPDATE_DOWNLOAD', payload, loading, callback, getData});
    },
    handleDeleteDownload(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_DOWNLOAD', payload, loading, callback, getData});
    },
    saveLoading(payload, loading, callback, getData) {
      dispatch({ type: 'SAVE_LOADING', payload, loading, callback, getData});
    },
    getRoleList(payload, loading, callback) {
      dispatch({ type: 'GET_ROLE_LIST', payload, loading, callback});
    },
    getDownloadById(payload, loading, callback) {
      dispatch({ type: 'GET_DOWNLOAD_BY_ID', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
