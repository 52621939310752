
import request from "../utils/request";

export function GET_CourseForm(token) {
  return request.get("/exam/course/form", {}, {
    headers: {
      Authorization: token,
    },
  });
}
export function CREATE_Course(token, payload) {
  return request.post("/course", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_Course(token, payload) {
  return request.put("/course", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_Course(token, payload) {
  return request.post(`/course/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_CourseMember(token, payload) {
  return request.get(`/course/member?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_CourseById(token, payload) {
  return request.get(`/course?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Course(token, payload) {
  return request.delete(`/course?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function POST_ImportCourseMember(token, payload) {
  return request.post(`/course/import/addMember`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_ExportCourseBuyMember(token, payload) {
  return request.get(`/buy/order/log/export?type=10&course_id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function POST_PageTopicLog(token, payload) {
  return request.post(`/book/pagetopiclog/all`, payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function POST_ExportPageTopicLog(token, payload) {
  return request.post(`/book/pagetopiclog/export`, payload, {
    headers: {
      Authorization: token,
    },
  });
}