import request from "../utils/request";

export function CREATE_Tag(token, payload) {
  return request.post("/tag", payload, {
    headers: {Authorization: token },
  });
}

export function UPDATE_Tag(token, payload) {
  return request.put("/tag", payload, {
    headers: {Authorization: token },
  });
}

export function GET_Tag(token, payload) {
  return request.post(`tag/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_TagById(token, payload) {
  return request.get(`tag?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TagRank(token, payload) {
  return request.get(`tag/rank?tagType=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Tag(token, payload) {
  return request.delete(`/tag`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_TagForm(token, payload) {
  return request.get(`tag/form?type=${payload}`, {}, {
    headers: {Authorization: token },
  });
}