import React from 'react';
import Images from "../../themes/Images";
import ReactPlayer from 'react-player'
import './Topic.less';

let optionList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const Topic = (props) => {
  const { data } = props;
  return (
    <div style={{ paddingTop: '30px'}}>
      <span
        style={{
          color: props.color.textColor,
          fontSize: '18px',
          borderBottom: '2px solid #13D1AC',
        }}
      >NO.{data.topic_id} {data.topic_type_name}</span>

      {<div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
          marginTop: '16px',
        }}>{props.i18n.topic_manager_title}
      </div>}
      {(data.topic_title !== null && data.topic_title !== "") &&<div
        style={{
          fontSize: '18px',
          color: props.color.textColor,
        }}
        dangerouslySetInnerHTML={{ __html: data.topic_title }}
        >
      </div>}
      {data.imgs !== null && data.imgs.map((item) => {
        return <img src={item.file_url} alt='img' style={{ width: 'auto', maxWidth: '100%', height: 'auto', marginBottom: '12px'}} />
      })}
      {data.audio !== null && data.audio.map((item) => {
        return <ReactPlayer controls url={item.file_url} style={{ marginBottom: '12px', maxWidth: '100%', height: 'auto'}}  />
      })}
      {data.options !== null && data.options.map((child, childIndex) => {
        return <div
          style={{
            fontSize: '14px',
            color: props.color.textColor,
            display: 'flex',
            alignItems: 'center'
          }}>
          {child.is_answer ? <img src={Images.tick} alt="tick" style={{ width: '17px', height: '13px', marginRight: '16px'}} />: <div style={{ width: '17px', height: '13px', marginRight: '16px'}} /> }
          {optionList[childIndex]}. {child.topic_option_name}
          {child.topic_option_img !== null && <ReactPlayer height="54px" width="300px" controls url={child.topic_option_img} style={{ marginBottom: '12px' }} />}
          {child.topic_option_audio !== null && <ReactPlayer height="54px" width="300px" controls url={child.topic_option_audio} style={{ marginBottom: '12px' }} />}
        </div>
      })}
      {(data.topic_answer !== '' && data.topic_answer !== null && (data.options === null || data.options.length === 0)) &&<div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
        }}>{props.i18n.topic_manager_answer}
      </div>}
      {(data.topic_answer !== '' && data.topic_answer !== null && (data.options === null || data.options.length === 0)) &&<div
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
          marginBottom: '16px'
        }}>{data.topic_answer}
      </div>}
      {(data.topic_detail !== '' && data.topic_detail !== null) && <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
        }}>{props.i18n.topic_manager_answer_desc}
      </div>}
      <div
        style={{
          fontSize: '14px',
          color: props.color.textColor,
        }}
        dangerouslySetInnerHTML={{ __html: data.topic_detail }}
        >
      </div>
      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
        }}>{props.i18n.tag}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px', flexWrap: 'wrap', width: '100%'}}>
        {
          data.tags.map((item) => {
            return <div 
            style={{ 
              height: '40px', 
              lineHeight: '40px',
              padding: '0px 12px',
              backgroundColor: '#FCF1DF',
              color: '#242933',
              marginRight: '16px',
              borderRadius: '15px',
              textAlign: 'center'
             }}>
              {item.tag_name}
            </div>
          })
        }
      </div>
      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
          marginTop: '30px',
        }}>{props.i18n.ability}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px', flexWrap: 'wrap', width: '100%'}}>
        {
          data.abilities.map((item) => {
            return <div 
            style={{ 
              height: '40px', 
              lineHeight: '40px',
              padding: '0px 12px',
              backgroundColor: '#FCF1DF',
              color: '#242933',
              marginRight: '16px',
              borderRadius: '15px',
              textAlign: 'center'
             }}>
              {item.ability_name}
            </div>
          })
        }
      </div>

      <div style={{ width: '100%', height: '1px', backgroundColor: '#159E90', marginRight: '12px', marginBottom: '16px', marginTop: '16px' }}></div>
      {(data.items !== null && data.items.length > 0) &&<div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginTop: '16px',
          color: props.color.inputTitleColor,
        }}>{props.i18n.topic_manager_topic_list}
      </div>}
      {(data.items !== null && data.items.length > 0) && data.items.map((item, index) => {
        return <div>
          <div style={{ display: 'flex'}}>
            <div
              style={{
                fontSize: '14px',
                color: props.color.textColor,
                marginRight: '4px'
              }}
            >
              {index + 1}.
            </div>
            
            {item.imgs !== null && item.imgs.map((child) => {
              return <img src={child.file_url} alt='img' style={{ width: 'auto', maxWidth: '100%', height: 'auto', marginBottom: '16px'}} />
            })}
            {item.audio !== null && item.audio.map((child) => {
              return <ReactPlayer controls url={child.file_url} style={{ marginBottom: '16px'}} />
            })}
            {item.topic_item_title !== '' && <div
              style={{
                fontSize: '14px',
                color: props.color.textColor,
              }}
              dangerouslySetInnerHTML={{ __html: item.topic_item_title }}
              >
            </div>}
          </div>
          {item.options !== null && item.options.map((child, childIndex) => {
            return <div
              style={{
                fontSize: '14px',
                color: props.color.textColor,
                display: 'flex',
                alignItems: 'center'
              }}>
              {child.is_answer ? <img src={Images.tick} alt="tick" style={{ width: '17px', height: '13px', marginRight: '16px'}} />: <div style={{ width: '17px', height: '13px', marginRight: '16px'}} /> }
              {optionList[childIndex]}. {child.topic_option_name}
            </div>
          })}
          {(item.options === null || item.options.length === 0) &&<div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: props.color.inputTitleColor,
            }}>{props.i18n.topic_manager_answer}
          </div>}
          {(item.options === null || item.options.length === 0) &&<div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: props.color.inputTitleColor,
              marginBottom: '16px'
            }}>{item.topic_item_answer}
          </div>}
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: props.color.inputTitleColor,
            }}>{props.i18n.topic_manager_answer_desc}
          </div>
          <div
            style={{
              fontSize: '14px',
              color: props.color.textColor,
            }}
            dangerouslySetInnerHTML={{ __html: item.topic_item_detail }}
            >
          </div>
        </div>
      })}
    </div>
  );

}

export default Topic;
