import React from 'react';
import { Form, Input } from 'antd';
import './CommonTextarea.less';
import _ from "lodash";
import { connect } from "react-redux";

const { TextArea } = Input;
const CommonTextarea = (props = { required: false, rules: [], type: 'text', formItemStyle: {}, requiredMessage: '', rowHeight: 3, ...props }) => {
  return (
    <TextArea
      id="CommonTextarea"
      autoComplete="new-account"
      style={{
        borderRadius: '15px',
        color: '#125346',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
        width: '100%',
        ...props.style,
      }}
      value={props.value}
      rows={props.rowHeight}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonTextarea);