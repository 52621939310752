/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/table.css'
import BraftEditor from 'braft-editor';
import 'braft-extensions/dist/color-picker.css';
import ColorPicker from 'braft-extensions/dist/color-picker'
import Table from "braft-extensions/dist/table";
import './HtmlEditor.less';
import { Form, Input } from 'antd';

const options = {
  defaultColumns: 3, // 默认列数
  defaultRows: 3, // 默认行数
  withDropdown: false, // 插入表格前是否弹出下拉菜单
  columnResizable: false, // 是否允许拖动调整列宽，默认false
  exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
  includeEditors: ['editor'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
  excludeEditors: ['editor-id-2']  // 指定该模块对哪些BraftEditor无效
}
 
BraftEditor.use(Table(options))
BraftEditor.use(ColorPicker({
  includeEditors: ['editor-with-color-picker'],
  theme: 'light' // 支持dark和light两种主题，默认为dark
}))

 
class HtmlEditor extends React.Component {
  static propTypes = {
    propName: PropTypes.string,
    inputStyle: PropTypes.object, // Input 樣式
  };

  static defaultProps = {
    required: false,
    type: 'text',
    requiredErrorMessage: '此項目為必填',
    placeholder: '',
    rule: [],
    inputStyle: {},
    labelCol: 24,
    wrapperCol: 24,
    i18nMode: 'zh',
  };

  constructor(props) {
    super(props);
    this.state = {
      editValue: BraftEditor.createEditorState(props.value),
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      editValue: BraftEditor.createEditorState(value),
    });
  }
  onChangeValue = (value) => {
    const { onEditorStateChange } = this.props;
    this.setState({
      editValue: value,
    });
    onEditorStateChange(value);
  };

  
  uploadFn = (param) => {
  
    // const serverURL = 'http://localhost:5000/api/member/file'
    const serverURL = process.env.REACT_APP_FILE_URL || 'https://dts.backend.winhome1688.com.tw/api/member/file'
    const xhr = new XMLHttpRequest()
    const fd = new FormData()
  
    // libraryId可用于通过mediaLibrary示例来操作对应的媒体内容 
    const successFn = (response) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: JSON.parse(xhr.responseText).result,
      });
    }
  
    const progressFn = (event) => {
      // 上传进度发生变化时调用param.progress
      param.progress(event.loaded / event.total * 100)
    }
  
    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: 'unable to upload.'
      })
    }
  
    xhr.upload.addEventListener("progress", progressFn, false)
    xhr.addEventListener("load", successFn, false)
    xhr.addEventListener("error", errorFn, false)
    xhr.addEventListener("abort", errorFn, false)
  
    fd.append('data', param.file)
    xhr.open('POST', serverURL, true)
    xhr.send(fd)
  }

  render() {
    const { editValue } = this.state;
    const {
      title,
      value,
      style,
      error,
      rule,
      required,
      i18nMode,
      requiredErrorMessage,
      propName,
      labelCol,
      wrapperCol,
      textColor,
      editorStyle = {},
    } = this.props;

    return (
      <Form.Item
        label={title !== '' ?<div
          style={{
            color: textColor,
            fontSize: '18px',
            padding: required ? '0px' :'0px 16px',
            fontWeight: 'bold'
          }}>{title}</div>: null}
        name={propName}
        rules={[
          {
            required: required,
            message: requiredErrorMessage,
            ...rule,
          },
        ]}
        required={required}
        style={style}
        colon={false}
        labelCol={{ span: labelCol }}
        wrapperCol={{ span: wrapperCol }}
      >
        <BraftEditor
          value={editValue}
          id={"editor"}
          // id="editor-with-color-picker"
          language={i18nMode === 'zh' ? "zh-hant": 'en'}
          defaultValue={editValue}
          imageResizable={true}
          media={{
            image: true, // 开启图片插入功能
            video: true, // 开启视频插入功能
            audio: true, // 开启音频插入功能
            validateFn: null, // 指定本地校验函数，说明见下文
            uploadFn: this.uploadFn // 指定上传函数，说明见下文
          }}
        //   controls={[
        //     'undo', 'redo', 'separator',
        //     'font-size', 'separator',
        //     'text-color', 'bold', 'italic', 'underline', 'strike-through', 'table', 'separator',
        //     'separator', 'text-indent', 'text-align', 'separator',
        //     'headings', 'list-ul', 'list-ol', 'separator',
        //     'link', 'media', 'separator', 'hr' ,'separator',
        //     'clear'
        // ]}
          onChange={this.onChangeValue}
          contentStyle={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '10px',
            zIndex: '-1',
            backgroundColor: '#ffffff',
            minHeight: '200px',
            maxHeight: '800px',
            width: '100%',
            ...editorStyle,
          }}
          style={{
            height: '100%',
            padding: '10px',
            zIndex: '0',
            backgroundColor: '#ffffff',
            border: error ? `1px solid red` : `1px solid #7D9EB5`,
            minHeight: '200px',
            width: '100%',
            borderRadius: '15px',
            ...editorStyle,
          }}
        />
      </Form.Item>
    );
  }
}
export default HtmlEditor;