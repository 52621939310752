/* eslint-disable import/no-anonymous-default-export */
export default {
  avatar: require('../assets/avatar.png'),
  ability_dark: require('../assets/ability_white.png'),
  book_dark: require('../assets/book_white.png'),
  code_dark: require('../assets/code_white.png'),
  course_dark: require('../assets/course_white.png'),
  download_dark: require('../assets/download_white.png'),
  exam_dark: require('../assets/exam_white.png'),
  game_dark: require('../assets/game_white.png'),
  knowledge_dark: require('../assets/knowledge_white.png'),
  mail_dark: require('../assets/mail_white.png'),
  member_dark: require('../assets/member_white.png'),
  news_dark: require('../assets/news_white.png'),
  pay_dark: require('../assets/pay_white.png'),
  question_dark: require('../assets/question_white.png'),
  tag_dark: require('../assets/tag_white.png'),
  topic_dark: require('../assets/topic_white.png'),
  ability_light: require('../assets/ability_light.png'),
  book_light: require('../assets/book_light.png'),
  code_light: require('../assets/code_light.png'),
  course_light: require('../assets/course_light.png'),
  download_light: require('../assets/download_light.png'),
  exam_light: require('../assets/exam_light.png'),
  game_light: require('../assets/game_light.png'),
  knowledge_light: require('../assets/knowledge_light.png'),
  mail_light: require('../assets/mail_light.png'),
  member_light: require('../assets/member_light.png'),
  news_light: require('../assets/news_light.png'),
  pay_light: require('../assets/pay_light.png'),
  question_light: require('../assets/question_light.png'),
  tag_light: require('../assets/tag_light.png'),
  topic_light: require('../assets/topic_light.png'),
  ability_black: require('../assets/ability_black.png'),
  book_black: require('../assets/book_black.png'),
  code_black: require('../assets/code_black.png'),
  course_black: require('../assets/course_black.png'),
  download_black: require('../assets/download_black.png'),
  exam_black: require('../assets/exam_black.png'),
  game_black: require('../assets/game_black.png'),
  knowledge_black: require('../assets/knowledge_black.png'),
  mail_black: require('../assets/mail_black.png'),
  member_black: require('../assets/member_black.png'),
  news_black: require('../assets/news_black.png'),
  pay_black: require('../assets/pay_black.png'),
  question_black: require('../assets/question_black.png'),
  tag_black: require('../assets/tag_black.png'),
  topic_black: require('../assets/topic_black.png'),
  check: require('../assets/check.png'),
  light: require('../assets/light.png'),
  dark: require('../assets/dark.png'),
  login_background: require('../assets/login_background.png'),
  eye: require('../assets/eye.png'),
  eye_open: require('../assets/eye_open.png'),
  edit_green: require('../assets/edit_green.svg').default,
  trash: require('../assets/trash.svg').default,
  light_bg: require('../assets/light_bg.png'),
  dark_bg: require('../assets/dark_bg.png'),
  logo: require('../assets/logo.png'),
  dark_default: require('../assets/dark_default.png'),
  light_default: require('../assets/light_default.png'),
  auto_default: require('../assets/auto_default.png'),
  dark_selected: require('../assets/dark_selected.png'),
  light_selected: require('../assets/light_selected.png'),
  auto_selected: require('../assets/auto_selected.png'),
  bar: require('../assets/bar.png'),
  bar_dark: require('../assets/dark_bar.png'),
  line: require('../assets/line.png'),
  tag_bar: require('../assets/tag_bar.png'),
  search: require('../assets/search.png'),
  up: require('../assets/up.png'),
  down: require('../assets/down.png'),
  up_check: require('../assets/up_check.png'),
  down_check: require('../assets/down_check.png'),
  eye_green: require('../assets/eye_green.png'),
  edit: require('../assets/edit.png'),
  delete: require('../assets/delete.png'),
  drawer_bg: require('../assets/drawer_bg.png'),
  close: require('../assets/close.png'),
  close_white: require('../assets/close_white.png'),
  user_icon: require('../assets/user_icon.png'),
  address_icon: require('../assets/address_icon.png'),
  birth_icon: require('../assets/birth_icon.png'),
  email_icon: require('../assets/email_icon.png'),
  user_icon_dark: require('../assets/user_icon_dark.png'),
  address_icon_dark: require('../assets/address_icon_dark.png'),
  birth_icon_dark: require('../assets/birth_icon_dark.png'),
  email_icon_dark: require('../assets/email_icon_dark.png'),
  menu_group_light: require('../assets/menu_group.png'),
  menu_ungroup_light: require('../assets/menu_ungroup.png'),
  menu_group_dark: require('../assets/menu_group_dark.png'),
  menu_ungroup_dark: require('../assets/menu_ungroup_dark.png'),
  up_light: require('../assets/up_light.png'),
  down_light: require('../assets/down_light.png'),
  up_dark: require('../assets/up_dark.png'),
  down_dark: require('../assets/down_dark.png'),
  up_check_light: require('../assets/up_check_light.png'),
  down_check_light: require('../assets/down_check_light.png'),
  up_check_dark: require('../assets/up_check_dark.png'),
  down_check_dark: require('../assets/down_check_dark.png'),
  menu_child_light: require('../assets/menu_child_light.png'),
  menu_child_dark: require('../assets/menu_child_dark.png'),
  lock_closed: require('../assets/lock_closed.png'),
  copy: require('../assets/copy.png'),
  question: require('../assets/question.png'),
  sound: require('../assets/sound.png'),
  img: require('../assets/img.png'),
  check_icon: require('../assets/check_icon.png'),
  cancel_icon: require('../assets/cancel_icon.png'),
  tick: require('../assets/tick.png'),
  one: require('../assets/1.png'),
  two: require('../assets/2.png'),
  star: require('../assets/star.png'),
  exam_page: require('../assets/exam_page.png'),
  money: require('../assets/money.png'),
  book_icon: require('../assets/book_icon.png'),
  grid1: require('../assets/grid1.png'),
  grid2: require('../assets/grid2.png'),
  grid3: require('../assets/grid3.png'),
  media: require('../assets/media.png'),
  download_audio: require('../assets/audio.svg').default,
  download_doc: require('../assets/doc.svg').default,
  download_image: require('../assets/image.svg').default,
  download_movie: require('../assets/movie.svg').default,
  download_pdf: require('../assets/pdf.svg').default,
  download_ppt: require('../assets/ppt.svg').default,
  download_txt: require('../assets/txt.svg').default,
  download_unKnow: require('../assets/unKnow.svg').default,
  download_xls: require('../assets/xls.svg').default,
  download_zip: require('../assets/zip.svg').default,
  lottery_light: require('../assets/lottery01.png'),
  lottery_dark: require('../assets/lottery02.png'),
  statistics_light: require('../assets/char01.png'),
  statistics_dark: require('../assets/char02.png'),
  review_light: require('../assets/test01.png'),
  review_dark: require('../assets/test02.png'),
  delete_red: require('../assets/delete_red.png'),

};
