import React from 'react';
import { Form, DatePicker } from 'antd';
import './FormDatePicker.less';
import _ from "lodash";
import { connect } from "react-redux";

const FormDatePicker = (props) => {
  return (
    <Form.Item
      label={(props.title !== '' && props.title !== undefined) &&<div
        style={{
          color: props.textColor,
          fontSize: '18px',
          padding: props.required ? '0px' :'0px 16px',
          fontWeight: 'bold'
        }}>{props.title}</div>}
      name={props.name}
      colon={false}
      rules={[
        {
          required: props.required,
          message: (props.requiredMessage !== '' && props.requiredMessage !== undefined) ? props.requiredMessage :`${props.i18n.placeholder}${props.title}`,
        },
        // props.rules !== undefined ? [...props.rules]: [],
        // {
        //   type: props.type,
        //   message: props.i18n.typeError, 
        // }
      ]}
    >
      <DatePicker
        showTime={props.showTime}
        style={{
          borderRadius: '15px',
          height: '50px',
          color: '#125346',
          padding: '0px 16px',
          backgroundColor: '#FFF',
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
          width: '100%',
        }}
        placeholder={props.placeholder}
      />
    </Form.Item>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDatePicker);