import { all } from "redux-saga/effects";

const sagas = [
  "Example",
  "Global", 
  "UserSaga",
  "MemberSaga", 
  "TagSaga", 
  "SchoolSaga", 
  "KnowledgeSaga",
  "TopicGroupSaga",
  "TopicManagerSaga",
  "ExamSaga",
  "BookSaga",
  "CourseSaga",
  "TalkSaga",
  "LotterySaga",
  "StatisticsSaga",
  "ReviewSaga",
  "DownloadSaga",
  "NewsSaga"
];
const Saga = sagas.map((saga) => require(`./${saga}`).default());

export default function* rootSaga() {
  yield all(Saga);
}
