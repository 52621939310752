import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRoutes, Navigate } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Forget from "./pages/Forget";
import ChangePassword from "./pages/ChangePassword";

import Tag from "./pages/Tag";
import Ability from "./pages/Ability";
import Member from "./pages/Member";
import School from "./pages/School";
import Character from "./pages/Character";
import String from "./pages/String";
import Talk from "./pages/Talk";
import TalkUpdate from "./pages/TalkUpdate";
import Grammar from "./pages/Grammar";
import Article from "./pages/Article";
import TopicGroup from "./pages/TopicGroup";
import TopicManager from "./pages/TopicManager";
import Exam from "./pages/Exam";
import ExamCreate from "./pages/ExamCreate";
import ExamUpdate from "./pages/ExamUpdate";
import ExamTopic from "./pages/ExamTopic";
import Book from "./pages/Book";
import BookCreate from "./pages/BookCreate";
import BookUpdate from "./pages/BookUpdate";
import BookView from "./pages/BookView";
import BookChapter from "./pages/BookChapter";
import Course from "./pages/Course";
import CourseView from "./pages/CourseView";
import CourseCreate from "./pages/CourseCreate";
import CourseUpdate from "./pages/CourseUpdate";
import Lottery from "./pages/Lottery";
import LotteryLog from "./pages/LotteryLog";
import LotteryCreate from "./pages/LotteryCreate";
import LotteryUpdate from "./pages/LotteryUpdate";
import Download from "./pages/Download";
import Review from "./pages/Review";
import Statistics from "./pages/Statistics";
import News from "./pages/News";
import 'antd/dist/antd.css';

const App = (props) => {
  const caseSensitive = true;
  const [init, setInit] = useState(false);
  useEffect(() => {
    let temp = window.localStorage.getItem('DTS_THEME');
    if (temp === undefined || temp === null) {
      temp = 'dark';
      window.localStorage.setItem('DTS_THEME', 'dark');
    }
    props.setInitTheme(temp);
    let tempI18N = window.localStorage.getItem('DTS_I18N');
    if (temp === undefined || temp === null) {
      temp = 'en';
      window.localStorage.setItem('DTS_I18N', 'en');
    }

    let tempUser = window.localStorage.getItem("DTS_DASHBOARD_USER");
    if (tempUser !== null) {
      tempUser = JSON.parse(tempUser);
      props.setToken(tempUser.token);
      props.setUserData(tempUser.member);
      setInit(true);
    } else {
      setInit(true);
    }
    props.setInitI18N(tempI18N);
  });

  let temp = window.localStorage.getItem("DTS_DASHBOARD_LOGIN") === 'true' ? [
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Tag />, caseSensitive },
        { path: "/tag", element: <Tag />, caseSensitive },
        { path: "/ability", element: <Ability />, caseSensitive },
        { path: "/member/user", element: <Member />, caseSensitive },
        { path: "/member/school", element: <School />, caseSensitive },
        { path: "/knowledge/character", element: <Character />, caseSensitive },
        { path: "/knowledge/string", element: <String />, caseSensitive },
        { path: "/knowledge/grammar", element: <Grammar />, caseSensitive },
        { path: "/knowledge/article", element: <Article />, caseSensitive },
        { path: "/knowledge/talk", element: <Talk />, caseSensitive },
        { path: "/knowledge/talk/update/:id", element: <TalkUpdate />, caseSensitive },
        { path: "/topic/group", element: <TopicGroup />, caseSensitive },
        { path: "/topic/manager", element: <TopicManager />, caseSensitive },
        { path: "/exam", element: <Exam />, caseSensitive },
        { path: "/exam/create", element: <ExamCreate />, caseSensitive },
        { path: "/exam/update/:id", element: <ExamUpdate />, caseSensitive },
        { path: "/exam/topic/:id/:part_id/:ids", element: <ExamTopic />, caseSensitive },
        { path: "/book", element: <Book />, caseSensitive },
        { path: "/book/create", element: <BookCreate />, caseSensitive },
        { path: "/book/update/:id", element: <BookUpdate />, caseSensitive },
        { path: "/book/view/:id", element: <BookView />, caseSensitive },
        { path: "/book/chapter/:book_id/:chapter_id", element: <BookChapter />, caseSensitive },
        { path: "/course", element: <Course />, caseSensitive },
        { path: "/course/view/:id", element: <CourseView />, caseSensitive },
        { path: "/course/create", element: <CourseCreate />, caseSensitive },
        { path: "/course/update/:id", element: <CourseUpdate />, caseSensitive },
        { path: "/lottery", element: <Lottery />, caseSensitive },
        { path: "/lottery/log/:id", element: <LotteryLog />, caseSensitive },
        { path: "/lottery/create", element: <LotteryCreate />, caseSensitive },
        { path: "/lottery/update/:id", element: <LotteryUpdate />, caseSensitive },
        { path: "/review", element: <Review />, caseSensitive },
        { path: "/statistics", element: <Statistics />, caseSensitive },
        { path: "/news", element: <News />, caseSensitive },
        { path: "/download", element: <Download />, caseSensitive },
        { path: '*', element: <Navigate to="/tag" replace />}
      ],
    },
  ]: [
    {
      path: "/",
      element: <EmptyLayout />,
      children: [
        { path: "/", element: <Login />, caseSensitive },
        { path: "/login", element: <Login />, caseSensitive },
        { path: "/forget", element: <Forget />, caseSensitive },
        { path: "/change/password/:code", element: <ChangePassword />, caseSensitive: false },
        { path: '*', element: <Navigate to="/login" replace />}
      ],
    },
  ];
  let element = useRoutes(temp);
  

  return init ? element: null;
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInitTheme(payload) {
      dispatch({ type: 'setInitTheme', payload });
    },
    setInitI18N(payload) {
      dispatch({ type: 'setInitI18N', payload });
    },
    setToken(payload) {
      dispatch({ type: 'SET_TOKEN', payload });
    },
    setUserData(payload) {
      dispatch({ type: 'SET_USER_DATA', payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
