import _ from 'lodash';

const Reducer = (state = {
  schoolList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  schoolFormList: [],
  gradeList: [],
  permission: {
    front: [],
    dashboard: [],
  },
  roleList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_SCHOOL_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.school_id,
        });
      })
      return { ...state, schoolList: temp, paging: payload.paging };
    case "GET_SCHOOL_LIST_BY_FORM_SUCCESS":
      let tempSchool = [];
      payload.results.map((item) => {
        tempSchool.push({
          id: item.school_id,
          name: item.school_name,
        });
      });
      return { ...state, schoolFormList: tempSchool };
    case "GET_GRADE_BY_SCHOOL_ID_SUCCESS":
      let tempGrade = [];
      payload.results.map((item) => {
        tempGrade.push({
          id: item.grade_id,
          name: item.grade_name,
        });
      });
      return { ...state, gradeList: tempGrade };
    case "GET_ROLE_LIST_SUCCESS":
      let tempData = payload.filter((item)=> item.id !== "M001" && item.id !== "M002")
      return { ...state, roleList: tempData };
    case "GET_SCHOOL_PERMISSION_FORM_SUCCESS":
      let front = [], dashboard = [];
      
      if (_.isArray(payload.front)) {
        payload.front.map((item) => {
          let tempRoleId = [];
          item.rolePermissions.map((child) => {
            tempRoleId.push(child.role_id);
          })
          front.push({
            ...item,
            roleId: tempRoleId,
          });
        });
      }
      if (_.isArray(payload.dashboard)) {
        payload.dashboard.map((item) => {
          let tempRoleId = [];
          item.rolePermissions.map((child) => {
            tempRoleId.push(child.role_id);
          })
          dashboard.push({
            ...item,
            roleId: tempRoleId,
          });
        });
      }
      
      return { ...state, permission: { front, dashboard} };
    default:
      return state;
  }
};

export default Reducer;
