import request from "../utils/request";

export function CREATE_Member(token, payload) {
  return request.post("/member", payload, {
    // headers: {Authorization: token },
    headers: {
      ContentType: 'multipart/form-data',
      Authorization: token,
    },
  });
}

export function UPDATE_Member(token, payload) {
  return request.put("/member", payload, {
    // headers: {Authorization: token },
    headers: {
      ContentType: 'multipart/form-data',
      Authorization: token,
    },
  });
}

export function UPDATE_MemberAccount(token, payload) {
  return request.put("/member/account", payload, {
    headers: {Authorization: token },
  });
}

export function GET_Member(token, payload) {
  return request.post(`member/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_MemberForm(token, payload) {
  return request.get(`member/form?role=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_MemberById(token, payload) {
  return request.get(`member?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Member(token, payload) {
  return request.delete(`/member?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}