const Reducer = (state = {
  examList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  examFormList: [],
  examDetail: {
    tags: [],
    abilities: [],
    parts: [],
  },
  topicDetail: {
    part_topics: [],
    topics: [],
    checkList: [],
  }
}, { type, payload }) => {
  switch (type) {
    case "GET_EXAM_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.exam_id,
        });
      })
      return { ...state, examList: temp, paging: payload.paging };
    case "GET_EXAM_FORM_LIST_SUCCESS":
      return { ...state, examFormList: payload };
    case "GET_EXAM_TOPIC_SUCCESS":
      let tempList = [];
      payload.topics.map((item) => {
        tempList.push({
          ...item,
          key: item.topic_id,
        });
      })
      let checkList = [];
      payload.part_topics.map((item) => {
        checkList.push(item.topic_id);
      });
      return { ...state, topicDetail: {...payload, topics: tempList, checkList} };
    case "GET_EXAM_BY_ID_SUCCESS":
      let tempParts = [];
      let tempAllScore = 0;
      payload.parts.map((item) => {
        let count = 0;
        if (item.part_items.length === 0) {
          count = item.topic_count;
        } else {
          item.part_items.map((child) => {
            count += child.topic_count;
          });
        }
        tempAllScore += (count * item.topic_score);
        tempParts.push({
          ...item,
          part_total_score: count * item.topic_score,
          part_total_count: count,
        });
      })
      return { ...state, examDetail: {...payload, allScore: tempAllScore, parts: tempParts } };
    default:
      return state;
  }
};

export default Reducer;
