import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Upload } from 'antd';
import FormInput from "../components/common/FormInput";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDatePicker from "../components/common/FormDatePicker";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import Swal from 'sweetalert2';
import { PlusOutlined } from '@ant-design/icons';
import "./CourseCreate.less";
import _ from "lodash";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
let timer;
const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const CourseCreate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    chapters: [],
    is_sell: false,
    course_desc: '',
  });
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    props.getTagFormList(10);
    props.getExamFormList();
    props.getBookFormList();
    props.getTeacherFormList("M003");
    props.getStudentFormList("M004");
    props.getSchoolListByForm({}, setIsLoading);
  }, []);
  
  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      createForm.current.setFieldsValue({ img: temp });
      setImageData(temp);
    } else {
      createForm.current.setFieldsValue({ img: newFileList });
      setImageData(newFileList);
    }
  };
  const handleFileChange = ({ fileList: newFileList }) => {
      createForm.current.setFieldsValue({ files: newFileList });
      setFileData(newFileList);
  };
  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 10,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }
 
  const handleCancel = () => {
    Swal.fire({
      title: props.i18n.update_cancel,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        navigate('/course');
      }
    });
  }
  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleFinish = (value) => {
    let formData = new FormData();
    formData.append("course_title", formValue.course_title);
    formData.append("course_desc", _.isObject(formValue.course_desc) ? formValue.course_desc.toHTML(): '');
    formData.append("course_price", parseInt(formValue.course_price));
    if (formValue.course_discount !== undefined) {
      formData.append("course_discount", formValue.course_discount);
    } 
    formData.append("is_sell", formValue.is_sell);
    if (imageData.length > 0) {
      formData.append("imgs", imageData[0].originFileObj);
    }
    if (fileData.length > 0) {
      fileData.map((item, index) => {
        formData.append("files", item.originFileObj);
      });
    }
    if (_.isArray(formValue.schools)) {
      formValue.schools.map((item) => {
        formData.append('schools', item);
      });
    }
    if (formValue.course_effective !== undefined) {
      formData.append("course_effective", formValue.course_effective);
    } 
    formValue.tags.map((child) => {
      formData.append(`tags`, child);
    });
    formValue.abilities.map((child) => {
      formData.append(`abilities`, child);
    });
    formData.append('teacher_id', formValue.teacher_id);
    formValue.books.map((item) => {
      formData.append('books', item);
    });
    if (!_.isEmpty(formValue.course_start)) {
      formData.append("course_start", formValue.course_start.format('YYYY-MM-DD'));
    }
    if (!_.isEmpty(formValue.course_end)) {
      formData.append("course_end", formValue.course_end.format('YYYY-MM-DD'));
    }
    if (value.exams !== undefined) {
      value.exams.map((item, index) => {
        formData.append(`exams[${index}].exam_id`, item.exam_id);
        formData.append(`exams[${index}].course_exam_start`, item.course_exam_start.format('YYYY-MM-DD'));
        formData.append(`exams[${index}].course_exam_end`, item.course_exam_end.format('YYYY-MM-DD'));
        formData.append(`exams[${index}].is_practice`, item.is_practice);
      });
    }
    const callback = (value) => {
      navigate(`/course/update/${value.course_id}`);
    }
    props.handleCreateCourse(formData, setIsLoading, callback);
  }


  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px'}} className="scrollList">
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          exams: [],
        }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
    >
      <Row gutter={24} style={{ marginRight: '0px'}}>
        <Col span={24}>
          <FormInput
            title={props.i18n.course_title}
            name="course_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.course_title}`}
          />
        </Col>
        <Col span={24}>
          <HtmlEditor
            title={props.i18n.course_desc}
            i18nMode={props.i18nMode}
            propName="course_desc"
            // value={formValue.topic_title}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.origin_price}
            name="course_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.discount_price}
            name="course_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>
        <Col span={6}>
          <FormDatePicker
            title={props.i18n.course_start}
            name="course_start"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.course_start}`}
          />
        </Col>
        <Col span={6}>
          <FormDatePicker
            title={props.i18n.course_end}
            name="course_end"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.course_end}`}
          />
        </Col>
        <Col span={12}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="books"
            mode="multiple"
            type="array"
            required
            options={props.bookFormList}
            allowClear={true}
            title={props.i18n.course_book}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.course_book}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="teacher_id"
            options={props.teacherFormList}
            allowClear={true}
            required
            title={props.i18n.course_teacher}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.course_teacher}`}
          />
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.book_effective}
            name="course_effective"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_effective}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="schools"
            mode="multiple"
            type="array"
            options={props.schoolFormList}
            allowClear={true}
            title={props.i18n.lottery_act_school}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_act_school}`}
          />
        </Col>
        <Col span={6}>
          <Form.Item 
            valuePropName="img"
            name="img"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.course_imgs}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.course_imgs}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="picture-card"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              onChange={handleImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#292C33'}}>{props.i18n.upload}</div>
              </div>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={6} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>上架狀態</div>}
            name="is_sell"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginBottom: '12px',
              marginLeft: '24px'
            }}>測驗管理</div>
        </Col>
        <Col span={24}>
          <Form.List name="exams" >
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '0px 16px 0px 8px' }}>  
                    <div style={{ minWidth: '180px', marginRight: '16px'}}>                  
                      <FormDatePicker
                        title={props.i18n.course_exam_start}
                        {...restField}
                        required
                        name={[name, 'course_exam_start']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.course_exam_start}`}
                      />
                    </div>
                    <div style={{ minWidth: '180px', marginRight: '16px'}}>   
                      <FormDatePicker
                        title={props.i18n.course_exam_end}
                        required
                        {...restField}
                        name={[name, 'course_exam_end']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.course_exam_end}`}
                      />
                    </div>
                    <div style={{ minWidth: '300px', marginRight: '16px'}}> 
                      <FormInputSelect
                        name={[name, 'exam_id']}
                        required
                        {...restField}
                        options={props.examFormList}
                        allowClear={true}
                        title={props.i18n.course_exam}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.course_exam}`}
                      />
                    </div>
                    <div style={{ minWidth: '120px', marginRight: '16px'}}>   
                      <Form.Item
                        {...restField}
                        label={<div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: '18px',
                            fontWeight: 'bold'
                          }}>是否為練習</div>}
                        name={[name, 'is_practice']}
                        // valuePropName="checked"
                      >
                        <Switch></Switch>
                      </Form.Item>
                    </div>
                    <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center'}}>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '15px',
                          width: '40px',
                          height: '40px',
                          backgroundColor: '#D53838',
                          border: '1px solid #F31616',
                          marginLeft: '8px',
                        }}
                        onClick={() => remove(name)}
                      >
                        <img
                          src={Images.delete}
                          style={{ width: '22px', height: '14px'}}
                          alt={props.i18n.delete}
                        />
                      </Button>
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                    }}
                  >
                    + {props.i18n.create_btn}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item 
            valuePropName="files"
            name="files"
            rules={[
              {
                required: false,
                message: `${props.i18n.placeholder}${props.i18n.file_upload}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.file_upload}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              fileList={fileData}
              onChange={handleFileChange}
              multiple={true}
              onPreview={handlePreview}
              customRequest={dummyRequest}
            >
              <Button
                loading={isLoading}
                style={{
                  height: '50px',
                  backgroundColor: '#4A9E8F',
                  width: '128px',
                  borderRadius: '20px',
                  color: 'white',
                  border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                  marginRight: '30px'
                }}
              >
                {props.i18n.upload}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item style={{ width: '100%', textAlign: 'center'}}>
            <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
            >
              {props.i18n.create_btn}
            </Button>
            <Button
              loading={isLoading}
              onClick={handleCancel}
              style={{
                height: '50px',
                backgroundColor: '#D53838',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
              }}
            >
            {props.i18n.swal_no}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    formTagList: _.get(state, 'Tag.formTagList', []),
    bookFormList: _.get(state, 'Book.bookFormList', []),
    examFormList: _.get(state, 'Exam.examFormList', []),
    teacherFormList: _.get(state, 'Member.teacherFormList', []),
    studentFormList: _.get(state, 'Member.studentFormList', []),
    schoolFormList: _.get(state, 'School.schoolFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleCreateCourse(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_COURSE', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getExamFormList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_FORM_LIST', payload, loading, callback});
    },
    getBookFormList(payload, loading, callback) {
      dispatch({ type: 'GET_BOOK_FORM_LIST', payload, loading, callback});
    },
    getTeacherFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TEACHER_FORM_LIST', payload, loading, callback});
    },
    getStudentFormList(payload, loading, callback) {
      dispatch({ type: 'GET_STUDENT_FORM_LIST', payload, loading, callback});
    },
    getSchoolListByForm(payload, loading, callback) {
      dispatch({ type: 'GET_SCHOOL_LIST_BY_FORM', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate);
