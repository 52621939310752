import request from "../utils/request";

export function CREATE_Book(token, payload) {
  return request.post("/book", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_Book(token, payload) {
  return request.put("/book", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_BookSell(token, payload) {
  return request.put(`/book/sell?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function UPDATE_BookTopic(token, payload) {
  return request.put("/book/parttopic", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function SORT_Part(token, payload) {
  return request.put("/book/order", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_BookPartTopic(token, payload) {
  return request.post("/book/topic/form", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_Book(token, payload) {
  return request.post(`/book/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_BookForm(token, payload) {
  return request.get(`/book/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_BookById(token, payload) {
  return request.get(`/book?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Book(token, payload) {
  return request.delete(`/book?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Part(token, payload) {
  return request.delete(`/book/part?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function CREATE_Chapter(token, payload) {
  return request.put(`/book/chapter`, payload, {
    headers: {Authorization: token },
  });
}



export function DELETE_Chapter(token, payload) {
  return request.delete(`/book/chapter?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_BookByForm(token,) {
  return request.get(`/book/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_BookTypeList(token,) {
  return request.post(`/booktype/all`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_BookTypeByForm(token,) {
  return request.get(`/booktype/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_BookChapterById(token, payload) {
  return request.post(`/book/page/get`, payload, {
    headers: {Authorization: token },
  });
}

export function PUT_UpdateChapter(token, payload) {
  return request.put(`/book/page`, payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}