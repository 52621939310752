import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import DataTable from "../components/common/DataTable";


import "./Lottery.less";
import _ from "lodash";
import moment from "moment";

let timer;
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
]

const Lottery = (props) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [sorts, setSorts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let url = window.location.pathname.split('/lottery/log/')[1];
    let payload = {
      page: 1,
      act_id: url,
      start: null,
      end: null,
      pageCount: 10,
      orderKeys: sorts,
    };
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    props.getLotteryLog(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleSortChange = (e) => {
    setSorts(e);
    handleGetData();
  }

  const handlePageChange = (e) => {
    let url = window.location.pathname.split('/lottery/log/')[1];
    navigate(`/lottery/log/${url}?${e !== 1 ? `&page=${e}`: ''}`)
  }
 
  const columns = [
    {
      title: props.i18n.lottery_log_name,
      dataIndex: 'member_name',
      key: 'member_name',
      render: (value, record) => <div>{record.member_name}</div>
    },
    {
      title: props.i18n.lottery_log_award_name,
      dataIndex: 'award_name',
      key: 'award_name',
      render: (value, record) => <div>{record.award_name}{record.award_collect_name !== null && `(${record.award_collect_name})`}</div>
    },
    {
      title: props.i18n.lottery_log_isExchange,
      dataIndex: 'is_have',
      key: 'is_have',
      sort: true,
      render: (value, record) => <div>{value && '可兌換'}</div>
    },
  ];
  return <div id="lottery">
    <div style={{ width: '100%', textAlign: 'right'}}>
      <Button
        onClick={() => props.handleExportLotteryLog({start: null, end: null}, setIsLoading)}
        style={{
          width: '128px',
          height: '50px',
          textAlign: 'center',
          color: 'white',
          backgroundColor: '#4A9E8F',
          border: `2px solid ${props.color.createBtnBorder}`,
          borderRadius: '15px',
          fontSize: '18px'
        }}
        >
          {props.i18n.export_btn}
      </Button>
    </div>
    <DataTable
      column={columns}
      data={props.lotteryLogList}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={false}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      showSearch={false}
      searchValue={search}
      hasPagination={true}
      showChangePageSize={false}
      paging={props.logPaging}
      isLoading={isLoading}
      hasFrontCustomChildren={false}
      scrollHeight={props.screenHeight - 350}
      handleSortChange={handleSortChange}
      handlePageChange={handlePageChange}
    />
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    lotteryLogList: _.get(state, 'Lottery.lotteryLogList', []),
    logPaging: _.get(state, 'Lottery.logPaging', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLotteryLog(payload, loading, callback) {
      dispatch({ type: 'GET_LOTTERY_LOG', payload, loading, callback});
    },
    handleExportLotteryLog(payload, loading, callback) {
      dispatch({ type: 'HANDLE_EXPORT_LOTTERY_LOG', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lottery);
