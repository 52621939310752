import request from "../utils/request";

export function GET_NewsList(token, payload) {
  return request.post("/news/all", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_NewsById(token, payload) {
  return request.get(`/news?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function POST_CreateNews(token, payload) {
  return request.post(`/news`, payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_UpdateNews(token, payload) {
  return request.put(`/news`, payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function DELETE_DeleteNews(token, payload) {
  return request.delete(`/news?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_OpenNews(token, payload) {
  return request.put(`/news/open?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function PUT_TopNews(token, payload) {
  return request.put(`/news/top?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}