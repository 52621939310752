const Reducer = (state = {
  paging: {
    page: 1,
    pageCount: 12,
  },
  authList: [],
  examList: [],
  learnList: [],
  talkList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_USE_LOG_LIST_SUCCESS":
      let temp = JSON.parse(JSON.stringify(state));
      if (payload.type === 0) {
        temp.authList = payload.auth;
      } else if (payload.type === 7) {
        temp.examList = payload.exam;
      } else if (payload.type === 8 || payload.type === 10) {
        temp.learnList = payload.learn;
      } 
      return { ...temp, paging: payload.paging };
    default:
      return state;
  }
};

export default Reducer;
