import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Drawer } from 'antd';
import FormSelect from "../components/common/Select";
import Images from "../themes/Images";
import DataTable from "../components/common/DataTable";
import "./ExamUpdate.less";
import _ from "lodash";
import Search from "../components/common/Search";
import Topic from "../components/common/Topic";

let timer;
const ExamUpdate = (props) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentPart, setCurrentPart] = useState({});
  const [currentType, setCurrentType] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [current, setCurrent] = useState({});
  const [checkList, setCheckList] = useState([]);
  const [exam_id, setExamId] = useState('');
  const [currentTab, setCurrentTab] = useState('check');
  const [topicType, setTopicType] = useState(1);
  const [topicList, setTopicList] = useState([]);
  const [beforeList, setBeforeList] = useState([]); // 之前已選擇
  const [pagePaging, setPagePaging] = useState({
    page: 1,
    pageCount: 10,
    total: 0,
  });

  const getTopicById = (id) => {
    const callback = (value) => {
      setCurrentData(value);
      setTimeout(() => {
        setDrawerVisible(true);
      }, 100);
    }
    props.getTopicById(id, setIsLoading, callback);
  }
  

  useEffect(() => {
    props.getTopicTypeFormList();
    let url = window.location.pathname.split('/exam/topic/')[1];
    let temp = url.split('/');
    const callback = (value) => {
      let currentPayload = {};
      const examCallback = (res) => {
        let temp = [];
        let tempCheck = [];
        let tempBefore = [];
        res.part_topics.map((item) => {
          tempCheck.push(item.topic_id);
          tempBefore.push({ ...item, check: true, key: item.topic_id  });
        });
        setPagePaging(res.paging);
        setBeforeList(tempBefore);
        res.topics.map((item) => {
          temp.push({...item, check: false, key: item.topic_id })
        });
        temp = temp.sort((a, b) => b.check - a.check);
        setTopicList(temp);
        setCheckList(tempCheck);
      }
      value.parts.map((item) => {
        if (item.part_id === temp[1]) {
          setCurrentPart(item);
          if (item.is_choose) {
            let tempTags = [];
            let tempAbility = [];
            item.tags.map((tagItem) => {
              tempTags.push(tagItem.tag_id);
            });
            item.abilities.map((abilityItem) => {
              tempAbility.push(abilityItem.ability_id);
            });
            currentPayload = {
              id: temp[1],
              type: 1,
              tags: tempTags,
              is_choose: true,
              abilities: tempAbility,
              page: 1,
              pageCount: 10,
              search: '',
            };
            setCurrent(currentPayload);
            props.getExamPartTopic(currentPayload, setIsLoading, examCallback);

          } else {
            item.part_items.map((child) => {
              if (child.part_item_type === parseInt(temp[2])) {
                setCurrentType(child);
                let tempTags = [];
                let tempAbility = [];
                item.tags.map((tagItem) => {
                  tempTags.push(tagItem.tag_id);
                });
                item.abilities.map((abilityItem) => {
                  tempAbility.push(abilityItem.ability_id);
                });

                currentPayload = {
                  id: temp[1],
                  type: parseInt(temp[2]),
                  tags: tempTags,
                  is_choose: false,
                  abilities: tempAbility,
                  page: 1,
                  pageCount: 10,
                  search: null,
                };
                setCurrent(currentPayload);
                props.getExamPartTopic(currentPayload, setIsLoading, examCallback);
              }
            });
          }
        }
      })
    }
    setExamId(temp[0]);
    props.getTopicTypeList();
    props.getExamById(temp[0], setIsLoading, callback);
  }, []);

  const columns = [
    {
      title: props.i18n.topic,
      dataIndex: 'tag_name',
      key: 'tag_name',
      render: (value, record) => <div style={{ height: '80px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        <div style={{ fontSize: '16px', color: props.color.inputTitleColor, fontWeight: 'bold' }}>NO.{record.topic_id}</div>

        {(record.topic_title !== null && record.topic_title !== undefined) && <div dangerouslySetInnerHTML={{__html: record.topic_title }} style={{ marginLeft: '30px', fontSize: '18px', color: props.color.inputTitleColor, height: '80px'}}></div>}
        {record.imgs.length > 0 && <img src={record.imgs[0]} alt="img" style={{ maxHeight: '64px', marginLeft: '30px' }} />}
        {record.audio.length > 0 && <img
          alt="audio"
          src={Images.sound}
          style={{ width: '24px', height: '23px', marginLeft: '16px', cursor: 'pointer' }}
          onClick={() => window.open(record.audio[0])}
        />}
      </div>
    },
    {
      title: props.i18n.topic_count,
      dataIndex: 'items',
      key: 'items',
      width: '120px',
      align: 'center',
    },
    {
      title: props.i18n.topic_view,
      dataIndex: 'items',
      key: 'items',
      width: '80px',
      align: 'center',
      render: (value, record) => <div style={{ width: '80px', display: 'flex', justifyContent: 'center'}}>
        <Button
          style={{
            width: '40px',
            height: '40px',
            backgroundColor: '#42C1B4',
            border: `1px solid #C1EFD6`,
            borderRadius: '15px',
            marginLeft: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => getTopicById(record.topic_id)}
        >
          <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
        </Button>
      </div>
    },
  ];

  const handleUpdateExamTopic = () => {
    let check = true;
    if (check) {
      let payload = {
        part_id: currentPart.part_id,
        part_item_id: currentType.part_item_id,
        topics: checkList,
      };
      const callback = () => {
        navigate(`/exam/update/${exam_id}`)
      }
      props.handleUpdateExamTopic(payload, setIsLoading, callback);
    }
  }

  const handleSearch = (e) => {
    setSearch(e);
    const getSearch = () => {
      let tempList = props.topicDetail.topics.filter((item) => item.topic_title.toLowerCase().indexOf(e.toLowerCase()) > -1);
      let temp = [];
      
      tempList.map((item) => {
        if (checkList.filter((child) => child.topic_id === item.topic_id).length > 0) {
          temp.push({ ...item, check: true, key: item.topic_id });
        } else {
          temp.push({...item, check: false, key: item.topic_id });
        }
      });
      temp = temp.sort((a, b) => b.topic_id - a.topic_id);
      temp = temp.sort((a, b) => b.check - a.check);
      setTopicList(temp);;
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 300);
  }

  const handleChangeTopicType = (e) => {
    setTopicType(e);
    let temp = JSON.parse(JSON.stringify(current));
    temp.type = e;
    setCurrent(temp);
                
    const examCallback = (res) => {
      let temp = [];
      let tempCheck = [];
      res.topics.map((item) => {
        if (res.part_topics.filter((child) => child.topic_id === item.topic_id).length > 0) {
          tempCheck.push(item.topic_id);
          temp.push({ ...item, check: true, key: item.topic_id });
        } else {
          temp.push({...item, check: false, key: item.topic_id });
        }
      });
      temp = temp.sort((a, b) => a.topic_id - b.topic_id);
      temp = temp.sort((a, b) => b.check - a.check);
      setTopicList(temp);
      setCheckList(tempCheck);
    }
    props.getExamPartTopic(temp, setIsLoading, examCallback);

  }

  const handlePageChange = (page) => {
    const examCallback = (res) => {
      let temp = [];
      setPagePaging(res.paging);
      res.topics.map((item) => {
        temp.push({...item, check: checkList.indexOf(item.topic_id)> -1, key: item.topic_id })
      });
      temp = temp.sort((a, b) => b.check - a.check);
      setTopicList(temp);
    }
    props.getExamPartTopic({
      ...current,
      page: page,
    }, setIsLoading, examCallback);

    
  }

  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px' }} className="scrollList">
    <div style={{ fontSize: '18px', color: props.color.textColor}}>
      {props.examDetail.exam_title}({currentType.part_item_title})
    </div>
    <div style={{ fontSize: '18px', color: props.color.textColor, fontWeight: 'bold' }}>
      {currentPart.part_title}
      <span style={{ fontSize: '16px', color: '#106BD2', marginLeft: '16px' }}>每題{currentPart.topic_score}分</span>
      <span style={{ marginLeft: '16px'}}>編輯({props.topicDetail.topics.length})</span>
      <span style={{ marginLeft: '30px'}}>已選題目({checkList.length})</span>
      <span style={{ marginLeft: '30px',color: checkList.length !== currentType.topic_total ? 'red': '#106BD2'}}>題庫數{checkList.length}{_.isEmpty(currentType) ?'': '/'}{currentType.topic_total}</span>
    </div>
    <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center'}}>
      <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
        {
          props.examDetail.tags.map((child, index) => {
            return index < 3 && <div 
              style={{ 
                fontSize: '16px', 
                padding: '0px 12px', 
                backgroundColor: '#FCF1DF', 
                marginRight: '12px',
                color: '#242933',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '15px',
              }}
              >
              {child.tag_name}
              </div>
          })
        }
        {props.examDetail.tags.length > 3 && <div style={{
          width: '36px',
          height: '40px',
          lineHeight: '32px',
          borderRadius: '15px',
          backgroundColor: '#FCF1DF', 
          color: '#242933',
          textAlign: 'center',
          marginRight: '16px',
        }}>
          ...
        </div>}
      </div>
      {(props.examDetail.tags.length > 0 && props.examDetail.abilities.length > 0) &&<span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
        {
          props.examDetail.abilities.map((child, index) => {
            return <div 
              style={{ 
                fontSize: '16px', 
                padding: '0px 12px', 
                backgroundColor: '#FCD9BF', 
                marginRight: '12px',
                color: '#242933',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '15px',
              }}
              >
              {child.ability_name}
              </div>
          })
        }
      </div>
    </div>
    <div style={{ display: 'flex'}}>
      <div
        style={{
          width: '100px',
          height: '32px',
          lineHeight: '32px',
          borderTop: '1px solid #4a9e8f',
          borderRight: '1px solid #4a9e8f',
          borderLeft: '1px solid #4a9e8f',
          textAlign: 'center',
          borderBottom: '0px',
          marginRight: '12px',
          backgroundColor: currentTab === 'check' ? '#4a9e8f' : '#c2ebe8',
          color: currentTab === 'check' ? 'white' : 'black',
          cursor: 'pointer',
        }}
        onClick={() => setCurrentTab('check')}
      >
        已設定({})
      </div>
      <div
        style={{
          width: '100px',
          height: '32px',
          lineHeight: '32px',
          borderTop: '1px solid #4a9e8f',
          borderRight: '1px solid #4a9e8f',
          borderLeft: '1px solid #4a9e8f',
          textAlign: 'center',
          borderBottom: '0px',
          marginRight: '12px',
          backgroundColor: currentTab === 'choose' ? '#4a9e8f' :'#c2ebe8',
          color: currentTab === 'choose' ? 'white': 'black',
          cursor: 'pointer',
        }}
        onClick={() => setCurrentTab('choose')}
      >
        待選擇
      </div>
    </div>
    <div style={{ width: '100%', height: '1px', backgroundColor: '#26695C', marginBottom: '16px' }}></div>
    {currentTab === 'choose' && <div style={{ display: 'flex', fontSize: '18px',marginTop: '16px', alignItems: 'center' }}>

      <Search searchValue={search} handleSearch={handleSearch} />
      {currentPart.is_choose && <FormSelect
        showSearch
        style={{ marginLeft: '24px', width: '254px'}}
        placeholder={`${props.i18n.filter}${props.i18n.topic_manager_topic_type}`}
        options={props.topicTypeFormList}
        value={topicType}
        onChange={(e) => handleChangeTopicType(e)}
      />}
    </div>}
    {currentTab ==='check' && <DataTable
      column={columns}
      data={beforeList}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={true}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      openModal={() => { }}
      checkList={checkList}
      showSearch={false}
      searchValue={search}
      hasPagination={false}
      // paging={props.paging}
      isLoading={isLoading}
      hasFrontCustomChildren={true}
      scrollHeight={props.screenHeight -500}
      tableLineHeight={100}
      handleCheckChange={(e) => setCheckList(e)}
    />}
    {currentTab ==='choose' && <DataTable
      column={columns}
      data={topicList}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={true}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      openModal={() => { }}
      checkList={checkList}
      showSearch={false}
      searchValue={search}
      hasPagination={true}
      paging={pagePaging}
      isLoading={isLoading}
      hasFrontCustomChildren={true}
      scrollHeight={props.screenHeight -500}
      tableLineHeight={100}
      showChangePageSize={false}
      handleCheckChange={(e) => setCheckList(e)}
      // handleSearch={handleSearch}
      // handleSizeChange={handleSizeChange}
      handlePageChange={handlePageChange}
      // handleSortChange={handleSortChange}
      // handlePatchDelete={handlePatchDelete}
    />}
    <div style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
      <Button
        loading={isLoading}
        htmlType="submit"
        style={{
          height: '50px',
          backgroundColor: '#4A9E8F',
          width: '128px',
          borderRadius: '20px',
          color: 'white',
          border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
          marginRight: '30px'
        }}
        onClick={handleUpdateExamTopic}
      >
        確定
      </Button>
      <Button
        loading={isLoading}
        onClick={() => navigate(`/exam/update/${exam_id}`)}
        style={{
          height: '50px',
          backgroundColor: '#D53838',
          width: '128px',
          borderRadius: '20px',
          color: 'white',
          border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
        }}
      >
        取消
      </Button>
    </div>
    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.topic_view}</div>
      </div>
      <Topic
        key={currentData.topic_id}
        data={currentData}
        color={props.color}
        i18n={props.i18n}
      />
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    examList: _.get(state, 'Exam.examList', []),
    paging: _.get(state, 'Exam.paging', {}),
    topicDetail: _.get(state, 'Exam.topicDetail', {}),
    examDetail: _.get(state, 'Exam.examDetail', {}),
    examTopic: _.get(state, 'Exam.examTopic', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
    formTopicTypeList: _.get(state, 'TopicManager.topicTypeFormList', []),
    topicTypeFormList: _.get(state, 'TopicManager.topicTypeFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_LIST', payload, loading, callback});
    },
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    getExamById(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleUpdateExam(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_EXAM', payload, loading, callback});
    },
    handleUpdateExamTopic(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_EXAM_TOPIC', payload, loading, callback});
    },
    getExamPartTopic(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_PART_TOPIC', payload, loading, callback});
    },
    handleDeletePart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    handleSortPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_SORT_PART', payload, loading, callback});
    },
    getTopicTypeList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_LIST', payload, loading, callback});
    },
    getTopicById(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_BY_ID', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamUpdate);
