const Reducer = (state = {
  knowledgeList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  knowledgeFormList: [],
  gradeList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_KNOWLEDGE_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.knowledge_id,
        });
      })
      return { ...state, knowledgeList: temp, paging: payload.paging };
    case "GET_KNOWLEDGE_LIST_BY_FORM_SUCCESS":
      let tempKnowledge = [];
      payload.results.map((item) => {
        tempKnowledge.push({
          id: item.knowledge_id,
          name: item.knowledge_name,
        });
      });
      return { ...state, knowledgeFormList: tempKnowledge };
    default:
      return state;
  }
};

export default Reducer;
