const Reducer = (state = {
  userData: {},
  token: '',
  permissionList: [],
  schoolPermission: [],
  roleList: [],
  rolePermission: [],
}, { type, payload }) => {
  switch (type) {
    case "SET_USER_DATA":
      return { ...state,  userData: payload };
    case "SET_TOKEN":
      return { ...state, token: payload };
    case "GET_ALL_PERMISSION_SUCCESS":
      return { ...state, permissionList: payload };
    case "GET_SCHOOL_PERMISSION_SUCCESS":
      return { ...state, schoolPermission: payload };
    case "GET_ROLE_LIST_SUCCESS":
      return { ...state, roleList: payload };
    case "GET_ROLE_BY_PERMISSION_SUCCESS":
      return { ...state, rolePermission: payload.dashboard };
    default:
      return state;
  }
};

export default Reducer;
