import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Drawer,
  Row,
  Col,
  Form,
  Pagination,
  Select,
  Table,
  Switch,
  Upload,
} from "antd";
import Search from "../components/common/Search";
import FormInput from "../components/common/FormInput";
import FormTextarea from "../components/common/FormTextarea";
import FormInputSelect from "../components/common/FormInputSelect";
import Images from "../themes/Images";
import { city, area } from "../utils/location";
import Swal from "sweetalert2";

import "./School.less";
import _ from "lodash";

let timer;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const normFile = (e) => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
];

const School = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [currentPermission, setCurrentPermission] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [mode, setMode] = useState("create");
  const [search, setSearch] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [drawerTabKey, setDrawerTabKey] = useState("detail");
  const [currentData, setCurrentData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    let temp = [];
    city.map((item) => {
      temp.push({
        id: item,
        name: item,
      });
    });
    setCityList(temp);
    handleGetData();
    props.getAllPermission();
    props.getRoleList();
  }, []);

  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: "",
      page: 1,
      pageCount: 12,
      orderKeys: [],
    };
    if (param.get("search") !== null) {
      payload.search = param.get("search");
    }
    if (param.get("page") !== null) {
      payload.page = param.get("page");
    }
    if (param.get("pageCount") !== null) {
      payload.pageCount = param.get("pageCount");
    }
    props.getSchoolList(payload, setIsLoading);
  };

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleShowUpdate = (data) => {
    const { getSchoolById } = props;
    const callback = (value) => {
      let tempPermission = [];
      if (_.isArray(value.permissions)) {
        value.permissions.map((item) => {
          tempPermission.push(item.id);
        });
        setCurrentPermission(tempPermission);
      }
      setMode("edit");
      setCurrentData(value);
      setFormValue(value);
      setDrawerVisible(true);
    };
    getSchoolById(data.school_id, setIsLoading, callback);
  };

  const handleCreate = (value) => {
    const { handleCreateSchool, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setDrawerTabKey("detail");
    };
    let temp = [];
    if (_.has(value, "grades") && _.isArray(value.grades)) {
      value.grades.map((item) => {
        temp.push(item.grade_name);
      });
    }

    let getData = {
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    };

    handleCreateSchool(
      {
        ...formValue,
        grades: temp,
      },
      setIsLoading,
      callback,
      getData
    );
  };
  const handleUpdate = (value) => {
    const { handleUpdateSchool, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
    };

    let getData = {
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    };
    let addList = [];
    let removeList = [];
    let gradeList = [];

    formValue.grades.map((item) => {
      if (_.has(item, "grade_id")) {
        gradeList.push(item);
      } else {
        if (item.grade_name !== undefined) {
          addList.push(item.grade_name);
        }
      }
    });

    currentData.grades.map((item) => {
      if (
        formValue.grades.filter((child) => child.grade_id === item.grade_id)
          .length === 0
      ) {
        removeList.push(item.grade_id);
      }
    });

    handleUpdateSchool(
      {
        ...formValue,
        school_id: currentData.school_id,
        grades: gradeList,
        removes: removeList,
        list: addList,
      },
      setIsLoading,
      callback,
      getData
    );
  };
  const handleFinish = (value) => {
    if (mode === "create") {
      handleCreate(value);
    } else {
      handleUpdate(value);
    }
  };

  const handleFormChange = (change, all) => {
    if (_.has(change, "school_city")) {
      area.map((item) => {
        if (item.key === change.school_city) {
          let temp = [];
          item.list.map((child) => {
            temp.push({ id: child, name: child });
          });
          createForm.current.setFieldsValue({ school_area: undefined });
          setAreaList(temp);
        }
      });
    }
    setFormValue({
      ...formValue,
      ...all,
    });
  };

  const renderDrawerView = () => {
    if (mode === "view") {
      return drawerVisible && renderViewDrawer();
    } else if (mode === "permission") {
      return drawerVisible && renderPermissionDrawer();
    } else if (mode === "import") {
      return drawerVisible && renderImportDrawer();
    } else {
      return drawerVisible && renderFormDrawer();
    }
  };

  const handleImportMember = (value) => {
    if (_.isArray(value.file) && value.file.length > 0) {
      let formData = new FormData();
      formData.append("school_id", schoolId);
      formData.append("file", value.file[0].originFileObj);
      const callback = () => {
        setDrawerVisible(false);
      };
      props.handleImportSchoolMember(formData, props.saveLoading, callback);
    }
  };

  const renderImportDrawer = () => {
    return (
      <Form
        {...layout}
        name="basic"
        initialValues={{
          name: "",
        }}
        style={{ marginTop: "16px" }}
        onFinish={handleImportMember}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="file" getValueFromEvent={normFile}>
              <Upload accept=".xlsx" maxCount={1} customRequest={dummyRequest}>
                <Button
                  loading={isLoading}
                  style={{
                    height: "40px",
                    backgroundColor: "#4A9E8F",
                    width: "128px",
                    borderRadius: "20px",
                    color: "white",
                    border:
                      props.color.currentTheme === "dark"
                        ? "none"
                        : "2px solid #C7E3D4",
                    marginRight: "30px",
                  }}
                >
                  {props.i18n.download_create_title}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "30px", width: "100%", textAlign: "center" }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderViewDrawer = () => {
    return (
      <div style={{ paddingTop: "30px" }}>
        <span
          style={{
            color: props.color.inputTitleColor,
            fontSize: "18px",
            marginLeft: "16px",
            borderBottom: "2px solid #13D1AC",
          }}
        >
          {currentData.school_name}
        </span>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
            marginTop: "16px",
          }}
        >
          <img
            alt="user"
            src={
              props.color.currentTheme === "dark"
                ? Images.address_icon_dark
                : Images.address_icon
            }
            style={{ width: "13px", height: "17px", marginRight: "8px" }}
          />
          <div
            title={
              currentData.school_city +
              currentData.school_area +
              currentData.school_address
            }
            style={{
              fontSize: "16px",
              color: props.color.textColor,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {currentData.school_city +
              currentData.school_area +
              currentData.school_address}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor:
              props.color.currentTheme === "dark" ? "#002C51" : "#C2EBE8",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            height: "70px",
            padding: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              color: props.color.textColor,
              width: "100%",
            }}
          >
            {props.i18n.school_grade}
          </div>
        </div>
        <div
          className="scrollList"
          style={{
            height: `${props.screenHeight - 301}px`,
            paddingBottom: "8px",
          }}
        >
          {currentData.grades.map((item) => {
            return (
              <div
                style={{
                  backgroundColor: "rgba(204,255,225,0.23)",
                  height: "64px",
                  width: "100%",
                  padding: "0px 24px",
                  lineHeight: "64px",
                  borderBottom: "1px solid #707070",
                  color: props.color.textColor,
                }}
              >
                {item.grade_name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const handleChangeSwitchPermission = (checked, data) => {
    let payload = {
      school_id: schoolId,
      permission_id: data.permission_id,
      active: checked,
      roles: data.roleId,
    }
    props.handleChangePermission(payload, schoolId,);
  }


  const handleChangeSelectPermission = (data, item) => {
    let payload = {
      school_id: schoolId,
      permission_id: item.permission_id,
      active: item.active,
      roles: data,
    }
    props.handleChangePermission(payload, schoolId,);
  }

  const handleChangeSwitch = (checked, data) => {
    let tempPermission = JSON.parse(JSON.stringify(currentPermission));

    if (checked) {
      if (tempPermission.indexOf(data.id) < 0) {
        tempPermission.push(data.id);
        setCurrentPermission(tempPermission);
      }
    } else {
      tempPermission = tempPermission.filter((item) => item !== data.id);
      setCurrentPermission(tempPermission);
    }
  };

  const handleSubmitPermission = () => {
    const callback = () => {
      setFormValue({});
      setCurrentPermission([]);
      setDrawerVisible(false);
    };
    props.handleUpdateSchool(
      {
        school_id: formValue.school_id,
        permissions: currentPermission,
      },
      props.saveLoading(),
      callback
    );
  };

  const renderPermissionDrawer = () => {
    return (
      <div style={{ paddingTop: "30px" }}>
        <div
          style={{
            fontSize: "20px",
            color: props.color.inputDrawerTitleColor,
          }}
        >
          前台
        </div>
        {
          props.permission.front.map((item) => {
            return <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <div style={{ width: '180px', color: props.color.textColor}}>
                {item.name}
              </div>
              <div style={{ marginLeft: '8px'}}>
                <Switch
                  checked={item.active}
                  checkedChildren="開啟"
                  unCheckedChildren="關閉"
                  onChange={(checked) => handleChangeSwitchPermission(checked, item)}
                ></Switch>
              </div>
              <div style={{ marginLeft: '8px'}}>
                <Select
                  placeholder="請選擇角色"
                  mode="multiple"
                  style={{ width: '260px'}}
                  maxTagCount="responsive"
                  className="multipleFormSelectList"
                  value={item.roleId}
                  onChange={(data) => handleChangeSelectPermission(data, item)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    props.roleList.map((child) => {
                      return <Select.Option value={child.id}>{child.name}</Select.Option>
                    })
                  }
                </Select>
              </div>
            </div>
          })
        }
        <div
          style={{
            fontSize: "20px",
            color: props.color.inputDrawerTitleColor,
          }}
        >
          後台
        </div>
        {
          props.permission.dashboard.map((item) => {
            return <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <div style={{ width: '180px', color: props.color.textColor}}>
                {item.name}
              </div>
              <div style={{ marginLeft: '8px'}}>
                <Switch
                  checked={item.active}
                  checkedChildren="開啟"
                  unCheckedChildren="關閉"
                  onChange={(checked) => handleChangeSwitchPermission(checked, item)}
                ></Switch>
              </div>
              <div style={{ marginLeft: '8px'}}>
                <Select
                  placeholder="請選擇角色"
                  mode="multiple"
                  style={{ width: '260px'}}
                  maxTagCount="responsive"
                  className="multipleFormSelectList"
                  value={item.roleId}
                  onChange={(data) => handleChangeSelectPermission(data, item)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    props.roleList.map((child) => {
                      return <Select.Option value={child.id}>{child.name}</Select.Option>
                    })
                  }
                </Select>
              </div>
            </div>
          })
        }
      </div>
    );
  };

  const renderFormDrawer = () => {
    const column = [
      {
        title: "權限內容",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "狀態",
        dataIndex: "active",
        key: "active",
        render: (value, record) => (
          <Switch
            checked={currentPermission.indexOf(record.id) > -1}
            checkedChildren="開啟"
            unCheckedChildren="關閉"
            onChange={(checked) => handleChangeSwitch(checked, record)}
          ></Switch>
        ),
      },
    ];
    return (
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        style={{ marginTop: "16px" }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
      >
        <Row gutter={24}>
          <Col span={24} style={{ marginBottom: "30px", display: "flex" }}>
            <div
              style={{
                padding: "0px 24px",
                height: "50px",
                lineHeight: "50px",
                border: `2px solid ${props.color.drawerTabBorder}`,
                borderRadius: "20px",
                marginRight: "24px",
                fontSize: "18px",
                color: props.color.drawerTabText,
                backgroundColor:
                  drawerTabKey === "detail"
                    ? props.color.drawerTabBackground
                    : "transparent",
                cursor: "pointer",
              }}
              onClick={() => setDrawerTabKey("detail")}
            >
              {props.i18n.school_detail}
            </div>
            <div
              style={{
                padding: "0px 24px",
                height: "50px",
                lineHeight: "50px",
                border: `2px solid ${props.color.drawerTabBorder}`,
                borderRadius: "20px",
                marginRight: "24px",
                fontSize: "18px",
                color: props.color.drawerTabText,
                backgroundColor:
                  drawerTabKey === "grade"
                    ? props.color.drawerTabBackground
                    : "transparent",
                cursor: "pointer",
              }}
              onClick={() => setDrawerTabKey("grade")}
            >
              {props.i18n.school_grade}
            </div>
          </Col>
          {drawerTabKey === "detail" && (
            <Col span={24}>
              <FormInput
                name="school_name"
                title={props.i18n.school_name}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.school_name}`}
              />
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col span={24}>
              <FormInput
                name="school_en_name"
                title={props.i18n.school_en_name}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.school_en_name}`}
              />
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col
              span={24}
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
                marginBottom: "12px",
              }}
            >
              <div style={{ marginLeft: "16px" }}>
                {props.i18n.member_addr_title}
              </div>
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col span={12}>
              <FormInputSelect
                name="school_city"
                options={cityList}
                required
                requiredErrorMessage={`${props.i18n.placeholder}${props.i18n.member_city}`}
                title={props.i18n.member_city}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.member_city}`}
              />
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col span={12}>
              <FormInputSelect
                name="school_area"
                options={areaList}
                required
                requiredErrorMessage={`${props.i18n.placeholder}${props.i18n.member_area}`}
                title={props.i18n.member_area}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.member_area}`}
              />
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col span={24}>
              <FormInput
                name="school_address"
                required
                requiredErrorMessage={`${props.i18n.placeholder}${props.i18n.member_address}`}
                title={props.i18n.member_address}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.member_address}`}
              />
            </Col>
          )}
          {drawerTabKey === "detail" && (
            <Col span={24}>
              <FormTextarea
                name="school_remark"
                title={props.i18n.remark}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.remark}`}
              />
            </Col>
          )}
          {drawerTabKey === "grade" && (
            <Col span={24}>
              <div
                style={{
                  width: "100%",
                  backgroundColor:
                    props.color.currentTheme === "dark" ? "#002C51" : "#C2EBE8",
                  borderRadius: "20px",
                  height: "70px",
                  padding: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    color: props.color.textColor,
                    width: "100%",
                  }}
                >
                  {props.i18n.school_grade}
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    color: props.color.textColor,
                    minWidth: "60px",
                    textAlign: "center",
                  }}
                >
                  {props.i18n.operation}
                </div>
              </div>
              <Form.List name="grades">
                {(fields, { add, remove }) => (
                  <div>
                    <div
                      className="scrollList"
                      style={{
                        maxHeight: `${props.screenHeight - 412}px`,
                        paddingBottom: "8px",
                      }}
                    >
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: "0px 16px 0px 8px",
                          }}
                        >
                          <FormInput
                            {...restField}
                            title=""
                            name={[name, "grade_name"]}
                            rules={[
                              { required: true, message: props.i18n.required },
                            ]}
                            style={{ width: "100% !important" }}
                            textColor={props.color.inputTitleColor}
                            placeholder={`${props.i18n.placeholder}${props.i18n.school_grade}`}
                          />
                          <div
                            style={{
                              minWidth: "60px",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "15px",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#D53838",
                                border: "1px solid #F31616",
                                marginLeft: "8px",
                              }}
                              onClick={() => remove(name)}
                            >
                              <img
                                src={Images.delete}
                                style={{ width: "22px", height: "14px" }}
                                alt={props.i18n.delete}
                              />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        style={{
                          fontSize: "18px",
                          border: "2px dashed #0EA285",
                          height: "50px",
                          lineHeight: "50px",
                          padding: "0px",
                          color:
                            props.color.currentTheme === "dark"
                              ? "#00FFCE"
                              : "#26695C",
                          backgroundColor: "transparent",
                          borderRadius: "15px",
                        }}
                      >
                        + {props.i18n.create_btn}
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </Col>
          )}
        </Row>
        <Form.Item style={{ width: "100%", textAlign: "center" }}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const handleDeleteSchool = (id) => {
    const { handleDeleteSchool, paging } = props;

    let getData = {
      search: search,
      page: paging.page,
      pageCount: paging.pageCount,
    };

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: "#42C1B4",
    }).then((result) => {
      if (result.value) {
        handleDeleteSchool(id, setIsLoading, () => {}, getData);
      }
    });
  };

  const handleSizeChange = (e) => {
    navigate(
      `/member/school?search=${search}&page=1${
        e !== 12 ? `&pageCount=${e}` : ""
      }`
    );
  };

  const handlePageChange = (e) => {
    navigate(
      `/member/school?search=${search}${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }${e !== 1 ? `&page=${e}` : ""}`
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(
        `/member/school?search=${e}${
          props.paging.pageCount !== 12
            ? `&pageCount=${props.paging.pageCount}`
            : ""
        }&page=1`
      );
    };
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay);
    }
    debounce(getSearch, 500);
  };

  const handleShowView = (data) => {
    const { getSchoolById } = props;
    const callback = (value) => {
      setMode("view");
      setCurrentData(value);
      setDrawerVisible(true);
    };
    getSchoolById(data.school_id, setIsLoading, callback);
  };

  const renderDrawerTitle = () => {
    if (mode === "create") {
      return props.i18n.school_create;
    } else if (mode === "update") {
      return props.i18n.school_update;
    } else if (mode === "view") {
      return props.i18n.school_view;
    } else if (mode === "permission") {
      return "權限設定";
    } else if (mode === "import") {
      return props.i18n.school_import;
    }
  };

  const handleImport = (item) => {
    setSchoolId(item.school_id);
    setMode("import");
    setDrawerVisible(true);
  };

  const handleUpdatePermission = (item) => {
    const callback = () => {
      setSchoolId(item.school_id);
      setMode("permission");
      setDrawerVisible(true);
    }
    props.getSchoolPermissionForm(item.school_id, () => { }, callback);
  }

  return (
    <div id="school">
      <Row gutter={24}>
        <Col span={12}>
          <Search searchValue={search} handleSearch={handleSearchChange} />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            style={{
              width: "128px",
              height: "50px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#4A9E8F",
              border: `2px solid ${props.color.createBtnBorder}`,
              borderRadius: "15px",
              fontSize: "18px",
              marginRight: "24px",
            }}
          >
            {props.i18n.import_btn}
          </Button>
          <Button
            onClick={() => {
              setDrawerVisible(true);
              setMode("create");
              setFormValue({});
              setCurrentData({});
            }}
            style={{
              width: "128px",
              height: "50px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#4A9E8F",
              border: `2px solid ${props.color.createBtnBorder}`,
              borderRadius: "15px",
              fontSize: "18px",
            }}
          >
            {props.i18n.create_btn}
          </Button>
        </Col>
      </Row>
      <Row
        gutter={24}
        className="scrollList"
        style={{
          maxHeight: `${props.screenHeight - 273}px`,
          marginRight: "0px !important",
        }}
      >
        {props.schoolList.map((item) => {
          return (
            <Col
              span={8}
              style={{
                // height: '225px',
                width: "100%",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  backdropFilter: "blur(30px) brightness(1.5)",
                }}
              >
                <div style={{ display: "flex", padding: "24px 24px 0px 24px" }}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          title={item.school_name}
                          style={{
                            fontSize: "18px",
                            color:
                              props.color.currentTheme === "dark"
                                ? "white"
                                : "#242933",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.school_name}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="user"
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.address_icon_dark
                              : Images.address_icon
                          }
                          style={{
                            width: "13px",
                            height: "17px",
                            marginRight: "8px",
                          }}
                        />
                        <div
                          title={
                            item.school_city +
                            item.school_area +
                            item.school_address
                          }
                          style={{
                            fontSize: "16px",
                            color:
                              props.color.currentTheme === "dark"
                                ? "white"
                                : "#242933",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.school_city +
                            item.school_area +
                            item.school_address}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "30px",
                        color: props.color.inputTitleColor,
                        minWidth: "120px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {item.members}
                      {props.i18n.school_people}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#85D1C2",
                    margin: "4px 0px",
                  }}
                ></div>
                <div
                  style={{
                    height: "64px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 24px",
                    backgroundColor: "#ACF8EF",
                    alignItems: "center",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <div style={{ fontSize: "18px", color: "#26695C" }}>
                    {props.i18n.school_grade}({item.grades})
                  </div>
                  <div style={{ display: "flex" }}>
                    {(props.roleId === 'M001' || props.roleId === 'M002') &&<Button
                      style={{
                        // width: '40px',
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                      onClick={() => handleUpdatePermission(item)}
                    >
                      權限
                    </Button>}
                    <Button
                      style={{
                        // width: '40px',
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                      onClick={() => handleImport(item)}
                      // onClick={() => navigate(`/course/view/${item.course_id}`)}
                    >
                      {/* <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} /> */}
                      匯入人員
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowView(item)}
                    >
                      <img
                        alt="eye"
                        src={Images.eye_open}
                        style={{ width: "22px", height: "14px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowUpdate(item)}
                    >
                      <img
                        alt="edit"
                        src={Images.edit}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#D53838",
                        border: `1px solid #F31616`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleDeleteSchool(item.school_id)}
                    >
                      <img
                        alt="delete"
                        src={Images.delete}
                        style={{ width: "19.4px", height: "19.4px" }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <div
        style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          defaultCurrent={props.paging.page}
          total={props.paging.total}
          current={props.paging.page}
          pageSize={props.paging.pageCount}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
        <Select
          id="selectList"
          style={{ width: "112px", marginLeft: "16px" }}
          defaultValue={props.paging.pageCount}
          value={props.paging.pageCount}
          onChange={handleSizeChange}
        >
          {sizeList.map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {item.name} / {props.i18n.page}
            </Select.Option>
          ))}
        </Select>
      </div>
      {
        <Drawer
          placement="right"
          className={
            props.color.currentTheme === "dark" ? "dark_member" : "light_member"
          }
          closable={false}
          maskClosable={mode === "view" ? true : false}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Images.close}
              alt="close"
              style={{ width: "65px", height: "74px", cursor: "pointer" }}
              onClick={() => setDrawerVisible(false)}
            />
            <div
              style={{
                fontSize: "24px",
                color: props.color.inputDrawerTitleColor,
                marginLeft: "30px",
              }}
            >
              {renderDrawerTitle()}
            </div>
          </div>
          {renderDrawerView()}
        </Drawer>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, "Global.theme", "light"),
    themeMode: _.get(state, "Global.themeMode", "dark"),
    color: _.get(state, "Global.color", {}),
    i18n: _.get(state, "Global.i18n", {}),
    i18nMode: _.get(state, "Global.i18nMode", {}),
    screenHeight: _.get(state, "Global.screenHeight", 0),
    schoolList: _.get(state, "School.schoolList", []),
    paging: _.get(state, "School.paging", {}),
    permissionList: _.get(state, "User.permissionList", []),
    roleList: _.get(state, "School.roleList", []),
    permission: _.get(state, "School.permission", []),
    roleId: _.get(state, "User.userData.role_id", ''),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateSchool(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_CREATE_SCHOOL",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleUpdateSchool(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_UPDATE_SCHOOL",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleDeleteSchool(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_DELETE_SCHOOL",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleChangePermission(payload, id, loading, callback) {
      dispatch({
        type: "HANDLE_CHANGE_PERMISSION",
        payload,
        id,
        loading,
        callback,
      });
    },
    getRoleList(payload, loading, callback) {
      dispatch({ type: "GET_ROLE_LIST", payload, loading, callback });
    },
    getSchoolPermissionForm(payload, loading, callback) {
      dispatch({ type: "GET_SCHOOL_PERMISSION_FORM", payload, loading, callback });
    },
    getSchoolList(payload, loading, callback) {
      dispatch({ type: "GET_SCHOOL_LIST", payload, loading, callback });
    },
    getSchoolById(payload, loading, callback) {
      dispatch({ type: "GET_SCHOOL_BY_ID", payload, loading, callback });
    },
    getAllPermission(payload, loading, callback) {
      dispatch({ type: "GET_ALL_PERMISSION", payload, loading, callback });
    },
    getSchoolPermission(payload, loading, callback) {
      dispatch({ type: "GET_SCHOOL_PERMISSION", payload, loading, callback });
    },
    handleImportSchoolMember(payload, loading, callback) {
      dispatch({
        type: "HANDLE_IMPORT_SCHOOL_MEMBER",
        payload,
        loading,
        callback,
      });
    },
    saveLoading(payload) {
      dispatch({ type: "SAVE_LOADING", payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(School);
