import moment from "moment";

const initialState = {
  toast: [],
  isMobile: false,
  theme: null,
  initialLoad: false,
  themeMode: "dark",
  loading: false,
  color: {
    currentTheme: "dark",
    logoColor: "#FFFFFF",
    primaryColor: "#0EA498",
    secondColor: "#BBFFF2",
    thirdColor: "#025444",
    fourColor: "#E98400",
    fiveColor: "#FEAE19",
    sixColor: "#313131",
    enText: "#2FD37A",
    menuCheckOneColor: "#0EA498",
    menuCheckSecondColor: "#0EA498",
    menuCheckThirdColor: "#1E5C71",
    menuChildCheckOneColor: "#6EE9D1",
    menuChildCheckSecondColor: "#2BA8A4",
    menuChildCheckThirdColor: "#1E8F79",
    menuTitle: "#FFFFFF",
    leftBackground: "rgba(14, 164,152, 0.44)",
    leftMenuText: "#FFFFFF",
    headerColor: "#C7E3D4",
    tagBarTextColor: "#00FFCE",
    createBtnBorder: "#242933",
    eChartTextColor: "#DDFFF2",
    eChartTitleColor: "#DAF1DD",
    eChartItemLeft: "#84E8EB",
    eChartItemRight: "#40B4AF",
    inputTitleColor: "#00FFCE",
    inputDrawerTitleColor: "#00FFCE",
    drawerTabBackground: "#0096A7",
    drawerTabBorder: "white",
    drawerTabText: "#00FFCE",
    textColor: "white",
  },
  darkTheme: {
    currentTheme: "dark",
    logoColor: "#FFFFFF",
    primaryColor: "#0EA498",
    secondColor: "#BBFFF2",
    thirdColor: "#025444",
    fourColor: "#E98400",
    fiveColor: "#FEAE19",
    sixColor: "#313131",
    enText: "#2FD37A",
    menuCheckOneColor: "#0EA498",
    menuCheckSecondColor: "#0EA498",
    menuCheckThirdColor: "#1E5C71",
    menuChildCheckOneColor: "#6EE9D1",
    menuChildCheckSecondColor: "#2BA8A4",
    menuChildCheckThirdColor: "#1E8F79",
    menuTitle: "#FFFFFF",
    leftBackground: "rgba(14, 164,152, 0.44)",
    leftMenuText: "#FFFFFF",
    headerColor: "#C7E3D4",
    tagBarTextColor: "#00FFCE",
    createBtnBorder: "#DAF1DD",
    eChartTextColor: "#DDFFF2",
    eChartTitleColor: "#DAF1DD",
    eChartItemLeft: "#84E8EB",
    eChartItemRight: "#40B4AF",
    inputTitleColor: "#00FFCE",
    inputDrawerTitleColor: "#00FFCE",
    drawerTabBackground: "#0096A7",
    drawerTabBorder: "white",
    drawerTabText: "#00FFCE",
    textColor: "white",
  },
  lightTheme: {
    currentTheme: "light",
    logoColor: "#0F3982",
    primaryColor: "#FFFFFF",
    secondColor: "#008B70",
    thirdColor: "#D3F6EC",
    fourColor: "#E98400",
    fiveColor: "#FEAE19",
    sixColor: "#EFEFEF",
    enText: "white",
    menuCheckOneColor: "#B6E5D4",
    menuCheckSecondColor: "#BBFFE6",
    menuCheckThirdColor: "#FFFFFF",
    menuChildCheckOneColor: "#E3F2EC",
    menuChildCheckSecondColor: "#C8EBDE",
    menuChildCheckThirdColor: "#C8EBDE",
    menuTitle: "#2FD37A",
    leftBackground: "rgba(255, 255,255, 0.66)",
    leftMenuText: "#046F66",
    headerColor: "#26695C",
    tagBarTextColor: "#125346",
    createBtnBorder: "#C7E3D4",
    eChartTextColor: "#005040",
    eChartTitleColor: "#242933",
    eChartItemLeft: "#2FC4C9",
    eChartItemRight: "#23DED7",
    inputTitleColor: "#26695C",
    inputDrawerTitleColor: "#26695C",
    drawerTabBackground: "#00DDFF",
    drawerTabBorder: "#17A0AA",
    drawerTabText: "#125346",
    textColor: "#242933",
  },
  i18nMode: "en",
  i18n: {},
  zh: {
    // menu
    menu_tag: "索引值管理",
    menu_ability: "能力管理",
    menu_knowledge: "知識樹管理",
    menu_knowledge_character: "單字管理",
    menu_knowledge_string: "字串管理",
    menu_knowledge_grammar: "文法管理",
    menu_knowledge_article: "文章管理",
    menu_knowledge_talk: "影片管理",
    menu_member: "帳號管理",
    menu_member_school: "學校管理",
    menu_member_grade: "班級管理",
    menu_member_user: "會員管理",
    menu_member_log: "會員登入時間查詢",
    menu_topic: "題目管理",
    menu_topic_group: "題目群組",
    menu_topic_manager: "考題管理",
    menu_exam: "測驗管理",
    menu_exam_create: "測驗新增",
    menu_exam_update: "測驗編輯",
    menu_exam_topic: "題型編輯",
    menu_book: "教科書管理",
    menu_game: "闖關遊戲設定",
    menu_course: "課程管理",
    menu_download: "下載管理",
    menu_mail: "郵件管理",
    menu_news: "公告管理",
    menu_question: "問卷管理",
    menu_code: "驗證碼管理",
    menu_pay: "付費管理",
    menu_lottery: "抽獎活動",
    menu_statistics: "統計記錄",
    menu_review: "測驗評分",

    // common
    logout: "登出",
    home: "首頁",
    operation: "操作",
    edit: "編輯",
    delete: "刪除",
    save: "儲存",
    save_and_back: "儲存並返回",
    placeholder: "請輸入",
    create_btn: "新增",
    update_btn: "編輯",
    import_btn: "匯入",
    export_btn: "匯出",
    send_btn: "送出",
    patch_delete_btn: "批次刪除",
    page: "頁",
    select: "已選",
    item: "項",
    remark: "備註",
    required: "該欄位資料不可為空",
    upload: "上傳圖片",
    uploadAudio: "上傳音檔",
    uploadFile: "上傳檔案",
    typeError: "格式錯誤",
    filter: "篩選",
    tag: "索引值",
    ability: "能力值",
    add_item: "新增項目",
    add_item_placeholder: "請輸入項目內容",
    img: "圖片",
    audio: "音檔",
    answer: "答案",
    option_content: "選項",
    option_file: "檔案",
    create_option: "新增選項",
    create_topic: "新增題目",
    origin_price: "原價",
    discount_price: "特價",
    start_time: "開始時間",
    end_time: "結束時間",
    file_upload: "檔案上傳",
    exam: "測驗",

    // tag
    table_tag_name: "名稱",
    table_tag_count: "關聯筆數",
    table_tag_updater: "編輯者",
    table_tag_update_at: "最後更新時間",
    drawer_connect: "關聯",
    drawer_name: "索引值",
    create_success: "新增成功",
    update_success: "編輯成功",
    import_success: "匯入成功",
    export_success: "匯出成功",
    upload_success: "上傳成功",
    download_success: "下載成功",
    download_loading: "下載中",
    copy_success: "複製成功",
    delete_success: "刪除成功",
    delete_confirm: "確認是否要刪除?",
    copy_confirm: "確認是否要複製?",
    swal_yes: "確定",
    swal_no: "取消",
    next_step: "下一步",
    prev_step: "上一步",
    update_cancel: "是否要捨棄變更並返回",

    // member
    member_name: "姓名",
    member_nick_name: "暱稱",
    member_birth: "生日",
    member_school: "學校",
    member_grade: "班級",
    member_email: "信箱",
    member_addr_title: "地址",
    member_city: "縣市",
    member_area: "鄉鎮市區",
    member_address: "道路或街名",
    member_account_title: "帳號資料",
    member_account: "帳號",
    member_role: "角色",
    member_password: "密碼",
    member_password_confirm: "密碼確認",
    member_new_password: "新密碼",
    member_new_password_confirm: "新密碼確認",
    member_password_btn: "變更密碼",
    member_password_diff: "密碼輸入不一致",
    login_count: "登入次數",
    login_time: "上線時間",
    member_create: "會員新增",
    member_update: "會員編輯",
    member_update_account: "帳號密碼編輯",

    // school
    school_grade: "班級",
    school_people: "人",
    school_create: "學校新增",
    school_update: "學校編輯",
    school_view: "學校檢視",
    school_permission: "權限調整",
    school_name: "學校名稱",
    school_en_name: "學校英文名稱",
    school_detail: "基本資料",
    school_import: "匯入人員",

    // knowledge
    character_create: "單字新增",
    character_update: "單字編輯",
    character_view: "單字檢視",
    character_name: "單字",
    character_kk: "音標",
    character_desc: "說明",
    character_speeches: "詞性",
    character_tag: "索引值",
    character_ability: "能力級別",
    character_example_title: "說明例句",
    character_example: "例句",
    character_en_example: "英文例句",
    character_zh_example: "例句中文翻譯",

    // String
    string_create: "字串新增",
    string_update: "字串編輯",
    string_view: "字串檢視",
    string_name: "字串",

    // Grammar
    grammar_create: "文法新增",
    grammar_update: "文法編輯",
    grammar_view: "文法檢視",
    grammar_name: "文法",

    // Article
    article_create: "文章新增",
    article_update: "文章編輯",
    article_view: "文章檢視",
    article_name: "文章",

    // Topic Group
    topic_group_create: "題目群組新增",
    topic_group_update: "題目群組編輯",
    topic_group_view: "題目群組檢視",
    topic_group_name: "題目群組",
    topic_count: "題數",
    topic_view: "查看詳細",

    // Topic Manager
    topic_manager_topic_type: "題目題型",
    topic_manager_topic_group: "所屬題目群組",
    topic_manager_title: "題目",
    topic_manager_answer_desc: "題目詳解",
    topic_manager_answer: "正確答案",
    topic_manager_topic_list: "題目列表",
    topic_manager_create: "題目新增",
    topic_manager_update: "題目編輯",
    topic_manager_view: "題目檢視",
    topic_manager_import_topic: "題目檔案",
    topic_manager_import_file: "題目音檔",

    // Exam
    exam_title: "測驗名稱",
    exam_desc: "描述",
    exam_time: "測驗時間(分)",
    exam_page: "大題數量",
    exam_is_exam: "是否為練習",
    exam_is_fix: "考卷內容是否隨機",
    exam_show_answer: "完成後是否顯示答案",
    exam_show_score: "完成後是否顯示分數",
    exam_show_detail: "完成後是否顯示解析",
    exam_show_pvqc: "是否為PVQC測驗",
    exam_is_big: "是否為校內檢測",
    exam_page_setting: "大題設定",
    exam_page_list: "大題列表",
    exam_page_title: "大題標題",
    exam_page_score: "每題分數",
    exam_page_video_time: "影片播放次數",
    exam_page_content: "題型內容",
    exam_page_topic_type: "題型",
    exam_page_topic_store: "題型題庫數量",
    exam_page_part_topic_store: "題庫數量",
    exam_page_topic_count: "出題數量",
    exam_auto: "系統產出",
    exam_custom: "自選題目",
    exam_role: "可參加角色",
    exam_course: "可參加課程",

    // Book
    book_name: "教材名稱",
    book_effective: "有效天數",
    book_img: "封面圖片",
    book_count: "章節數量",
    book_on_sell: "上架",
    book_off_sell: "下架",
    book_part_setting: "章節設定",
    book_part_content: "章節內容",
    book_page_title: "該頁標題",

    // Course
    course_title: "課程名稱",
    course_desc: "課程描述",
    course_start: "開始時間",
    course_end: "結束時間",
    course_exam_start: "開始時間",
    course_exam_end: "結束時間",
    course_teacher: "任課老師",
    course_student: "選課學生",
    course_book: "教科書",
    course_exam: "測驗",
    course_imgs: "課程封面",
    course_import: "匯入選課學生",

    // Talk
    talk_create: "影片新增",
    talk_title: "影片標題",
    talk_desc: "影片介紹",
    talk_length: "影片長度",
    talk_date: "錄製日期",
    talk_img: "封面圖檔上傳",
    talk_cc_import: "字幕檔上傳",
    talk_en_cc_view: "英文字幕檢視",
    talk_zh_cc_view: "中文字幕檢視",
    talk_module: "學練測模組",
    talk_learn: "學習",
    talk_practice: "練習",
    talk_exam: "測驗",
    talk_practice_count: "設定測驗次數",
    talk_exam_score: "設定測驗分數",
    talk_video_upload: "影片上傳",
    talk_video_cover_upload: "封面圖與字幕上傳",
    talk_cc_start: "開始秒數",
    talk_cc_end: "結束秒數",
    talk_cc_desc: "字幕內容",
    talk_cc_audio: "字幕音檔",
    talk_sell: "上架狀態",

    // Lottery
    lottery_act_title: "活動名稱",
    lottery_act_desc: "活動描述",
    lottery_start_time: "開始時間",
    lottery_end_time: "結束時間",
    lottery_act_mode: "活動模式(是否可於一天抽到多個實體獎品)",
    lottery_act_school: "可參加學校",
    lottery_repeat: "是否可重複累積",
    lottery_mode: "抽獎模式(順序或平均分配)",
    lottery_award_name: "獎項名稱",
    lottery_award_count: "數量",
    lottery_award_probability: "機率",
    lottery_award_start: "開始時間",
    lottery_award_real: "是否為實體獎品",
    lottery_award_isHave: "是否可兌換",
    lottery_award_isCollect: "是否為集字",
    lottery_award_collect_text: "集字內容以,做區隔",

    lottery_log_name: "姓名",
    lottery_log_award_name: "獎項名稱",
    lottery_log_time: "抽出時間",
    lottery_log_exchanger: "負責人",
    lottery_log_change_time: "兌換時間",
    lottery_log_isExchange: "可兌換",

    // 統計紀錄
    statistics_login_name: "姓名",
    statistics_login_count: "登入次數",
    statistics_login_subtotal: "登入時間統計(分)",
    statistics_exam_name: "測驗名稱",
    statistics_exam_member: "測驗人",
    statistics_exam_time: "花費時間(分)",
    statistics_exam_score: "分數",
    statistics_exam_end_time: "結束時間",

    // 測驗評分
    review_exam_name: "測驗名稱",
    review_member_name: "測驗人",
    review_date: "測驗日期",
    review_score: "評測分數",
    review_last_updated: "上次評分日期",
    review_check_remark: "評語",
    review_topic_title: "題目",
    review_topic_answer: "學生答案",

    // Download
    download_create_title: "上傳檔案",
    download_name: "顯示名稱",
    download_file: "檔案",
    download_role: "身份",

    // News
    news_title: "標題",
    news_content: "描述",
    news_open: "上架狀態",
    news_top: "置頂狀態",
    news_open_check: "上架中",
    news_open_un_check: "下架中",
    news_top_check: "已置頂",
    news_top_un_check: "未置頂",
  },
  en: {
    // menu
    menu_tag: "Tag",
    menu_ability: "Ability",
    menu_knowledge: "knowledge",
    menu_knowledge_character: "Character",
    menu_knowledge_string: "String",
    menu_knowledge_grammar: "Grammar",
    menu_knowledge_article: "Article",
    menu_knowledge_talk: "Video",
    menu_member: "Member",
    menu_member_school: "School",
    menu_member_grade: "Grade",
    menu_member_user: "User",
    menu_member_log: "Login Log",
    menu_topic: "Topic",
    menu_topic_group: "Topic Group",
    menu_topic_manager: "Topic Manager",
    menu_exam: "Exam",
    menu_exam_create: "Exam Create",
    menu_exam_update: "Exam Update",
    menu_exam_topic: "Topic Update",
    menu_book: "Book",
    menu_game: "Game",
    menu_course: "Course",
    menu_download: "Download",
    menu_mail: "Mail",
    menu_news: "News",
    menu_question: "Question",
    menu_code: "Code",
    menu_pay: "Pay",
    menu_lottery: "Lottery",
    menu_statistics: "Statistics",
    menu_review: "Review",

    // common
    logout: "Logout",
    home: "Home",
    operation: "Operation",
    edit: "Edit",
    delete: "Delete",
    save: "Save",
    save_and_back: "Save and back",
    placeholder: "Please Enter ",
    create_success: "Create Success",
    update_success: "Update Success",
    import_success: "Import Success",
    export_success: "Export Success",
    upload_success: "Upload Success",
    download_success: "Download Success",
    download_loading: "Loading....",
    copy_success: "Copy Success",
    delete_success: "Delete Success",
    delete_confirm: "Are you sure to delete?",
    copy_confirm: "Are you sure to copy?",
    swal_yes: "Yes",
    swal_no: "Cancel",
    create_btn: "Create",
    update_btn: "Update",
    import_btn: "Import",
    export_btn: "Export",
    send_btn: "Send",
    patch_delete_btn: "Patch Delete",
    page: "Page",
    select: "Selected",
    item: "Items",
    remark: "Remark",
    required: "This input is required",
    upload: "Upload Image",
    uploadAudio: "Upload",
    uploadFile: "Upload Audio",
    typeError: "Type Format Error",
    filter: "Filter ",
    tag: "Tag",
    ability: "Ability",
    add_item: "Add Item",
    add_item_placeholder: "Please Add Item",
    img: "Image",
    audio: "Audio",
    answer: "Answer",
    option_content: "Options",
    option_file: "File",
    create_option: "Create Options",
    create_topic: "Create Topic",
    origin_price: "Origin Price",
    discount_price: "Discount Price",
    next_step: "Next Step",
    prev_step: "Prev Step",
    start_time: "Start Time",
    end_time: "End Time",
    file_upload: "Files Upload",

    // tag
    table_tag_name: "Name",
    table_tag_count: "Count",
    table_tag_updater: "Updater",
    table_tag_update_at: "Last Update Time",
    drawer_connect: "Connect",
    drawer_name: "Tag Name",
    update_cancel: "Whether to discard changes and return",

    // member
    member_name: "Name",
    member_nick_name: "Nick Name",
    member_birth: "Birth",
    member_school: "School",
    member_grade: "Grade",
    member_email: "Email",
    member_addr_title: "Address Detail",
    member_city: "City",
    member_area: "Area",
    member_address: "Address",
    member_account_title: "Account Detail",
    member_account: "Account",
    member_role: "Role",
    member_password: "Password",
    member_password_confirm: "Password Confirm",
    member_new_password: "New Password",
    member_new_password_confirm: "New Password Confirm",
    member_password_btn: "Change Password",
    member_password_diff: "Password Diff",
    login_count: "Login Count",
    login_time: "Learn Time",
    member_create: "Member Create",
    member_update: "Member Update",
    member_update_account: "Account Update",

    // school
    school_grade: "Grade",
    school_people: "",
    school_create: "School Create",
    school_update: "School Update",
    school_view: "School Detail",
    school_permission: "Permission",
    school_name: "School Name",
    school_en_name: "School Name(EN)",
    school_detail: "Basic Information",
    school_import: "Import Member",

    // knowledge
    character_create: "Character Create",
    character_update: "Character Update",
    character_view: "Character Detail",
    character_name: "Character",
    character_kk: "KK",
    character_desc: "Description",
    character_speeches: "Speeches",
    character_tag: "Tags",
    character_ability: "Ability",
    character_example_title: "Description Sample",
    character_example: "Example",
    character_en_example: "English Example",
    character_zh_example: "Chinese Example",

    // String
    string_create: "String Create",
    string_update: "String Update",
    string_view: "String Detail",
    string_name: "String",

    // Grammar
    grammar_create: "Grammar Create",
    grammar_update: "Grammar Update",
    grammar_view: "Grammar Detail",
    grammar_name: "Grammar",

    // Article
    article_create: "Article Create",
    article_update: "Article Update",
    article_view: "Article Detail",
    article_name: "Article",

    // TopicGroup
    topic_group_create: "Topic Group Create",
    topic_group_update: "Topic Group Update",
    topic_group_view: "Topic Group Detail",
    topic_group_name: "Topic Group",
    topic_count: "Number of Questions",
    topic_view: "View Topic",

    // Topic Manager
    topic_manager_topic_type: "Topic Type",
    topic_manager_topic_group: "Belong Topic Group",
    topic_manager_title: "Title",
    topic_manager_answer_desc: "Topic Answer Detail",
    topic_manager_answer: "Answer",
    topic_manager_topic_list: "Topic List",
    topic_manager_create: "Topic Create",
    topic_manager_update: "Topic Update",
    topic_manager_view: "Topic Detail",
    topic_manager_import_topic: "Topic File",
    topic_manager_import_file: "Topic Video",

    // Exam
    exam_title: "Exam Title",
    exam_desc: "Description",
    exam_time: "Exam Time(m)",
    exam_page: "Page Count",
    exam_is_exam: "Is Practice ?",
    exam_is_fix: "Is the content of the test paper random ?",
    exam_show_answer: "Is to show the answer when done ?",
    exam_show_score: "Is to show the score when done ?",
    exam_show_detail: "Is to show the answer detail when done ?",
    exam_show_pvqc: "Is PVQC ?",
    exam_is_big: "Is On-campus testing ?",
    exam_page_setting: "Big Topic Setting",
    exam_page_list: "Big Topic List",
    exam_page_title: "Big Topic Title",
    exam_page_score: "Count for items",
    exam_page_content: "Topic Type Content",
    exam_page_topic_type: "Topic Type",
    exam_page_topic_store: "Topic All Count",
    exam_page_part_topic_store: "Topic All Count",
    exam_page_topic_count: "Topic of Count",
    exam_auto: "Auto",
    exam_custom: "Custom",
    exam_role: "Roles",
    exam_course: "Course",

    // Book
    book_name: "Book Name",
    book_effective: "Book Effective Date",
    book_img: "Book Cover Photo",
    book_count: "Part Count",
    book_on_sell: "On Sell",
    book_off_sell: "Off Sell",
    book_part_setting: "Book Part Setting",
    book_part_content: "Book Part Content",
    book_page_title: "Book Page Title",

    // Course
    course_title: "Course Name",
    course_desc: "Course Description",
    course_start: "Start Time",
    course_end: "End Time",
    course_exam_start: "Start Time",
    course_exam_end: "End Time",
    course_teacher: "Teacher",
    course_student: "Students",
    course_book: "Books",
    course_exam: "Exams",
    course_imgs: "Image",
    course_import: "Import Student",

    // Talk
    talk_create: "Video Create",
    talk_title: "Video Title",
    talk_desc: "Video Description",
    talk_length: "Video length",
    talk_date: "Record Date",
    talk_img: "Cover Photo",
    talk_cc_import: "Subtitle",
    talk_en_cc_view: "English Subtitle",
    talk_zh_cc_view: "Chinese Subtitle",
    talk_module: "Module",
    talk_learn: "Learn",
    talk_practice: "Practice",
    talk_exam: "Exam",
    talk_practice_count: "Practice Count",
    talk_exam_score: "Exam Score",
    talk_video_upload: "Video Upload",
    talk_video_cover_upload: "Cover image and subtitle upload",
    talk_cc_start: "Start",
    talk_cc_end: "End",
    talk_cc_desc: "Content",
    talk_cc_audio: "Audio",
    talk_sell: "Status",

    // Lottery
    lottery_act_title: "活動名稱",
    lottery_act_desc: "活動描述",
    lottery_start_time: "開始時間",
    lottery_end_time: "結束時間",
    lottery_act_mode: "活動模式(是否可於一天抽到多個實體獎品)",
    lottery_act_school: "可參加學校",
    lottery_repeat: "是否可重複累積",
    lottery_mode: "抽獎模式(順序或平均分配)",
    lottery_award_name: "獎項名稱",
    lottery_award_count: "數量",
    lottery_award_probability: "機率",
    lottery_award_start: "開始時間",
    lottery_award_real: "是否為實體獎品",
    lottery_award_isHave: "是否可兌換",
    lottery_award_isCollect: "是否為集字",
    lottery_award_collect_text: "集字內容以,做區隔",

    lottery_log_name: "Name",
    lottery_log_award_name: "Award Name",
    lottery_log_time: "Time",
    lottery_log_exchanger: "Updater",
    lottery_log_change_time: "Exchange Time",
    lottery_log_isExchange: "Is Exchange",

    // 統計紀錄
    statistics_login_name: "姓名",
    statistics_login_count: "登入次數",
    statistics_login_subtotal: "登入時間統計(分)",
    statistics_exam_name: "測驗名稱",
    statistics_exam_member: "測驗人",
    statistics_exam_time: "花費時間",
    statistics_exam_score: "分數",
    statistics_exam_end_time: "結束時間",

    // 測驗評分
    review_exam_name: "測驗名稱",
    review_member_name: "測驗人",
    review_date: "測驗日期",
    review_score: "評測分數",
    review_last_updated: "上次評分日期",
    review_check_remark: "評語",
    review_topic_title: "題目",
    review_topic_answer: "學生答案",

    // Download
    download_create_title: "Upload File",
    download_name: "Show Name",
    download_file: "File",
    download_role: "Identity",

    // News
    news_title: "Title",
    news_content: "Description",
    news_open: "上架狀態",
    news_top: "置頂狀態",
  },
  screenHeight: 0,
  screenWidth: 0,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_isMobile":
      return { ...state, isMobile: payload };
    case "SAVE_LOADING":
      return { ...state, loading: payload };
    case "SAVE_Theme":
      return { ...state, theme: payload };
    case "SAVE_InitialLoad":
      return { ...state, initialLoad: payload };
    case "SAVE_Toast":
      return { ...state, toast: payload };
    case "SAVE_Location":
      return { ...state, location: payload };
    case "SAVE_Screen":
      return {
        ...state,
        screenWidth: payload.screenWidth,
        screenHeight: payload.screenHeight,
      };
    case "handleChangeTheme":
      let tempColor = {};

      if (payload === "dark") {
        tempColor = state.darkTheme;
      } else if (payload === "light") {
        tempColor = state.lightTheme;
      } else if (payload === "auto") {
        if (moment().format("HHmm") > "1800") {
          tempColor = state.darkTheme;
        } else {
          tempColor = state.lightTheme;
        }
      }
      window.localStorage.setItem("DTS_THEME", payload);
      return { ...state, themeMode: payload, color: tempColor };
    case "setInitTheme":
      let tempInitColor = {};
      if (payload === "light") {
        tempInitColor = state.lightTheme;
      } else {
        tempInitColor = state.darkTheme;
      }
      return { ...state, themeMode: payload, color: tempInitColor };
    case "handleChangeI18N":
      let tempI18NMode = "en";
      let tempI18N = {};

      if (state.i18nMode === "en") {
        tempI18NMode = "zh";
        tempI18N = state.zh;
      } else {
        tempI18NMode = "en";
        tempI18N = state.en;
      }
      window.localStorage.setItem("DTS_I18N", tempI18NMode);
      return { ...state, i18nMode: tempI18NMode, i18n: tempI18N };
    case "setInitI18N":
      let tempInitI18N = {};
      if (payload === "en") {
        tempInitI18N = state.en;
      } else {
        tempInitI18N = state.zh;
      }
      return { ...state, i18nMode: payload, i18n: tempInitI18N };
    default:
      return state;
  }
};

export default Reducer;
