/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Knowledge,
  CREATE_Knowledge,
  UPDATE_Knowledge,
  DELETE_Knowledge,
  GET_KnowledgeById,
  GET_KnowledgeByForm,
} from "../services/Knowledge";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getKnowledgeList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Knowledge, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_KNOWLEDGE_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getKnowledgeListByForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_KnowledgeByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_KNOWLEDGE_LIST_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* getKnowledgeById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_KnowledgeById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateKnowledge({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Knowledge, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_KNOWLEDGE_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateKnowledge({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Knowledge, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_KNOWLEDGE_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteKnowledge({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Knowledge, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_KNOWLEDGE_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_KNOWLEDGE_LIST", getKnowledgeList);
  yield takeLatest("GET_KNOWLEDGE_BY_ID", getKnowledgeById);
  yield takeLatest("HANDLE_CREATE_KNOWLEDGE", handleCreateKnowledge);
  yield takeLatest("HANDLE_UPDATE_KNOWLEDGE", handleUpdateKnowledge);
  yield takeLatest("HANDLE_DELETE_KNOWLEDGE", handleDeleteKnowledge);
  yield takeLatest("GET_KNOWLEDGE_LIST_BY_FORM", getKnowledgeListByForm);
}
