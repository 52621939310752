const Reducer = (state = {
  paging: {
    page: 1,
    pageCount: 12,
  },
  reviewList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_REVIEW_LIST_SUCCESS":
      return { ...state, reviewList: payload.results, paging: payload.paging };
    default:
      return state;
  }
};

export default Reducer;
