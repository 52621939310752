import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Drawer, Row, Col, Form, Input } from 'antd';
import ReactECharts from 'echarts-for-react';
import DataTable from "../components/common/DataTable";
import Images from "../themes/Images";

import "./Ability.less";
import _ from "lodash";
import moment from "moment";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const App = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('create');
  const [currentData, setCurrentData] = useState({ list: ['123', '1233', '123123', '123134', '43211', '12312']});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState('character');
  const [currentName, setCurrentName] = useState('單字');
  const [hoverKey, setHoverKey] = useState('');
  const tabList = [{
    name: '單字',
    key: 'character',
    id: 1,
  }, {
    name: '文法',
    key: 'grammar',
    id: 2,
  }, {
    name: '字串',
    key: 'string',
    id: 3,
  }, {
    name: '文章',
    key: 'article',
    id: 4,
  }, {
    name: '題目',
    key: 'topic',
    id: 5,
  }, {
    name: '課程',
    key: 'course',
    id: 6,
  }, {
    name: '教科書',
    key: 'book',
    id: 7,
  }, {
    name: '測驗',
    key: 'exam',
    id: 8,
  }, {
    name: '闖關活動',
    key: 'game',
    id: 9,
    }];

  const styles = {
    btnStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '15px',
      width: '40px',
      height: '40px',
      backgroundColor: '#42C1B4',
      border: '1px solid #C1EFD6',
    },
    iconImgStyle: {
      width: '24px',
      height: '24px',
    }
  };
  
  const handleSetCurrentKey = (item) => {
    setCurrentKey(item.key);
    setCurrentName(item.name);
  }

  const handleShowUpdate = (data) => {
    setMode('edit');
    setDrawerVisible(true);
    setCurrentData(data);
  }

  const handleCreate = (value) => {
    const { handleCreateTag } = this.props;
    handleCreateTag({ type: currentKey, name: value.name });
  }

  return <div id="tag">
    <div style={{ width: '100%', display: 'flex'}}>
      {tabList.map((item) => <div
        key={item.key}
        onMouseEnter={() => setHoverKey(item.key)}
        onMouseLeave={() => setHoverKey('')}
        onClick={() => handleSetCurrentKey(item)}
        style={{
          marginRight: '16px',
          width: '128px',
          height: '50px',
          background: 'transparent',
          border:(currentKey === item.key || hoverKey === item.key) ? '2px solid #17A0AA' : '2px solid #FFF',
          backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#CDF3DF' :'rgba(0,0,0,0.02)',
          color: (currentKey === item.key || hoverKey === item.key) ? '#4B2E03' : props.color.tagBarTextColor,
          textAlign: 'center',
          lineHeight: '50px',
          borderRadius: '20px',
          fontSize: '18px',
          backdropFilter: 'blur(30px)',
          boxShadow:'inset 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16)',
        }}>
        {item.name}
        <div
          style={{
            width: '70px',
            height: '5px',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#17A0AA' :'#00FFCE',
            position: 'relative',
            bottom: '7px',
            left: '29px',
          }}
        ></div>
      </div>)}
    </div>
    <Row gutter={24} style={{ marginTop: '30px'}}>
      <Col span={8}>
        <div
          style={{
            backgroundColor: 'rgba(64,185,174, 0.3)',
            height: '100%',
            borderRadius: '15px',
            padding: '16px',
            width: '100',
            backdropFilter: 'blur(30px)',
          }}
        >
          <div style={{ fontSize: '24px', color: props.color.eChartTitleColor, textAlign: 'center' }}>資料統計</div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '100%', maxWidth: '150px', height: '1px', backgroundColor: '#129B80'}}></div>
            <div style={{ minWidth: '8px', height: '8px', backgroundColor: '#129B80', borderRadius: '2px', margin: '24px'}}></div>
            <div style={{ width: '100%', maxWidth: '150px', height: '1px', backgroundColor: '#129B80'}}></div>
          </div>
        </div>
      </Col>
      <Col span={16}>
      </Col>
    </Row>

    <Drawer
      placement="right"
      id={props.color.currentTheme === 'dark' ? 'dark_tag' : 'light_tag'}
      closable={false}
      onClose={() => setDrawerVisible(false)} visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{currentName}_索引值</div>
      </div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          name: ''
        }}
        style={{ marginTop: '16px'}}
        onFinish={value => handleCreate(value)}
      >
        <Form.Item
          label={<div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding: '0px 16px',
              fontWeight: 'bold'
            }}>{props.i18n.drawer_name}</div>}
          name="name"
          colon={false}
        >
          <Input
            autoComplete="new-account"
            style={{
              borderRadius: '15px',
              height: '45px',
              color: '#125346',
              border: '2px solid #13D1AC',
              padding: '0px 16px',
              backgroundColor: '#FFF',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
              width: '254px',
            }}
            placeholder={`${props.i18n.placeholder}${props.i18n.drawer_name}`}
          />
        </Form.Item>
        <Form.Item
          label={<div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding: '0px 16px',
              fontWeight: 'bold'
            }}>{props.i18n.drawer_connect}</div>}
          name="name"
          colon={false}
        >
          <div style={{
            backgroundColor: 'rgba(255,255,255,0.7)',
            width: '100%',
            height: '100%',
            maxHeight: '400px',
            minHeight: '400px',
            borderRadius: '15px',
            padding: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'baseline',
          }}>
            {currentData.list.map((item) => {
              return <div
                style={{
                  height: '30px',
                  borderRadius: '20px',
                  backgroundColor: 'rgba(21,158,144,0.67)',
                  lineHeight: '30px',
                  padding: '0px 8px',
                  color: 'white',
                  marginRight: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '18px',
                  marginBottom: '8px',
                }}>
                {item}
                <img src={Images.close_white} alt="close" style={{ width: '14.5px', height: '14.5px', marginLeft: '4px', cursor: 'pointer'}}  />
              </div>
            })}
          </div>
        </Form.Item>
        <Form.Item style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: '50px',
              backgroundColor: '#4A9E8F',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              marginRight: '30px'
            }}
          >
            確定
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: '50px',
              backgroundColor: '#D53838',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
            }}
          >
            取消
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin(payload, callback, loading) {
      dispatch({ type: 'HANDLE_LOGIN' });
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_LOG' });
    }
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
