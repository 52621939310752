import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Upload } from 'antd';
import FormInput from "../components/common/FormInput";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormTextarea from "../components/common/FormTextarea";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import Swal from 'sweetalert2';
import { PlusOutlined } from '@ant-design/icons';
import "./BookCreate.less";
import _ from "lodash";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
let timer;
const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
];
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BookCreate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    chapters: [],
    is_sell: false,
  });
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    props.getTagFormList(8);
  }, []);
  
  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      createForm.current.setFieldsValue({ img: temp });
      setImageData(temp);
    } else {
      createForm.current.setFieldsValue({ img: newFileList });
      setImageData(newFileList);
    }
  };
  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 8,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }
 
  const handleCancel = () => {
    Swal.fire({
      title: props.i18n.update_cancel,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        navigate('/book');
      }
    });
  }
  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleFinish = (value) => {
    if (step === 1) {
      let tempPart = [];
      for (let index = 0; index < parseInt(value.part_total); index++) {
        if (index > formValue.chapters.length -1) {
          tempPart.push({
            chapters_title: '',
            tags: formValue.tags,
            abilities: formValue.abilities,
          });
        } else {
          tempPart.push(formValue.chapters[index]);
        }
      }
      createForm.current.setFieldsValue({ chapters: tempPart });
      setStep(2);
    } else {
      let formData = new FormData();
      formData.append("book_title", formValue.book_title);
      formData.append("book_desc", (formValue.book_desc === null || formValue.book_desc === undefined) ? '': formValue.book_desc);
      if (formValue.book_effective !== undefined) {
        formData.append("book_effective", formValue.book_effective);
      } 
      formData.append("book_price", parseInt(formValue.book_price));
      if (formValue.book_discount !== undefined) {
        formData.append("book_discount", formValue.book_discount);
      } 
      formData.append("is_sell", formValue.is_sell);
      if (imageData.length > 0) {
        formData.append("imgs", imageData[0].originFileObj);
      }
      formData.append("book_title", formValue.book_title);
      formValue.chapters.map((item, index) => {
        formData.append(`chapters[${index}].chapter_title`, item.chapter_title);
        if (_.isArray(item.tags)) {
          item.tags.map((child) => {
            formData.append(`chapters[${index}].tags`, child);
          });
        }
        if (_.isArray(item.abilities)) {
          item.abilities.map((child) => {
            formData.append(`chapters[${index}].abilities`, child);
          });
        }
      });
      formValue.tags.map((child) => {
        formData.append(`tags`, child);
      });
      formValue.abilities.map((child) => {
        formData.append(`abilities`, child);
      });
      const callback = (value) => {
        navigate(`/book/update/${value.book_id}`);
      }
      props.handleCreateBook(formData, setIsLoading, callback);
    }
  }


  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px'}} className="scrollList">
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
    >
      <Row gutter={24}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '64px', height: '64px', border: '2px dashed #26695C', backgroundColor: step === 1 ? '#C4FEEE': 'transparent', borderRadius: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={Images.one} style={{ width: '18px', height: '36px' }} alt="1" />
          </div>
          <div style={{ width: '327px', height: '1px', backgroundColor: '#707070', margin: '0px 30px'}}></div>
          <div style={{ width: '64px', height: '64px', border: '2px dashed #26695C', backgroundColor: step === 2 ? '#C4FEEE' :'transparent', borderRadius: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={Images.two} style={{ width: '18px', height: '36px' }} alt="1" />
          </div>
        </Col>
        {step === 1 && <Col span={16}>
          <FormInput
            title={props.i18n.book_name}
            name="book_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_name}`}
          />
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            title={props.i18n.origin_price}
            name="book_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            title={props.i18n.discount_price}
            name="book_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>}

        {step === 1 && <Col span={8}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            title={props.i18n.book_effective}
            name="book_effective"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_effective}`}
          />
        </Col>}
        {
          step === 1 && <Col span={4} style={{ display: 'flex', alignItems: 'center'}}>
            <Form.Item name="is_sell" valuePropName="checked">
              <Switch></Switch>
            </Form.Item>
            <span
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '4px',
                marginLeft: '16px',
                marginRight: '50px',
                marginBottom: '24px',
              }}
            >{formValue.is_sell ? props.i18n.book_on_sell: props.i18n.book_off_sell}</span>
          </Col>
        }
        {step === 1 && <Col span={24}>
          <FormTextarea
            name="book_desc"
            title={props.i18n.exam_desc}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_desc}`}
          />
        </Col>}
        {step === 1 && <Col span={6}>
          <Form.Item 
            valuePropName="img"
            name="img"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.img}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.book_img}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="picture-card"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              onChange={handleImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#292C33'}}>{props.i18n.upload}</div>
              </div>
            </Upload>
          </Form.Item>
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            name="part_total"
            type="number"
            required
            title={props.i18n.book_count}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_count}`}
          />
        </Col>}
        {step === 2 && <Col span={24}>
          <div
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: props.color.inputTitleColor,
              marginTop: '30px',
            }}>{props.i18n.book_part_setting}</div>
        </Col>}
        {step === 2 && <Col span={24}>
          <div style={{
            width: '100%',
            backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
            borderRadius: '20px',
            height: '70px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.book_part_content}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'center'}}>{props.i18n.operation}</div>
          </div>

          <Form.List name="chapters" >
            {(fields, { add, remove }) => (
              <div>
                <div className="scrollList" style={{ maxHeight: `${props.screenHeight - 412}px`, paddingBottom: '8px' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <div style={{ display: 'flex', width: '100%', padding: '0px 16px 0px 8px' }}>
                      <div style={{ fontSize: '24px', lineHeight: '50px', color: props.color.inputTitleColor, fontWeight: 'bold', marginRight: '12px'}}>{key+1}</div>
                      <FormInput
                        {...restField}
                        name={[name, 'chapter_title']}
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        required
                        style={{ width: '100%'}}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.book_part_content}`}
                      />
                      <FormInputSelect
                        {...restField}
                        name={[name, 'tags']}
                        mode="multiple"
                        type="array"
                        options={props.formTagList}
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        style={{ width: '100% !important', }}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.tag}`}
                        placeholder={`${props.i18n.placeholder}${props.i18n.tag}`}
                      />
                      <FormInputSelect
                        {...restField}
                        name={[name, 'abilities']}
                        options={abilityList}
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        mode="multiple"
                        type="array"
                        style={{ width: '100% !important', }}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.ability}`}
                        placeholder={`${props.i18n.placeholder}${props.i18n.ability}`}
                      />
                      <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center'}}>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#D53838',
                            border: '1px solid #F31616',
                            marginLeft: '8px',
                          }}
                          onClick={() => remove(name)}
                        >
                          <img
                            src={Images.delete}
                            style={{ width: '22px', height: '14px'}}
                            alt={props.i18n.delete}
                          />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                    }}
                  >
                    + {props.i18n.create_btn}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>}
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item style={{ width: '100%', textAlign: 'center'}}>
            {step === 1 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              // onClick={() => setStep(2)}
            >
              {props.i18n.next_step}
            </Button>}
            {step === 2 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              onClick={() => setStep(1)}
            >
              {props.i18n.prev_step}
            </Button>}
            {step === 2 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
            >
              {props.i18n.create_btn}
            </Button>}
            <Button
              loading={isLoading}
              onClick={handleCancel}
              style={{
                height: '50px',
                backgroundColor: '#D53838',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
              }}
            >
            {props.i18n.swal_no}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleCreateBook(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_BOOK', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookCreate);
