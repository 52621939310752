import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Drawer, Row, Col, Form, Pagination, Select } from 'antd';
import Search from "../components/common/Search";
import FormInput from "../components/common/FormInput";
import FormSelect from "../components/common/Select";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import { city, area } from '../utils/location';
import Swal from 'sweetalert2';

import "./Character.less";
import _, { values } from "lodash";

let timer;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
]
const speechesList = [
  {
    id: 'n.',
    name: '名詞',
  },
  {
    id: 'pron.',
    name: '代名詞',
  },
  {
    id: 'v.',
    name: '動詞',
  },
  {
    id: 'adv.',
    name: '副詞',
  },
  {
    id: 'adj.',
    name: '形容詞',
  },
  {
    id: 'prep.',
    name: '介系詞',
  },
  {
    id: 'conj.',
    name: '連接詞',
  },
  {
    id: 'det.',
    name: '限定詞',
  },
  {
    id: 'int.',
    name: '感嘆詞',
  },
  {
    id: 'num',
    name: '數詞',
  },
  {
    id: 'phr.',
    name: '片語',
  },
  {
    id: 'abbr.',
    name: '縮寫',
  },
  {
    id: 'c.',
    name: '可數名詞',
  },
  {
    id: 'u.',
    name: '不可數名詞',
  },
  {
    id: 'pl.',
    name: '複數詞',
  },
  {
    id: 'vt.',
    name: '及物動詞',
  },
  {
    id: 'vi.',
    name: '不及物動詞',
  },
  {
    id: 'aux.',
    name: '助動詞',
  },
  {
    id: 'pt.',
    name: '過去式',
  },
  {
    id: 'pp.',
    name: '過去分詞',
  },
]

const Character = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [htmlText, setHtmlText] = useState('');
  const [mode, setMode] = useState('create');
  const [search, setSearch] = useState('');
  const [tag, setTag] = useState([]);
  const [ability, setAbility] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  
  useEffect(() => {
    let temp = [];
    city.map((item) => {
      temp.push({
        id: item,
        name: item,
      });
    });
    handleGetData();
    props.getTagFormList(1);
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      type: 1,
      search: '',
      page: 1,
      tags: [],
      abilities: [],
      pageCount: 12,
      orderKeys: [],
    };
    if (param.get('search') !== null) {
      setSearch(param.get('search'));
      payload.search = param.get('search');
    }
    if (param.get('page') !== null) {
      payload.page = param.get('page');
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    if (param.get('tag') !== null && param.get('tag') !== '') {
      let temp = param.get('tag').split(',');
      if (temp.length > 0){
        temp.map((item, index) => {
          temp[index] = parseInt(item);
        });
      }
      payload.tags = temp; 
      setTag(temp);
    }
    if (param.get('ability') !== null && param.get('ability') !== '') {
      let tempAbility = param.get('ability').split(',');
      if (tempAbility.length > 0){
        tempAbility.map((item, index) => {
          tempAbility[index] = parseInt(item);
        });
      }
      payload.abilities = tempAbility;
      setAbility(tempAbility);
    }
    props.getKnowledgeList(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);


  const handleShowUpdate = (data) => {
    const { getKnowledgeById } = props;
    const callback = (value) => {
      setMode('edit');
      setHtmlText(value.knowledge_desc);  
      let tempTag = [];
      value.tags.map((item) => {
        tempTag.push(item.tag_id);
      });
      let tempAbility = [];
      value.abilities.map((item) => {
        tempAbility.push(item.ability_id);
      });
      setCurrentData({...value, tags: tempTag, abilities: tempAbility, speeches: value.speeches !== null ?value.speeches: [] });
      setFormValue({...value, tags: tempTag, abilities: tempAbility, speeches: value.speeches !== null ?value.speeches: [] });
      setDrawerVisible(true);
    }
    getKnowledgeById(data.knowledge_id, setIsLoading, callback);
  }
  
  const handleCreate = (value) => {
    const { handleCreateKnowledge, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
    }

    let getData = {
      search: search,
      type: 1,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    handleCreateKnowledge(
      {
        ...value,
        knowledge_type:1, 
        knowledge_desc: htmlText,
        tags: value.tags !== undefined ? value.tags: [],
        speeches: value.speeches !== undefined ? value.speeches: [],
        abilities: value.abilities !== undefined ? value.abilities: [],
      },
      setIsLoading,
      callback,
      getData,
    );
  }
  const handleUpdate = (value) => {
    const { handleUpdateKnowledge, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
    }

    let getData = {
      search: search,
      type: 1,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    }
    let addList = [];
    let removeList = [];
    let tempList = [];

    value.sentences.map((item) => {
      if (_.has(item, 'knowledge_ex_id')) {
        tempList.push(item);
      } else {
        addList.push(item);
      }
    });
    currentData.sentences.map((item) => {
      if (formValue.sentences.filter((child) => child.knowledge_ex_id === item.knowledge_ex_id).length === 0) {
        removeList.push(item.knowledge_ex_id);
      }
    });

    handleUpdateKnowledge(
      {
        ...value,
        knowledge_type: 1, 
        knowledge_id: currentData.knowledge_id,
        removes: removeList,
        sentences: tempList,
        list: addList,
        knowledge_desc: htmlText
      },
      setIsLoading,
      callback,
      getData,
    );
  }
  const handleFinish = (value) => {
    if (mode === 'create') {
      handleCreate(value);
    } else {
      handleUpdate(value);
    }
  }

  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const renderDrawerView = () => {
    if (mode === 'view') {
      return drawerVisible && renderViewDrawer();
    } else {
      return drawerVisible && renderFormDrawer();
    }
  }

  const renderViewDrawer = () => {
    return <div style={{ paddingTop: '30px'}}>
      <span
        style={{
          color: props.color.textColor,
          fontSize: '18px',
          marginLeft: '16px',
          borderBottom: '2px solid #13D1AC',
        }}
      >{currentData.knowledge_title}</span>

      <div style={{ width: '100%', alignItems: 'center', marginLeft: '16px', marginTop: '16px' }}>
        <div
          title={currentData.knowledge_kk}
          style={{
            fontSize: '18px',
            color: props.color.textColor,
          }}>{currentData.knowledge_kk}
        </div>
        <div
          style={{
            fontSize: '18px',
            color: props.color.textColor,
            marginTop: '30px',
          }}
          dangerouslySetInnerHTML={{ __html: currentData.knowledge_desc }}
          >
        </div>
      </div>
      {
        currentData.sentences.map((item) => {
          return <div style={{ marginBottom: '16px', paddingLeft: '16px'}}>
            <div
              style={{
                fontSize: '18px',
                color: props.color.textColor,
              }}>{item.knowledge_ex_sentence}
            </div>
            <div
              style={{
                fontSize: '18px',
                color: props.color.textColor,
              }}>{item.knowledge_ex_translate}
            </div>
          </div>
        })
      }
      <div
        title={currentData.knowledge_kk}
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
          paddingLeft: '16px'
        }}>{props.i18n.tag}
      </div>
      <div style={{ paddingLeft: '16px', display: 'flex', alignItems: 'center', marginTop: '16px', flexWrap: 'wrap', width: '100%'}}>
        {
          currentData.tags.map((item) => {
            return <div 
            style={{ 
              height: '40px', 
              lineHeight: '40px',
              padding: '0px 12px',
              backgroundColor: '#FCF1DF',
              color: '#242933',
              marginRight: '16px',
              borderRadius: '15px',
              textAlign: 'center'
             }}>
              {item.tag_name}
            </div>
          })
        }
      </div>
      <div
        title={currentData.knowledge_kk}
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: props.color.inputTitleColor,
          paddingLeft: '16px',
          marginTop: '30px',
        }}>{props.i18n.ability}
      </div>
      <div style={{ paddingLeft: '16px', display: 'flex', alignItems: 'center', marginTop: '16px', flexWrap: 'wrap', width: '100%'}}>
        {
          currentData.abilities.map((item) => {
            return <div 
            style={{ 
              height: '40px', 
              lineHeight: '40px',
              padding: '0px 12px',
              backgroundColor: '#FCF1DF',
              color: '#242933',
              marginRight: '16px',
              borderRadius: '15px',
              textAlign: 'center'
             }}>
              {item.ability_name}
            </div>
          })
        }
      </div>
    </div>
  }

  const handleChangeContent = (value) => {
    setHtmlText(value.toHTML());
  }

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 1,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }

  const renderFormDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
      >
      <Row gutter={24} className="scrollList" style={{ height: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={12}>
          <FormInput
            name="knowledge_title"
            required
            title={props.i18n.character_name}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_name}`}
          />
        </Col>
       <Col span={12}>  
          <FormInput
            name="knowledge_kk"
            title={props.i18n.character_kk }
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_kk }`}
          />
        </Col>
        <Col>
          <HtmlEditor
            title="說明"
            i18nMode={props.i18nMode}
            propName="knowledge_desc"
            value={htmlText}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
       <Col span={24}>
          <FormInputSelect
            name="speeches"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={speechesList}
            allowClear={true}
            title={props.i18n.character_speeches}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_speeches}`}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
        <Col
          span={24}
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: props.color.inputTitleColor,
            marginBottom: '12px',
          }}>
          <div
            style={{ marginLeft: '16px' }}
          >{props.i18n.character_example_title}</div>
        </Col>
        <Col span={24}>
          <div style={{
            width: '100%',
            backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            height: '70px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.character_example}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'center'}}>{props.i18n.operation}</div>
          </div>
          <Form.List name="sentences" >
            {(fields, { add, remove }) => (
              <div>
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <div 
                      style={{ 
                        display: 'flex',
                        width: '100%',
                        padding: '24px 16px 24px 16px', 
                        borderBottom: '1px solid #E3FBEE',
                        backgroundColor: 'rgba(204,255,228,0.23)',
                      }}
                    >
                      <div style={{ width: '100%'}}>
                        <FormInput
                          {...restField}
                          title=""
                          formItemStyle={{ marginBottom: '0px'}}
                          name={[name, 'knowledge_ex_sentence']}
                          required
                          requiredMessage={props.i18n.required}
                          style={{ width: '100% !important'}}
                          textColor={props.color.inputTitleColor}
                          placeholder={`${props.i18n.placeholder}${props.i18n.character_en_example}`}
                        />
                        <FormInput
                          {...restField}
                          title=""
                          required
                          requiredMessage={props.i18n.required}
                          name={[name, 'knowledge_ex_translate']}
                          formItemStyle={{ marginBottom: '0px'}}
                          style={{ width: '100% !important', marginTop: '16px'}}
                          textColor={props.color.inputTitleColor}
                          placeholder={`${props.i18n.placeholder}${props.i18n.character_zh_example}`}
                        />
                      </div>
                      <div style={{ minWidth: '60px', display: 'flex', alignItems: 'center'}}>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#D53838',
                            border: '1px solid #F31616',
                            marginLeft: '8px',
                          }}
                          onClick={() => remove(name)}
                        >
                          <img
                            src={Images.delete}
                            style={{ width: '22px', height: '14px'}}
                            alt={props.i18n.delete}
                          />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <Form.Item style={{ marginTop: '24px'}}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                    }}
                  >
                    + {props.i18n.create_btn}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }
  

  const handleDeleteKnowledge = (id) => {
    const { handleDeleteKnowledge, paging } = props;

    let getData = {
      search: search,
      type: 1,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteKnowledge(id, setIsLoading, () => {}, getData);
      }
    });
  }


  const handleSizeChange = (e) => {
    navigate(`/knowledge/character?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}&page=1${e !== 12 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/knowledge/character?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/knowledge/character?search=${e}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }


  const handleShowView = (data) => {
    const { getKnowledgeById } = props;
    const callback = (value) => {
      setMode('view');
      setCurrentData(value);
      setDrawerVisible(true);
    }
    getKnowledgeById(data.knowledge_id, setIsLoading, callback);
  }

  const renderDrawerTitle = () => {
    if (mode === 'create') {
      return props.i18n.character_create;
    } else if (mode === 'update') {
      return props.i18n.character_update;
    }  else if (mode === 'view') {
      return props.i18n.character_view;
    }
  }

  const handleTagChange = (e) => {
    setTag(e);
    navigate(`/knowledge/character?search=${search}${e !== '' ? `&tag=${e}`: ''}${ability.length > 0 ? `&ability=${ability}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const handleAbilityChange = (e) => {
    setAbility(e);
    navigate(`/knowledge/character?search=${search}${e !== '' ? `&ability=${e}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  return <div id="character">
    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            mode="multiple"
            placeholder={`${props.i18n.filter}${props.i18n.tag}`}
            options={props.formTagList}
            value={tag}
            onChange={(e) => handleTagChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            placeholder={`${props.i18n.filter}${props.i18n.ability}`}
            mode="multiple"
            options={abilityList}
            value={ability}
            onChange={(e) => handleAbilityChange(e)}
          />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px',
            marginRight: '24px'
          }}
        >
        {props.i18n.import_btn}
      </Button>
        <Button
          onClick={() => {
            setDrawerVisible(true);
            setMode('create');
            setFormValue({});
            setCurrentData({});
            setHtmlText('');
          }}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 273}px`, marginRight: '0px !important', paddingBottom: '8px' }}>
      {
        props.knowledgeList.map((item) => {
          return <Col span={8} style={{
            // height: '225px',
            width: '100%',
            marginTop: '30px'
          }}>
            <div
              style={{
                width: '100%',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                backdropFilter: 'blur(30px) brightness(1.5)',
              }}
            >
              <div style={{ display: 'flex', padding: '24px 24px 0px 24px' }}>
                <div style={{ width: '100%', display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                      <div
                        title={item.knowledge_title}
                        style={{
                          fontSize: '24px',
                          color: props.color.currentTheme === 'dark' ? 'white' :'#242933',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>{item.knowledge_title}</div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '22px' }}>
                      <div
                        title={item.knowledge_kk}
                        style={{
                          fontSize: '18px',
                          color: props.color.currentTheme === 'dark' ? 'white' :'#242933',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>{item.knowledge_kk}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#85D1C2', margin: '4px 0px' }}></div>
              <div style={{padding: '8px 24px 8px 24px'}}>
                <div
                  style={{
                    fontSize: '16px',
                    color: props.color.textColor,
                    marginBottom: '12px',
                    height: '26px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }} dangerouslySetInnerHTML={{ __html: item.knowledge_desc }}></div>
                <div style={{ height: '52px'}}>
                  {item.sentences.map((child, childIndex) => {
                    return childIndex < 1 && <div style={{ fontSize: '16px', color: props.color.textColor }}>
                      <div> ※ {child.knowledge_ex_sentence}</div>
                      <div style={{ paddingLeft: '18px'}}> {child.knowledge_ex_translate}</div>
                    </div>
                  })}
                </div>
              </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#85D1C2', margin: '4px 0px' }}></div>
              <div style={{
                height: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 24px',
                backgroundColor: '#DCCA8D',
                alignItems: 'center',
                borderBottomLeftRadius: '30px',
                borderBottomRightRadius: '30px',
              }}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden'}}>
                  {
                    item.tags.map((child, index) => {
                      return index < 3 && <div 
                        style={{ 
                          fontSize: '16px', 
                          padding: '0px 12px', 
                          backgroundColor: '#FCF1DF', 
                          marginRight: '12px',
                          color: '#242933',
                          height: '40px',
                          lineHeight: '40px',
                          textAlign: 'center',
                          borderRadius: '15px',
                        }}
                        >
                        {child.tag_name}
                        </div>
                    })
                  }
                  {item.tags.length > 3 && <div style={{
                    width: '36px',
                    height: '40px',
                    lineHeight: '32px',
                    borderRadius: '15px',
                    backgroundColor: '#FCF1DF', 
                    color: '#242933',
                    textAlign: 'center'
                  }}>
                    ...
                  </div>}
                </div>
                <div style={{ display: 'flex'}}>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleShowView(item)}
                  >
                    <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleShowUpdate(item)}
                  >
                    <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#D53838',
                      border: `1px solid #F31616`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDeleteKnowledge(item.knowledge_id)}
                  >
                    <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
                  </Button>
                </div>
              </div>
            </div>

          </Col>
        })
      }
    </Row>

    <div className="pagination" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
      <Pagination
        defaultCurrent={props.paging.page}
        total={props.paging.total}
        current={props.paging.page}
        pageSize={props.paging.pageCount}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
      <Select
        id="selectList"
        style={{ width: '112px', marginLeft: '16px'}}
        defaultValue={props.paging.pageCount}
        value={props.paging.pageCount}
        onChange={handleSizeChange}
      >
        {
          sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {props.i18n.page}</Select.Option>)
        }
      </Select>
    </div>
    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={mode === 'view' ? true :false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{renderDrawerTitle()}</div>
      </div>
      {renderDrawerView()}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    knowledgeList: _.get(state, 'Knowledge.knowledgeList', []),
    paging: _.get(state, 'Knowledge.paging', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateKnowledge(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CREATE_KNOWLEDGE', payload, loading, callback, getData});
    },
    handleUpdateKnowledge(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_UPDATE_KNOWLEDGE', payload, loading, callback, getData});
    },
    handleDeleteKnowledge(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_KNOWLEDGE', payload, loading, callback, getData});
    },
    getKnowledgeList(payload, loading, callback) {
      dispatch({ type: 'GET_KNOWLEDGE_LIST', payload, loading, callback});
    },
    getKnowledgeById(payload, loading, callback) {
      dispatch({ type: 'GET_KNOWLEDGE_BY_ID', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Character);
