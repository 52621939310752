import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Pagination, Select } from 'antd';
import Search from "../components/common/Search";
import FormSelect from "../components/common/Select";
import Images from "../themes/Images";
import Swal from 'sweetalert2';


import "./Exam.less";
import _ from "lodash";

let timer;
const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
]

const Exam = (props) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tag, setTag] = useState([]);
  const [ability, setAbility] = useState([]);

  useEffect(() => {
    handleGetData();
    props.getTagFormList(7);
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: '',
      page: 1,
      tags: [],
      abilities: [],
      pageCount: 12,
      orderKeys: [],
    };
    if (param.get('search') !== null) {
      setSearch(param.get('search'));
      payload.search = param.get('search');
    }
    if (param.get('page') !== null) {
      payload.page = param.get('page');
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    if (param.get('tag') !== null && param.get('tag') !== '') {
      let temp = param.get('tag').split(',');
      if (temp.length > 0){
        temp.map((item, index) => {
          temp[index] = parseInt(item);
        });
      }
      payload.tags = temp; 
      setTag(temp);
    }
    if (param.get('ability') !== null && param.get('ability') !== '') {
      let tempAbility = param.get('ability').split(',');
      if (tempAbility.length > 0){
        tempAbility.map((item, index) => {
          tempAbility[index] = parseInt(item);
        });
      }
      payload.abilities = tempAbility;
      setAbility(tempAbility);
    }
    props.getExamList(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleDeleteExam = (id) => {
    const { handleDeleteExam, paging } = props;

    let getData = {
      search: search,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteExam(id, setIsLoading, () => {}, getData);
      }
    });
  }


  const handleSizeChange = (e) => {
    navigate(`/exam?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}&page=1${e !== 12 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/exam?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/exam?search=${e}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }

  const handleTagChange = (e) => {
    setTag(e);
    navigate(`/exam?search=${search}${e !== '' ? `&tag=${e}`: ''}${ability.length > 0 ? `&ability=${ability}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const handleAbilityChange = (e) => {
    setAbility(e);
    navigate(`/exam?search=${search}${e !== '' ? `&ability=${e}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const handleCopyExam = (id) => {
    Swal.fire({
      title: props.i18n.copy_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        const callback = (data) => {
          navigate(`/exam/update/${data.exam_id}`)
        }
        props.handleCopyExam(id, setIsLoading, callback);
      }
    });
  }
 
  return <div id="character">
    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            mode="multiple"
            placeholder={`${props.i18n.filter}${props.i18n.tag}`}
            options={props.formTagList}
            value={tag}
            onChange={(e) => handleTagChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            placeholder={`${props.i18n.filter}${props.i18n.ability}`}
            mode="multiple"
            options={abilityList}
            value={ability}
            onChange={(e) => handleAbilityChange(e)}
          />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={() => navigate('/exam/create')}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 273}px`, marginRight: '0px !important', paddingBottom: '8px' }}>
      {
        props.examList.map((item) => {
          return <Col span={8} style={{
            // height: '225px',
            width: '100%',
            marginTop: '30px'
          }}>
            <div
              style={{
                width: '100%',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                backdropFilter: 'blur(30px) brightness(1.5)',
              }}
            >
              <div style={{ display: 'flex', padding: '24px 24px 0px 24px' }}>
                <div style={{ width: '100%', display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div
                        title={item.topic_no}
                        style={{
                          fontSize: '24px',
                          color: props.color.currentTheme === 'dark' ? 'white' :'#242933',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          fontWeight: 'bold',
                        }}>{item.exam_title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#85D1C2', margin: '4px 0px' }}></div>
              <div style={{
                height: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 24px',
                backgroundColor: '#ACF8EF',
                alignItems: 'center',
                borderBottomLeftRadius: '30px',
                borderBottomRightRadius: '30px',
              }}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden'}}>
                  {
                    item.tags.map((child, index) => {
                      return index < 2 && <div 
                        style={{ 
                          fontSize: '16px', 
                          padding: '0px 12px', 
                          backgroundColor: '#FCF1DF', 
                          marginRight: '12px',
                          color: '#242933',
                          height: '40px',
                          lineHeight: '40px',
                          textAlign: 'center',
                          borderRadius: '15px',
                        }}
                        >
                        {child.tag_name}
                        </div>
                    })
                  }
                  {item.tags.length > 2 && <div style={{
                    width: '36px',
                    height: '40px',
                    lineHeight: '32px',
                    borderRadius: '15px',
                    backgroundColor: '#FCF1DF', 
                    color: '#242933',
                    textAlign: 'center'
                  }}>
                    ...
                  </div>}
                </div>
                <div style={{ display: 'flex'}}>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleCopyExam(item.exam_id)}
                  >
                    <img alt="copy" src={Images.copy} style={{ width: '22px', height: '14px'}} />
                  </Button>
                  {/* <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    // onClick={() => handleShowView(item)}
                  >
                    <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                  </Button> */}
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/exam/update/${item.exam_id}`)}
                  >
                    <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#D53838',
                      border: `1px solid #F31616`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDeleteExam(item.exam_id)}
                  >
                    <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
                  </Button>
                </div>
              </div>
            </div>

          </Col>
        })
      }
    </Row>

    <div className="pagination" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
      <Pagination
        defaultCurrent={props.paging.page}
        total={props.paging.total}
        current={props.paging.page}
        pageSize={props.paging.pageCount}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
      <Select
        id="selectList"
        style={{ width: '112px', marginLeft: '16px'}}
        defaultValue={props.paging.pageCount}
        value={props.paging.pageCount}
        onChange={handleSizeChange}
      >
        {
          sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {props.i18n.page}</Select.Option>)
        }
      </Select>
    </div>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    examList: _.get(state, 'Exam.examList', []),
    paging: _.get(state, 'Exam.paging', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_LIST', payload, loading, callback});
    },
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    handleCopyExam(payload, loading, callback) {
      dispatch({ type: 'HANDLE_COPY_EXAM', payload, loading, callback});
    },
    getExamById(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
