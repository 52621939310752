import { combineReducers } from "redux";
import Global from "./Global";
import Example from "./Example";
import Member from "./Member";
import Tag from "./Tag";
import School from "./School";
import User from "./User";
import Knowledge from "./Knowledge";
import TopicGroup from "./TopicGroup";
import TopicManager from "./TopicManager";
import Exam from "./Exam";
import Book from "./Book";
import Course from "./Course";
import Talk from "./Talk";
import Lottery from "./Lottery";
import Statistics from "./Statistics";
import Review from "./Review";
import Download from './Download';
import News from './News';


export default combineReducers({
  Global,
  Example,
  Member,
  Tag,
  School,
  User,
  Knowledge,
  TopicGroup,
  TopicManager,
  Exam,
  Book,
  Course,
  Talk,
  Lottery,
  Statistics,
  Review,
  Download,
  News,
});
