const Reducer = (state = {
  lotteryList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  lotteryDetail: {
    awards: [],
  },
  lotteryLogList: [],
  logPaging: {
    page: 1,
    pageCount: 10,
  },
}, { type, payload }) => {
  switch (type) {
    case "GET_LOTTERY_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.activity_id,
        });
      })
      return { ...state, lotteryList: temp, paging: payload.paging };
    case "GET_LOTTERY_LOG_SUCCESS":
      return { ...state, lotteryLogList: payload.results, logPaging: payload.paging };
    case "GET_LOTTERY_BY_ID_SUCCESS":
      return { ...state, lotteryDetail: payload };
    default:
      return state;
  }
};

export default Reducer;
