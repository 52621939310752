import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Drawer, Switch, Row, Col, Form } from 'antd';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import FormInput from "../components/common/FormInput";
import FormTextarea from "../components/common/FormTextarea";
import Search from "../components/common/Search";
import DataTable from "../components/common/DataTable";
import HtmlEditor from "../components/common/HtmlEditor";
import "./News.less";
import Images from "../themes/Images";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const News = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [search, setSearch] = useState('');
  const [sorts, setSorts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formMode, setFormMode] = useState('create');
  const [formValue, setFormValue] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      "search": null,
      "page": 1,
      "pageCount": 10,
      "orderKeys":[]
    };
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    props.getNewsList(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleSortChange = (e) => {
    setSorts(e);
    handleGetData();
  }

  const handleSearchChange = (e) => {
    setSearch(e);
  }

  const handlePageChange = (e) => {
    navigate(`/news?${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSubmit = (value) => {
    let payload = { ...value };
    payload.news_content = '';
    if (!_.isNil(value.news_content)) {
      if (_.isObject(value.news_content)) {
        payload.news_content = value.news_content.toHTML();
      } else {
        payload.news_content = value.news_content;
      }
    }
    const callback = () => {
      setFormMode('create');
      setFormValue({});
      setDrawerVisible(false);
    }
    const getData = {
      "search": null,
      "page": 1,
      "pageCount": 10,
      "orderKeys":[]
    };
    if (formMode === 'create') {
      props.handleCreateNews(payload, props.saveLoading, callback, getData);
    } else {
      payload['news_id'] = formValue.news_id;
      props.handleUpdateNews(payload, props.saveLoading, callback, getData);
    }
  }

  const handleChangeSwitch = (check, key, data) => {
    const getData = {
      "search": search,
      "page": props.paging.page,
      "pageCount": 10,
      "orderKeys":[]
    };
    if (key === 'open') {
      props.handleChangeNewsOpen(data.news_id, props.saveLoading, () => {}, getData);
    } else {
      props.handleChangeNewsTop(data.news_id, props.saveLoading, () => {}, getData);
    }
    
  }

  const handleViewNewsDetail = (id) => {
    const callback = (data) => {
      setFormMode('update');
      setFormValue(data);
      setDrawerVisible(true);
    }
    props.getNewsById(id, props.saveLoading, callback);
  }

  const handleGetNewsDetail = (id) => {
    const callback = (data) => {
      setFormMode('update');
      setFormValue(data);
      setDrawerVisible(true);
    }
    props.getNewsById(id, props.saveLoading, callback);
  }
 
  const columns = [
    {
      title: props.i18n.news_title,
      dataIndex: 'news_title',
      key: 'news_title',
    },
    {
      title: props.i18n.news_open,
      dataIndex: 'is_open',
      key: 'is_open',
      render: (value, record) => <Switch
        checked={value}
        checkedChildren="上架中"
        unCheckedChildren="下架中"
        onChange={(e) => handleChangeSwitch(e, 'open', record)}
      />
    },
    {
      title: props.i18n.news_top,
      dataIndex: 'is_top',
      key: 'is_top',
      render: (value, record) => <Switch
        checked={value}
        checkedChildren="已置頂"
        unCheckedChildren="未置頂"
        onChange={(e) => handleChangeSwitch(e, 'top', record)}
      />
    },
    {
      title: props.i18n.operation,
      dataIndex: 'update_time',
      key: 'update_time',
      width: '100px',
      align: 'center',
      render: (value, record) => <div style={{ display: 'flex'}}>
        <Button
          style={{
            width: '40px',
            height: '40px',
            backgroundColor: '#42C1B4',
            border: `1px solid #C1EFD6`,
            borderRadius: '15px',
            marginLeft: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleViewNewsDetail(record.news_id)}
        >
          <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
        </Button>
        <Button
          style={{
            width: '40px',
            height: '40px',
            backgroundColor: '#42C1B4',
            border: `1px solid #C1EFD6`,
            borderRadius: '15px',
            marginLeft: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleGetNewsDetail(record.news_id)}
        >
          <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
        </Button>
      </div>
    },
  ];

  return <div id="statistics">

    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={() => {
            setFormMode('create')
            setDrawerVisible(true);
          }}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <DataTable
      column={columns}
      data={props.newsList}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={false}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      showSearch={false}
      searchValue={search}
      hasPagination={true}
      showChangePageSize={false}
      paging={props.paging}
      isLoading={isLoading}
      hasFrontCustomChildren={false}
      scrollHeight={props.screenHeight - 350}
      handleSortChange={handleSortChange}
      handlePageChange={handlePageChange}
    />

    <Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.download_create_title}</div>
      </div>
      {drawerVisible && <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          name: '',
          ...formValue,
        }}
        style={{ marginTop: '16px',}}
        onFinish={handleSubmit}
      >
        <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 194}px`, marginRight: '0px', paddingBottom: '8px' }}>
          <Col span={24}>
            <FormInput
              name="news_title"
              required
              title={props.i18n.news_title}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.news_title}`}
            />
          </Col>
          <Col span={24}>
            <HtmlEditor
              title={props.i18n.news_content}
              i18nMode={props.i18nMode}
              value={formValue.news_content}
              propName="news_content"
              textColor={props.color.inputTitleColor}
              onEditorStateChange={() => {}}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>{props.i18n.news_open}</div>}
              name="is_open"
              valuePropName="checked"
            >
              <Switch 
                checkedChildren={props.i18n.news_open_check}
                unCheckedChildren={props.i18n.news_open_un_check}
              >
              </Switch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>{props.i18n.news_top}</div>}
              name="is_top"
              valuePropName="checked"
            >
              <Switch 
                checkedChildren={props.i18n.news_top_check}
                unCheckedChildren={props.i18n.news_top_un_check}
              >
              </Switch>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
          <Button
            loading={props.loading}
            htmlType="submit"
            style={{
              height: '50px',
              backgroundColor: '#4A9E8F',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              marginRight: '30px'
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={props.loading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: '50px',
              backgroundColor: '#D53838',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
            }}
          >
            
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>}
    </Drawer>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    newsList: _.get(state, 'News.newsList', []),
    paging: _.get(state, 'News.paging', {}),
    examFormList: _.get(state, 'Exam.examFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewsList(payload, loading, callback) {
      dispatch({ type: 'GET_NEWS_LIST', payload, loading, callback});
    },
    getNewsById(payload, loading, callback) {
      dispatch({ type: 'GET_NEWS_BY_ID', payload, loading, callback});
    },
    handleCreateNews(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CREATE_NEWS', payload, loading, callback, getData});
    },
    handleUpdateNews(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_UPDATE_NEWS', payload, loading, callback, getData});
    },
    handleDeleteNews(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_NEWS', payload, loading, callback, getData});
    },
    handleChangeNewsOpen(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CHANGE_NEWS_OPEN', payload, loading, callback, getData});
    },
    handleChangeNewsTop(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CHANGE_NEWS_TOP', payload, loading, callback, getData});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
