/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Lottery,
  CREATE_Lottery,
  UPDATE_Lottery,
  DELETE_Lottery,
  GET_LotteryById,
  GET_LotteryLog,
  POST_ExportLog,
} from "../services/Lottery";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getLotteryList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Lottery, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_LOTTERY_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getLotteryById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_LotteryById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_LOTTERY_BY_ID_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateLottery({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Lottery, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* handleUpdateLottery({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Lottery, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteLottery({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Lottery, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_LOTTERY_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* getLotteryLog({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_LotteryLog, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_LOTTERY_LOG_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleExportLotteryLog({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_ExportLog, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      window.open(res.result);
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}
export default function* Example() {
  yield takeLatest("GET_LOTTERY_LIST", getLotteryList);
  yield takeLatest("GET_LOTTERY_LOG", getLotteryLog);
  yield takeLatest("GET_LOTTERY_BY_ID", getLotteryById);
  yield takeLatest("HANDLE_CREATE_LOTTERY", handleCreateLottery);
  yield takeLatest("HANDLE_UPDATE_LOTTERY", handleUpdateLottery);
  yield takeLatest("HANDLE_DELETE_LOTTERY", handleDeleteLottery);
  yield takeLatest("HANDLE_EXPORT_LOTTERY_LOG", handleExportLotteryLog);
}
