import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Upload } from 'antd';
import FormInput from "../components/common/FormInput";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDatePicker from "../components/common/FormDatePicker";
import Images from "../themes/Images";
import Swal from 'sweetalert2';
import "./LotteryCreate.less";
import _ from "lodash";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const LotteryCreate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    chapters: [],
    is_sell: false,
    course_desc: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    props.getSchoolListByForm({}, setIsLoading);
  }, []);
  
 
  const handleCancel = () => {
    Swal.fire({
      title: props.i18n.update_cancel,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        navigate('/lottery');
      }
    });
  }
  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleFinish = (value) => {
    let formData = new FormData();
    formData.append("act_title", formValue.act_title);
    formData.append("act_desc", _.isObject(formValue.act_desc) ? formValue.act_desc.toHTML() : formValue.act_desc);
    
    if (!_.isEmpty(formValue.start)) {
      formData.append("start", formValue.start.format('YYYY-MM-DDTHH:mm:ss'));
    }
    if (!_.isEmpty(formValue.end)) {
      formData.append("end", formValue.end.format('YYYY-MM-DDTHH:mm:ss'));
    }
    formData.append('act_mode', value.act_mode ? 1: 0);
    formData.append('act_pr_mode', value.act_pr_mode ? 1: 0);
    formData.append('is_repeat', value.is_repeat ? value.is_repeat : false);
    if (_.isArray(value.schools)) {
      value.schools.map((item) => {
        formData.append('schools', item);
      });
    }
    value.awards.map((item, index) => {
      formData.append(`awards[${index}].award_name`, item.award_name);
      formData.append(`awards[${index}].award_count`, item.award_count);
      formData.append(`awards[${index}].award_pr`, item.award_pr);
      formData.append(`awards[${index}].start`, item.start.format('YYYY-MM-DDTHH:mm:ss'));
      formData.append(`awards[${index}].is_real`, item.is_real ?item.is_real: false);;
      formData.append(`awards[${index}].is_have`, item.is_have ?item.is_have: false);;
      formData.append(`awards[${index}].is_collect`, item.is_collect ? item.is_collect : false);
      if (item.is_collect) {
        let temp = item.collection_text.split(',');
        temp.map((child) => {
          formData.append(`awards[${index}].collections`, child);
        });
      }
    })
    const callback = (value) => {
      navigate(`/lottery/update/${value.act_id}`);
    }
    props.handleCreateLottery(formData, setIsLoading, callback);
  }


  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px'}} className="scrollList">
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          awards: [],
        }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
    >
      <Row gutter={24} style={{ marginRight: '0px'}}>
        <Col span={24}>
          <FormInput
            title={props.i18n.lottery_act_title}
            name="act_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_act_title}`}
          />
        </Col>
        <Col span={24}>
          <HtmlEditor
            title={props.i18n.lottery_act_desc}
            i18nMode={props.i18nMode}
            propName="act_desc"
            // value={formValue.topic_title}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={8}>
          <FormDatePicker
            title={props.i18n.lottery_start_time}
            name="start"
            showTime={true}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_start_time}`}
          />
        </Col>
        <Col span={8}>
          <FormDatePicker
            title={props.i18n.lottery_end_time}
            name="end"
            showTime={true}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_end_time}`}
          />
        </Col>
        <Col span={8} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>{props.i18n.lottery_act_mode}</div>}
            name="act_mode"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={8}>
          <FormInputSelect
            name="schools"
            mode="multiple"
            type="array"
            required
            options={props.schoolFormList}
            allowClear={true}
            title={props.i18n.lottery_act_school}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_act_school}`}
          />
        </Col>
        <Col span={8} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>{props.i18n.lottery_repeat}</div>}
            name="is_repeat"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={8} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>{props.i18n.lottery_mode}</div>}
            name="act_pr_mode"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={24} style={{ borderBottom: `2px solid ${props.color.inputTitleColor}`, marginBottom: '16px'}}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginBottom: '12px',
              marginLeft: '24px',
            }}>獎項資料</div>
        </Col>
        <Col span={24}>
          <Form.List name="awards" >
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={24}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center'}}>
                      <div
                        style={{
                          color: props.color.inputTitleColor,
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginLeft: '24px',
                          marginRight: '60px'
                        }}>獎項{name+1}</div>
                      
                      <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center'}}>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#D53838',
                            border: '1px solid #F31616',
                            marginLeft: '8px',
                          }}
                          onClick={() => remove(name)}
                        >
                          <img
                            src={Images.delete}
                            style={{ width: '22px', height: '14px'}}
                            alt={props.i18n.delete}
                          />
                        </Button>
                      </div>
                    </Col>
                    <Col span={6}>
                      <FormInput
                        title={props.i18n.lottery_award_name}
                        {...restField}
                        required
                        name={[name, 'award_name']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.lottery_award_name}`}
                      />
                    </Col>
                    <Col span={6}>
                      <FormInput
                        title={props.i18n.lottery_award_count}
                        {...restField}
                        required
                        name={[name, 'award_count']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.lottery_award_count}`}
                      />
                    </Col>
                    <Col span={6}>
                      <FormInput
                        title={props.i18n.lottery_award_probability}
                        {...restField}
                        required
                        name={[name, 'award_pr']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.lottery_award_probability}`}
                      />
                    </Col>
                    <Col span={6}>
                      <FormDatePicker
                        showTime={true}
                        title={props.i18n.lottery_award_start}
                        {...restField}
                        required
                        name={[name, 'start']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.lottery_award_start}`}
                      />
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        label={<div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: '18px',
                            fontWeight: 'bold'
                          }}>{props.i18n.lottery_award_real}</div>}
                        name={[name, 'is_real']}
                        valuePropName="checked"
                      >
                        <Switch></Switch>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        label={<div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: '18px',
                            fontWeight: 'bold'
                          }}>{props.i18n.lottery_award_isHave}</div>}
                        name={[name, 'is_have']}
                        valuePropName="checked"
                      >
                        <Switch></Switch>
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ display: 'flex', alignItems: 'flex-end'}}>
                      <Form.Item
                        {...restField}
                        label={<div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: '18px',
                            fontWeight: 'bold',
                            minWidth: '220px'
                          }}>{props.i18n.lottery_award_isCollect}</div>}
                        name={[name, 'is_collect']}
                        valuePropName="checked"
                      >
                        <Switch></Switch>
                      </Form.Item>
                      <FormInput
                        {...restField}
                        name={[name, 'collection_text']}
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.lottery_award_collect_text}`}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                    }}
                  >
                    + {props.i18n.create_btn}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item style={{ width: '100%', textAlign: 'center'}}>
            <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
            >
              {props.i18n.create_btn}
            </Button>
            <Button
              loading={isLoading}
              onClick={handleCancel}
              style={{
                height: '50px',
                backgroundColor: '#D53838',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
              }}
            >
            {props.i18n.swal_no}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    schoolFormList: _.get(state, 'School.schoolFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateLottery(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_LOTTERY', payload, loading, callback});
    },
    getSchoolListByForm(payload, loading, callback) {
      dispatch({ type: 'GET_SCHOOL_LIST_BY_FORM', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryCreate);
