/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  GET_ReviewList,
  GET_GetExamLogDetail,
  PUT_UpdateReview,
} from "../services/Review";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getReviewList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ReviewList, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_REVIEW_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleGetExamLogDetail({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_GetExamLogDetail, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateReview({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_UpdateReview, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success})
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_REVIEW_LIST", getReviewList);
  yield takeLatest("HANDLE_GET_EXAM_LOG_DETAIL", handleGetExamLogDetail);
  yield takeLatest("HANDLE_UPDATE_REVIEW", handleUpdateReview);
}
