/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Book,
  GET_BookForm,
  CREATE_Book,
  UPDATE_Book,
  DELETE_Book,
  DELETE_Part,
  UPDATE_BookSell,
  UPDATE_BookTopic,
  DELETE_Chapter,
  CREATE_Chapter,
  GET_BookById,
  GET_BookPartTopic,
  GET_BookByForm, 
  GET_BookTypeByForm,
  GET_BookTypeList,
  GET_BookChapterById,
  PUT_UpdateChapter,
} from "../services/Book";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getBookList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Book, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_BOOK_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getBookFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_BOOK_FORM_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getBookListByForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_BOOK_LIST_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getBookById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_BOOK_BY_ID_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateBook({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Book, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* handleChangeBookSell({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_BookSell, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateBook({ payload, callback, loading, id }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Book, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_BOOK_BY_ID', payload: id });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateBookTopic({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_BookTopic, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getBookPartTopic({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookPartTopic, token, payload);
    if (res.isSuccess) {
      yield put({ type: 'GET_BOOK_TOPIC_SUCCESS', payload: res.result });
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateChapter({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Chapter, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_BOOK_BY_ID', payload: payload.book_id });
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteChapter({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Chapter, token, payload.chapter_id);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_BOOK_BY_ID', payload: payload.book_id });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteBook({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Book, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_BOOK_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeletePart({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Part, token, payload.part_id);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_BOOK_BY_ID', payload: payload.book_id });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getBookTypeFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookTypeByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_BOOK_TYPE_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* getBookTypeList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookTypeList, token, {});
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_BOOK_TYPE_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* getBookChapterById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_BookChapterById, token, payload);
    if (res.isSuccess) {
      // if (callback) { callback(res.result) };
      yield callback(res.result);
      yield put({ type: 'GET_BOOK_CHAPTER_BY_ID_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
      if (loading) loading(false);
  }
}

function* handleUpdateChapter({ payload, callback, loading, callData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_UpdateChapter, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success });
      yield put({ type: 'GET_BOOK_CHAPTER_BY_ID', payload: {id: callData.chapter_id, mode: -1, is_front: false, page: -1, pageCount: 10000}, loading: loading, callback: callback });
    }
  } catch (error) {
    yield handleError(error);
    if (loading) loading(false);
  }
}

export default function* Book() {
  yield takeLatest("GET_BOOK_LIST", getBookList);
  yield takeLatest("GET_BOOK_FORM_LIST", getBookFormList);
  yield takeLatest("GET_BOOK_PART_TOPIC", getBookPartTopic);
  yield takeLatest("GET_BOOK_BY_ID", getBookById);
  yield takeLatest("HANDLE_CREATE_BOOK", handleCreateBook);
  yield takeLatest("HANDLE_CHANGE_BOOK_SELL", handleChangeBookSell);
  yield takeLatest("HANDLE_UPDATE_BOOK", handleUpdateBook);
  yield takeLatest("HANDLE_UPDATE_BOOK_TOPIC", handleUpdateBookTopic);
  // yield takeLatest("HANDLE_SORT_PART", handleSortPart);
  yield takeLatest("HANDLE_DELETE_BOOK", handleDeleteBook);
  yield takeLatest("HANDLE_CREATE_CHAPTER", handleCreateChapter);
  yield takeLatest("HANDLE_DELETE_CHAPTER", handleDeleteChapter);
  yield takeLatest("HANDLE_DELETE_PART", handleDeletePart);
  yield takeLatest("GET_BOOK_LIST_BY_FORM", getBookListByForm);
  yield takeLatest("GET_BOOK_TYPE_FORM_LIST", getBookTypeFormList);
  yield takeLatest("GET_BOOK_TYPE_LIST", getBookTypeList);
  yield takeLatest("GET_BOOK_CHAPTER_BY_ID", getBookChapterById);
  yield takeLatest("HANDLE_UPDATE_CHAPTER", handleUpdateChapter);
}
