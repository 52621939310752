import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, Form } from 'antd';
import './FormDropdownSelect.less';
import _ from "lodash";
import { connect } from "react-redux";

const FormDropdownSelect = (props = { required: false, rules: [], type: 'text', ...props }) => {
  const [name, setName] = useState('');

  return (
    <Form.Item
      label={
        <div
          style={{
            color: props.textColor,
            fontSize: '18px',
            padding: props.required ? '0px' :'0px 16px',
            fontWeight: 'bold'
          }}>{props.title}
        </div>
      }
      name={props.name}
      colon={false}
      rules={[
        {
          required: props.required,
          message: `${props.i18n.placeholder}${props.title}`,
        },
        {
          type: props.type,
          message: props.i18n.typeError, 
        }
      ]}
      className={props.mode === 'multiple' ? "multipleFormSelectList": "formSelectList"}
    >
      <Select
        allowClear={props.allowClear}
        showSearch
        mode={props.mode}
        maxTagCount="responsive"
        placeholder={props.placeholder}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder={props.i18n.add_item_placeholder}
                value={name}
                style={{ width: '300px'}}
                onChange={(e) => setName(e.target.value)}
              />
              <Button 
                type="text" icon={<PlusOutlined />} 
                onClick={() => props.handleAddItem(name, () => setName(''))}
              >
                {props.i18n.add_item}
              </Button>
            </Space>
          </>
        )}
      >
      {
        props.options.map((item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
      }
      </Select>
    </Form.Item>
  );

}


const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDropdownSelect);
