/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Member,
  GET_MemberForm,
  CREATE_Member,
  UPDATE_Member,
  UPDATE_MemberAccount,
  DELETE_Member,
  GET_MemberById,
} from "../services/Member";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getMemberList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Member, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_MEMBER_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getTeacherFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_MemberForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_TEACHER_FORM_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getStudentFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_MemberForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_STUDENT_FORM_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getMemberById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_MemberById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Member, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_MEMBER_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Member, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_MEMBER_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    } 
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateAccount({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_MemberAccount, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_MEMBER_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    } 
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Member, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_MEMBER_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_MEMBER_LIST", getMemberList);
  yield takeLatest("GET_TEACHER_FORM_LIST", getTeacherFormList);
  yield takeLatest("GET_STUDENT_FORM_LIST", getStudentFormList);
  yield takeLatest("GET_MEMBER_BY_ID", getMemberById);
  yield takeLatest("HANDLE_CREATE_MEMBER", handleCreateMember);
  yield takeLatest("HANDLE_UPDATE_MEMBER", handleUpdateMember);
  yield takeLatest("HANDLE_UPDATE_ACCOUNT", handleUpdateAccount);
  yield takeLatest("HANDLE_DELETE_MEMBER", handleDeleteMember);
}
