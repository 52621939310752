/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  POST_Forget,
  POST_Login,
  PUT_PasswordChange,
  GET_SchoolPermission,
  GET_AllPermission,
  PUT_SchoolPermission,
  GET_RoleList,
  GET_RoleByPermission,
} from "../services/User";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";

function* handleLogin({ payload, loading, callback }) {
  try {
    if (loading) loading(true);
    const res = yield call(POST_Login, payload);
    if (res.isSuccess) {
      if (callback) {callback()}
      window.localStorage.setItem("DTS_DASHBOARD_LOGIN", true);
      window.localStorage.setItem("DTS_DASHBOARD_USER", JSON.stringify(res.result));
      setTimeout(() => {
        window.location.reload();
      },400);
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleLogout({ payload }) {
  window.localStorage.setItem("DTS_DASHBOARD_LOGIN", false);
  window.location.reload();
}

function* handleForget({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const res = yield call(POST_Forget, payload);
    if (res.isSuccess) {
      if (callback) {callback()}
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleChangeNewPassword({ payload, callback, loading }) {
  try {
    if (loading) loading(true);

    const res = yield call(PUT_PasswordChange, payload);
    if (res.isSuccess) {
      if (callback) {callback()}
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getSchoolPermission({ payload, callback, loading }) {
  try {
    if (loading) loading(true);
    const token = yield select((state) => state.User.token);

    const res = yield call(GET_SchoolPermission, token, payload);
    if (res.isSuccess) {
      let temp = [];
      res.results.map((item) => {
        temp.push(item.permission_id);
      })
      if (callback) { callback(temp) }
      yield put({ type: 'GET_SCHOOL_PERMISSION_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getAllPermission({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);

    const res = yield call(GET_AllPermission, token,);
    if (res.isSuccess) {
      if (callback) { callback() }
      yield put({ type: 'GET_ALL_PERMISSION_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdatePermission({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);

    const res = yield call(PUT_SchoolPermission, token,);
    if (res.isSuccess) {
      if (callback) { callback() }
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getRoleList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);

    const res = yield call(GET_RoleList, token,);
    if (res.isSuccess) {
      if (callback) { callback() }
      yield put({ type: 'GET_ROLE_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getRoleByPermission({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);

    const res = yield call(GET_RoleByPermission, token,);
    if (res.isSuccess) {
      if (callback) { callback() }
      yield put({ type: 'GET_ROLE_BY_PERMISSION_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("HANDLE_LOGIN", handleLogin);
  yield takeLatest("HANDLE_LOGOUT", handleLogout);
  yield takeLatest("HANDLE_FORGET", handleForget);
  yield takeLatest("GET_ROLE_LIST", getRoleList);
  yield takeLatest("GET_ROLE_BY_PERMISSION", getRoleByPermission);
  yield takeLatest("HANDLE_CHANGE_NEW_PASSWORD", handleChangeNewPassword);
  yield takeLatest("GET_SCHOOL_PERMISSION", getSchoolPermission);
  yield takeLatest("GET_ALL_PERMISSION", getAllPermission);
  yield takeLatest("HANDLE_UPDATE_PERMISSION", handleUpdatePermission);
}
