/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  GET_NewsList,
  GET_NewsById,
  POST_CreateNews,
  PUT_UpdateNews,
  DELETE_DeleteNews,
  PUT_OpenNews,
  PUT_TopNews,
} from "../services/News";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getNewsList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_NewsList, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_NEWS_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getNewsById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_NewsById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_NEWS_DETAIL_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateNews({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_CreateNews, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success })
      yield put({ type: 'GET_NEWS_LIST', payload: getData })
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateNews({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_UpdateNews, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success })
      yield put({ type: 'GET_NEWS_LIST', payload: getData })
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteNews({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_DeleteNews, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.delete_success })
      yield put({ type: 'GET_NEWS_LIST', payload: getData })
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleChangeNewsOpen({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_OpenNews, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success })
      yield put({ type: 'GET_NEWS_LIST', payload: getData })
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleChangeNewsTop({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_TopNews, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.update_success })
      yield put({ type: 'GET_NEWS_LIST', payload: getData })
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_NEWS_LIST", getNewsList);
  yield takeLatest("GET_NEWS_BY_ID", getNewsById);
  yield takeLatest("HANDLE_CREATE_NEWS", handleCreateNews);
  yield takeLatest("HANDLE_UPDATE_NEWS", handleUpdateNews);
  yield takeLatest("HANDLE_DELETE_NEWS", handleDeleteNews);
  yield takeLatest("HANDLE_CHANGE_NEWS_OPEN", handleChangeNewsOpen);
  yield takeLatest("HANDLE_CHANGE_NEWS_TOP", handleChangeNewsTop);
}
