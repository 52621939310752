import request from "../utils/request";

export function GET_DownloadList(token, payload) {
  return request.post("/download/all", payload, {
    headers: {
      Authorization: token,
    },
  });
}

export function GET_DownloadById(token, payload) {
  return request.get(`/download?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}

export function CREATE_Download(token, payload) {
  return request.post("/download", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function PUT_Download(token, payload) {
  return request.put("/download", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function DELETE_Download(token, payload) {
  return request.delete(`/download?id=${payload}`, {}, {
    headers: {
      Authorization: token,
    },
  });
}
