import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Drawer } from 'antd';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";


import FormSelect from "../components/common/Select";
import CommonInput from "../components/common/CommonInput";
import CommonTextarea from "../components/common/CommonTextarea";
import FormInput from "../components/common/FormInput";
import Search from "../components/common/Search";
import DataTable from "../components/common/DataTable";
import "./Review.less";
import Images from "../themes/Images";

const Review = (props) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [exam, setExam] = useState(undefined);
  const [sorts, setSorts] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      "is_front": false,
      "is_big": null,
      "course_id": null,
      "exam_id": null,
      "is_checked": null,
      "search": null,
      "page": 1,
      "pageCount": 10,
      "orderKeys":[
        {
          orderKey: 'end',
          order: -1
        }]
    };
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    if (param.get('exam_id') !== null) {
      setExam(param.get('exam_id'));
      payload.exam_id = param.get('exam_id');
    }
    props.getReviewList(payload, setIsLoading,);
    props.getExamFormList();
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleSortChange = (e) => {
    setSorts(e);
    handleGetData();
  }

  const handleExamChange = (e) => {
    setExam(e);
    navigate(`/review?page=1&${e !== undefined ? `exam_id=${e}`: ''}`)
  }
  const handleSearchChange = (e) => {
    setSearch(e);
  }
  const handlePageChange = (e) => {
    navigate(`/review?${e !== 1 ? `&page=${e}`: ''}`)
  }
  const handleGetExamLogDetail = (id) => {
    const callback = (value) => {
      let tempTopic = [];
      value.parts.map((item) => {
        if (_.isArray(item.topics)) {
          item.topics.map((child) => {
            tempTopic.push(child);
          });
        }
        if (_.isArray(item.part_items)) {
          item.part_items.map((partItem) => {
            if (_.isArray(partItem.topics)) {
              partItem.topics.map((topicItem) => {
                tempTopic.push(topicItem);
              })
            }
          });
        }
      });
      setTopicList(tempTopic);
      setDrawerVisible(true)
    }
    setCurrentId(id);
    props.handleGetExamLogDetail(id, setIsLoading, callback);
  }

  const handleSendData = () => {
    let payload = {
      exam_log_id: currentId,
      list: [],
    };
    topicList.map((item) => {
      payload.list.push({
        part_topic_log_id: item.part_topic_log_id,
        is_correct: parseFloat(item.score) === parseFloat(item.got_score),
        is_done: true,
        score: parseFloat(item.got_score),
        check_remark: item.check_remark,
      });
    });
    const callback = () => {
      setDrawerVisible(false);
      setTopicList([]);
      handleGetData();
      setCurrentId('');
    }
    props.handleUpdateReview(payload, setIsLoading, callback);
  }

  const handleChangeTopicList = (value, index, key) => {
    let temp = JSON.parse(JSON.stringify(topicList));
    temp[index][key] = value;
    setTopicList(temp);
  }
 
  const columns = [
    {
      title: props.i18n.review_exam_name,
      dataIndex: 'exam_title',
      key: 'exam_title',
    },
    {
      title: props.i18n.review_member_name,
      dataIndex: 'member_name',
      key: 'member_name',
    },
    {
      title: props.i18n.review_score,
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: props.i18n.review_last_updated,
      dataIndex: 'update_time',
      key: 'update_time',
      render: (value, record) => <div>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</div>
    },
    {
      title: props.i18n.operation,
      dataIndex: 'update_time',
      key: 'update_time',
      width: '100px',
      align: 'center',
      render: (value, record) => <div>
        <Button
          style={{
            width: '40px',
            height: '40px',
            backgroundColor: '#42C1B4',
            border: `1px solid #C1EFD6`,
            borderRadius: '15px',
            marginLeft: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => handleGetExamLogDetail(record.exam_log_id)}
        >
          <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
        </Button>
      </div>
    },
  ];
  return <div id="statistics">
    <div>
      <Search searchValue={search} handleSearch={handleSearchChange} />
      <FormSelect
        allowClear={true}
        showSearch
        style={{ marginLeft: '24px', width: '254px'}}
        mode="text"
        placeholder={`${props.i18n.filter}${props.i18n.exam}`}
        options={props.examFormList}
        value={exam}
        onChange={(e) => handleExamChange(e)}
      />
    </div>
    <DataTable
      column={columns}
      data={props.reviewList}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={false}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      showSearch={false}
      searchValue={search}
      hasPagination={true}
      showChangePageSize={false}
      paging={props.paging}
      isLoading={isLoading}
      hasFrontCustomChildren={false}
      scrollHeight={props.screenHeight - 350}
      handleSortChange={handleSortChange}
      handlePageChange={handlePageChange}
    />

    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.review_score}</div>
      </div>
      {
        topicList.map((item, index) => {
          return <div key={item.part_topic_log_id}>
            <div
              style={{
                fontSize: '22px',
                fontWeight: 'bold',
                color: props.color.inputDrawerTitleColor,
              }}>
              第{index+1}題
            </div>
            <div
              style={{
                fontSize: '18px',
                color: props.color.inputDrawerTitleColor,
              }}>
              {props.i18n.review_topic_title}
            </div>
            <div
              style={{ width: '100%' }}
              className="braft-output-content"
              dangerouslySetInnerHTML={{ __html: item.topic_title }}>
            </div>
            <div
              style={{
                fontSize: '18px',
                color: props.color.inputDrawerTitleColor,
                marginTop: '16px',
              }}>
              {props.i18n.review_topic_answer}
            </div>
            <div
              style={{
                fontSize: '16px',
              }}
              dangerouslySetInnerHTML={{__html: item.answer }}
            >
            </div>
            <div
              style={{
                fontSize: '18px',
                color: props.color.inputDrawerTitleColor,
                marginTop: '16px',
              }}>
              {props.i18n.review_score}(總分：{item.score})
            </div>
            <div>
              <CommonInput
                title=""
                type="number"
                value={item.got_score}
                onChange={(e) => handleChangeTopicList(e.target.value, index, 'got_score')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.review_score}`}
              />
            </div>
            <div
              style={{
                fontSize: '18px',
                color: props.color.inputDrawerTitleColor,
                marginTop: '16px',
              }}>
              {props.i18n.review_check_remark}
            </div>
            <div style={{ marginBottom: '50px'}}>
              <CommonTextarea
                title=""
                type="number"
                rowHeight={4}
                value={item.check_remark}
                onChange={(e) => handleChangeTopicList(e.target.value, index, 'check_remark')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.review_check_remark}`}
              />
            </div>
          </div>
        })
      }
      <div>
        <Button
          loading={isLoading}
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
          onClick={handleSendData}
        >
          {props.i18n.send_btn}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </div>

    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    reviewList: _.get(state, 'Review.reviewList', []),
    paging: _.get(state, 'Review.paging', {}),
    examFormList: _.get(state, 'Exam.examFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewList(payload, loading, callback) {
      dispatch({ type: 'GET_REVIEW_LIST', payload, loading, callback});
    },
    handleGetExamLogDetail(payload, loading, callback) {
      dispatch({ type: 'HANDLE_GET_EXAM_LOG_DETAIL', payload, loading, callback});
    },
    getExamFormList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_FORM_LIST', payload, loading, callback});
    },
    handleUpdateReview(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_REVIEW', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
