/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Course,
  CREATE_Course,
  UPDATE_Course,
  DELETE_Course,
  GET_CourseById,
  POST_ImportCourseMember,
  GET_CourseMember,
  GET_ExportCourseBuyMember,
  POST_PageTopicLog,
  GET_CourseForm,
  POST_ExportPageTopicLog,
} from "../services/Course";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getCourseList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Course, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_COURSE_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getCourseFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_CourseForm, token);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_COURSE_FORM_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getCourseMember({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_CourseMember, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.results) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getCourseById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_CourseById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_COURSE_BY_ID_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateCourse({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Course, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* handleUpdateCourse({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Course, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteCourse({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Course, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_COURSE_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleImportCourseMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_ImportCourseMember, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* exportCourseBuyMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const i18n = yield select((state) => state.Global.i18n);
    showMessage({ type:'warning',content: i18n.download_loading})
    const res = yield call(GET_ExportCourseBuyMember, token, payload);
    window.open(res.result);
    showMessage({ type:'success',content: i18n.download_success})
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getPageTopicLog({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_PageTopicLog, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_PAGE_TOPIC_LOG_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleExportPageTopicLog({ payload, callback, loading,  }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_ExportPageTopicLog, token, payload);
    if (res.isSuccess) {
      window.open(res.result);
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


export default function* Course() {
  yield takeLatest("GET_COURSE_LIST", getCourseList);
  yield takeLatest("GET_COURSE_FORM_LIST", getCourseFormList);
  yield takeLatest("GET_COURSE_MEMBER", getCourseMember);
  yield takeLatest("GET_COURSE_BY_ID", getCourseById);
  yield takeLatest("HANDLE_CREATE_COURSE", handleCreateCourse);
  yield takeLatest("HANDLE_UPDATE_COURSE", handleUpdateCourse);
  yield takeLatest("HANDLE_DELETE_COURSE", handleDeleteCourse);
  yield takeLatest("HANDLE_IMPORT_COURSE_MEMBER", handleImportCourseMember);
  yield takeLatest("EXPORT_COURSE_BUY_MEMBER", exportCourseBuyMember);
  yield takeLatest("GET_PAGE_TOPIC_LOG", getPageTopicLog);
  yield takeLatest("HANDLE_EXPORT_PAGE_TOPIC_LOG", handleExportPageTopicLog);
}
