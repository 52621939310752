import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Drawer, Row, Col, Form, Pagination, Select, Switch } from 'antd';
import Search from "../components/common/Search";
import FormInput from "../components/common/FormInput";
import FormSelect from "../components/common/Select";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import Swal from 'sweetalert2';

import "./Talk.less";
import _ from "lodash";

let timer;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]
const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
]

const Talk = (props) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [tag, setTag] = useState([]);
  const [ability, setAbility] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [mode, setMode] = useState('create');
  
  useEffect(() => {
    handleGetData();
    props.getTagFormList(11);
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = { 
      search: '',
      page: 1,
      is_front: false,
      tags: [],
      abilities: [],
      pageCount: 12,
      orderKeys: [],
    };
    if (param.get('search') !== null) {
      setSearch(param.get('search'));
      payload.search = param.get('search');
    }
    if (param.get('page') !== null) {
      payload.page = param.get('page');
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    if (param.get('tag') !== null && param.get('tag') !== '') {
      let temp = param.get('tag').split(',');
      if (temp.length > 0){
        temp.map((item, index) => {
          temp[index] = parseInt(item);
        });
      }
      payload.tags = temp; 
      setTag(temp);
    }
    if (param.get('ability') !== null && param.get('ability') !== '') {
      let tempAbility = param.get('ability').split(',');
      if (tempAbility.length > 0){
        tempAbility.map((item, index) => {
          tempAbility[index] = parseInt(item);
        });
      }
      payload.abilities = tempAbility;
      setAbility(tempAbility);
    }
    props.getTalkList(payload, setIsLoading,);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleDeleteTalk = (id) => {
    const { handleDeleteTalk, paging } = props;

    let getData = {
      search: search,
      tags: tag,
      is_front: false,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteTalk(id, setIsLoading, () => {}, getData);
      }
    });
  }


  const handleSizeChange = (e) => {
    navigate(`/knowledge/talk?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}&page=1${e !== 12 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/knowledge/talk?search=${search}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 1 ? `&page=${e}`: ''}`)
  }

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/knowledge/talk?search=${e}${ability.length > 0 ? `&ability=${ability}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }

  const handleTagChange = (e) => {
    setTag(e);
    navigate(`/knowledge/talk?search=${search}${e !== '' ? `&tag=${e}`: ''}${ability.length > 0 ? `&ability=${ability}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const handleAbilityChange = (e) => {
    setAbility(e);
    navigate(`/knowledge/talk?search=${search}${e !== '' ? `&ability=${e}`: ''}${tag.length > 0 ? `&tag=${tag}`: ''}${props.paging.pageCount !== 12 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const handleCreate = (value) => {
    const { handleCreateTalk } = props;
    const callback = (data) => {
      setDrawerVisible(false);
      navigate(`/knowledge/talk/update/${data.talk_id}`);
    }

    handleCreateTalk(value,setIsLoading,callback);
  }

  const renderFormDrawer = () => {
    return <Form
        {...layout}
        name="basic"
        style={{ marginTop: '16px' }}
        onFinish={handleCreate}
      >
      <Row gutter={24}>
        <Col span={24}>
          <FormInput
            name="talk_title"
            required
            title={props.i18n.talk_title}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.talk_title}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }
  

  const handleChangeTalkSell = (id) => {
    const callback = () => {
      handleGetData();
    }
    props.handleChangeTalkSell(id, setIsLoading, callback);
  }

  return <div id="character">
    <Row gutter={24}>
      <Col span={16} style={{ display: 'flex', alignItems: 'center'}}>
        <Search searchValue={search} handleSearch={handleSearchChange} />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            mode="multiple"
            placeholder={`${props.i18n.filter}${props.i18n.tag}`}
            options={props.formTagList}
            value={tag}
            onChange={(e) => handleTagChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: '24px', width: '254px'}}
            placeholder={`${props.i18n.filter}${props.i18n.ability}`}
            mode="multiple"
            options={abilityList}
            value={ability}
            onChange={(e) => handleAbilityChange(e)}
          />
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={() => setDrawerVisible(true)}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.create_btn}
        </Button>
      </Col>
    </Row>
    <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 273}px`, marginRight: '0px !important', paddingBottom: '8px' }}>
      {
        props.talkList.map((item) => {
          return <Col key={item.talk_id} span={8} style={{
            // height: '225px',
            width: '100%',
            marginTop: '30px'
          }}>
            <div
              style={{
                width: '100%',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                backdropFilter: 'blur(30px) brightness(1.5)',
              }}
            >
              <div style={{ padding: '24px 24px 0px 24px' }}>
                <div
                  title={item.talk_title}
                  style={{
                    fontSize: '24px',
                    color: props.color.currentTheme === 'dark' ? 'white' :'#242933',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>{item.talk_title}</div>
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Switch checked={item.is_sell} onChange={() => handleChangeTalkSell(item.talk_id)} style={{ marginRight: '12px'}}></Switch><span style={{ color: props.color.textColor}}>{item.is_sell ?  '上架中': '已下架'}</span>
                  </div>
              </div>
              <div style={{ width: '100%', height: '1px', backgroundColor: '#85D1C2', margin: '4px 0px' }}></div>
              <div style={{
                height: '64px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 24px',
                backgroundColor: '#DEA7A7',
                alignItems: 'center',
                borderBottomLeftRadius: '30px',
                borderBottomRightRadius: '30px',
              }}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden'}}>
                  {
                    _.isArray(item.tags) && item.tags.map((child, index) => {
                      return index < 3 && <div 
                        style={{ 
                          fontSize: '16px', 
                          padding: '0px 12px', 
                          backgroundColor: '#FCF1DF', 
                          marginRight: '12px',
                          color: '#242933',
                          height: '40px',
                          lineHeight: '40px',
                          textAlign: 'center',
                          borderRadius: '15px',
                        }}
                        >
                        {child.tag_name}
                        </div>
                    })
                  }
                  {_.isArray(item.tags) && item.tags.length > 3 && <div style={{
                    width: '36px',
                    height: '40px',
                    lineHeight: '32px',
                    borderRadius: '15px',
                    backgroundColor: '#FCF1DF', 
                    color: '#242933',
                    textAlign: 'center'
                  }}>
                    ...
                  </div>}
                </div>
                <div style={{ display: 'flex'}}>
                  {/* <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/knowledge/talk/view/${item.talk_id}`)}
                  >
                    <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} />
                  </Button> */}
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(`/knowledge/talk/update/${item.talk_id}`)}
                  >
                    <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                  </Button>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#D53838',
                      border: `1px solid #F31616`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDeleteTalk(item.talk_id)}
                  >
                    <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
                  </Button>
                </div>
              </div>
            </div>

          </Col>
        })
      }
    </Row>

    <div className="pagination" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
      <Pagination
        defaultCurrent={props.paging.page}
        total={props.paging.total}
        current={props.paging.page}
        pageSize={props.paging.pageCount}
        showSizeChanger={false}
        onChange={handlePageChange}
      />
      <Select
        id="selectList"
        style={{ width: '112px', marginLeft: '16px'}}
        defaultValue={props.paging.pageCount}
        value={props.paging.pageCount}
        onChange={handleSizeChange}
      >
        {
          sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {props.i18n.page}</Select.Option>)
        }
      </Select>
    </div>

    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={mode === 'view' ? true :false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.talk_create}</div>
      </div>
      {drawerVisible && renderFormDrawer()}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    talkList: _.get(state, 'Talk.talkList', []),
    paging: _.get(state, 'Talk.paging', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteTalk(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_TALK', payload, loading, callback, getData});
    },
    getTalkList(payload, loading, callback) {
      dispatch({ type: 'GET_TALK_LIST', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTalk(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TALK', payload, loading, callback});
    },
    handleChangeTalkSell(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CHANGE_TALK_SELL', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Talk);
