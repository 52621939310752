import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const spinner = (props) => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <PropagateLoader color={props.mode === 'dark' ? '#00FFCE' : '#7BC3C0'} size={24} loading={true} />
    </div>
  );

}

export default spinner;
