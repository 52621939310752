import request from "../utils/request";

export function CREATE_TopicGroup(token, payload) {
  return request.post("/topicGroup", payload, {
    headers: {Authorization: token },
  });
}

export function UPDATE_TopicGroup(token, payload) {
  return request.put("/topicGroup", payload, {
    headers: {Authorization: token },
  });
}

export function GET_TopicGroup(token, payload) {
  return request.post(`/topicGroup/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_TopicGroupById(token, payload) {
  return request.get(`/topicGroup?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_TopicGroup(token, payload) {
  return request.delete(`/topicGroup?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TopicGroupByForm(token,) {
  return request.get(`/topicGroup/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TopicTypeByForm(token,) {
  return request.get(`/topictype/form`, {}, {
    headers: {Authorization: token },
  });
}