import request from "../utils/request";

export function GET_Example(token, payload) {
  return request.get("/Example", payload, {
    headers: { Authorization: token },
  });
}

export function POST_Example(token, payload) {
  return request.post("/Example", payload, {
    headers: { Authorization: token },
  });
}

export function PUT_Example(token, payload) {
  return request.put("/Example", payload, {
    headers: { Authorization: token },
  });
}

export function PATCH_Example(token, payload) {
  return request.patch("/Example", payload, {
    headers: { Authorization: token },
  });
}

export function DELETE_Example(token, payload) {
  return request.delete("/Example", payload, {
    headers: { Authorization: token },
  });
}
