const Reducer = (state = {
  topicList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  topicFormList: [],
  topicTypeFormList: [],
  topicTypeList: [],
}, { type, payload }) => {
  switch (type) {
    case "GET_TOPIC_LIST_SUCCESS":
      let temp = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.topic__id,
        });
      })
      return { ...state, topicList: temp, paging: payload.paging };
    case "GET_TOPIC_LIST_BY_FORM_SUCCESS":
      let tempSchool = [];
      payload.results.map((item) => {
        tempSchool.push({
          id: item.school_id,
          name: item.school_name,
        });
      });
      return { ...state, topicFormList: tempSchool };
    case "GET_TOPIC_TYPE_BY_FORM_SUCCESS":
      let tempType = [];
      payload.results.map((item) => {
        tempType.push({
          id: item.topic_type_id,
          name: item.topic_type_name,
        });
      });
      return { ...state, topicTypeFormList: tempType };
    case "GET_TOPIC_TYPE_LIST_SUCCESS":
      let tempTopicType = [];
      payload.map((item) => {
        tempTopicType.push({
          id: item.topic_type_id,
          // name: `${item.topic_type_name}(${(item.topic_type_desc !== null && item.topic_type_desc !== '') ?item.topic_type_desc: ''})`,
          name: item.topic_type_name,
          ...item,
        });
      });
      return { ...state, topicTypeList: tempTopicType };
    default:
      return state;
  }
};

export default Reducer;
