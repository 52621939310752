import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Drawer, Form, Upload } from 'antd';
import Images from "../themes/Images";
import DataTable from "../components/common/DataTable";
import Swal from 'sweetalert2';
import "./CourseView.less";
import _ from "lodash";
import moment from "moment";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const CourseView = (props) => {
  let navigate = useNavigate();
  const [courseId, setCourseId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('import');
  const [showList, setShowList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const columns = [
    {
      title: '帳號',
      dataIndex: 'member_account',
      key: 'member_account',
      width: '160px',
    },
    {
      title: '姓名',
      dataIndex: 'member_name',
      key: 'member_name',
      width: '160px',
    },
    {
      title: '班級',
      dataIndex: 'grade_name',
      key: 'grade_name',
      width: '160px',
      align: 'center',
    },
  ];
  const pageTopicLogColumns = [
    {
      title: '教科書名稱',
      dataIndex: 'book_title',
      key: 'book_title',
    },
    {
      title: '標題',
      dataIndex: 'page_title',
      key: 'page_title',
    },
    {
      title: '帳號',
      dataIndex: 'member_account',
      key: 'member_account',
    },
    {
      title: '姓名',
      dataIndex: 'member_name',
      key: 'member_name',
    },
    {
      title: '答對率',
      dataIndex: 'correct_rate',
      key: 'correct_rate',
      render: (value) => <div>{parseInt(value)}%</div>
    },
    {
      title: '練習時間',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (value) => <div>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</div>
    },
  ];

  useEffect(() => {
    let id = window.location.pathname.split('/course/view/')[1];
    const callback = (value) => {
    }
    props.getCourseById(id, () => {}, callback);
    props.getPageTopicLog({
      course_id: id,
      start: null,
      end: null,
      search: null,
      page:1, 
      pageCount: 10,
      orderKeys: [],
    });
  }, []);
  
  const handleExportBuyMember = (item) => {
    let id = window.location.pathname.split('/course/view/')[1];
    props.exportCourseBuyMember(id, props.saveLoading);
  }

  const handleImport = () => {
    let id = window.location.pathname.split('/course/view/')[1];
    setCourseId(id);
    setMode('import');
    setDrawerVisible(true);
  }

  const handleImportMember = (value) => {
    if (_.isArray(value.file) && value.file.length > 0) {
      let formData = new FormData();
      formData.append('id', courseId);
      formData.append('file', value.file[0].originFileObj);
      const callback = () => {
        setDrawerVisible(false);
      }
      props.handleImportCourseMember(formData, props.saveLoading, callback);
    } 
  }

  const handlePageChange = (e) => {
    let id = window.location.pathname.split('/course/view/')[1];
    props.getPageTopicLog({
      course_id: id,
      start: null,
      end: null,
      search: null,
      page:e, 
      pageCount: 10,
      orderKeys: [],
    },);

  }

  const handleExportPageTopicLog = () => {
    let id = window.location.pathname.split('/course/view/')[1];
    props.handleExportPageTopicLog({
      course_id: id,
      start: null,
      end: null,
      search: null,
      page: 1, 
      pageCount: 10,
      orderKeys: [],
    }, setIsLoading);

  }

  const handleShowMember = (item) => {

    let id = window.location.pathname.split('/course/view/')[1];
    const callback = (data) => {
      setShowList(data);
      setCourseId(id);
      setMode('showMember');
      setDrawerVisible(true);
    }
    props.getCourseMember(id, props.saveLoading, callback);
  }

  return <div style={{ marginRight: '16px', paddingBottom: '8px' }}>
    <Row>

      <Col lg={18} xxl={20}>
        <div style={{ fontSize: '24px', color: '#242933', fontWeight: 'bold' }}>{props.courseDetail.course_title}</div>
        <div style={{ fontSize: '20px', color: '#242933' }}>指導老師：{props.courseDetail.teacher_name}</div>
        <div style={{ display: 'flex'}}>
          {
            props.courseDetail.tags.map((child, index) => {
              return <div 
                style={{ 
                  fontSize: '16px', 
                  padding: '0px 12px', 
                  backgroundColor: '#FCF1DF', 
                  marginRight: '12px',
                  color: '#242933',
                  height: '40px',
                  lineHeight: '40px',
                  textAlign: 'center',
                  borderRadius: '15px',
                }}
                >
                {child.tag_name}
                </div>
            })
          }
          {
            props.courseDetail.abilities.map((child, index) => {
              return <div 
                style={{ 
                  fontSize: '16px', 
                  padding: '0px 12px', 
                  backgroundColor: '#FCD9BF', 
                  marginRight: '12px',
                  color: '#242933',
                  height: '40px',
                  lineHeight: '40px',
                  textAlign: 'center',
                  borderRadius: '15px',
                }}
                >
                {child.ability_name}
                </div>
            })
          }
        </div>
      </Col>
      <Col lg={6} xxl={4} style={{ display: 'flex', alignItems: 'flex-end'}}>
      </Col>
      <Col span={24} style={{ display: props.screenWidth >= 700 ? 'flex': 'block', marginTop: '30px' }}>
        {_.isArray(props.courseDetail.imgs) && <img
          alt="img"
          src={props.courseDetail.imgs[0].file_url}
          style={{ maxWidth: props.screenWidth >= 700 ? '350px' : '500px', width: '100%', height: '214px', objectFit: 'cover', borderRadius: '15px' }}
        />}
        <div style={{ width: '100%', marginLeft: '16px'}}>
          {(props.courseDetail.course_desc !==null ) && <div style={{ color: '#242933', fontSize: '18px'}} dangerouslySetInnerHTML={{__html: props.courseDetail.course_desc}}></div>}
        </div>
      </Col>
      <Col span={24} style={{ marginTop: '16px'}}>
        <div style={{ display: 'flex'}}>
          <Button
            style={{
              // width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
            onClick={() => handleExportBuyMember()}
            // onClick={() => navigate(`/course/view/${item.course_id}`)}
          >
            {/* <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} /> */}
            購買名單
          </Button>
          <Button
            style={{
              // width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
            onClick={() => handleShowMember()}
            // onClick={() => navigate(`/course/view/${item.course_id}`)}
          >
            {/* <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} /> */}
            選課人員
          </Button>
          <Button
            style={{
              // width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
            onClick={() => handleImport()}
            // onClick={() => navigate(`/course/view/${item.course_id}`)}
          >
            {/* <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} /> */}
            匯入人員
          </Button>
          <Button
            style={{
              // width: '40px',
              height: '40px',
              backgroundColor: '#42C1B4',
              border: `1px solid #C1EFD6`,
              borderRadius: '15px',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
            loading={isLoading}
            onClick={handleExportPageTopicLog}
            // onClick={() => navigate(`/course/view/${item.course_id}`)}
          >
            {/* <img alt="eye" src={Images.eye_open} style={{ width: '22px', height: '14px'}} /> */}
            匯出練習紀錄
          </Button>
        </div>
      </Col>
      <Col span={24}>
        <DataTable
          column={pageTopicLogColumns}
          data={props.pageTopicLogList}
          color={props.color}
          i18n={props.i18n}
          isRowSelection={false}
          showCreateButton={false}
          showPatchDeleteButton={false}
          showDeleteButton={false}
          openModal={() => {}}
          showSearch={false}
          hasPagination={true}
          showChangePageSize={false}
          paging={props.pageTopicLogPaging}
          // isLoading={isLoading}
          hasFrontCustomChildren={false}
          handleSizeChange={() => {}}
          handlePageChange={handlePageChange}
        />
      </Col>
    </Row>

    <Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{props.i18n.course_import}</div>
      </div>

        {(drawerVisible && mode === 'showMember') &&<DataTable
          column={columns}
          data={showList}
          color={props.color}
          i18n={props.i18n}
          isRowSelection={false}
          showCreateButton={false}
          showPatchDeleteButton={false}
          showDeleteButton={false}
          openModal={() => {}}
          showSearch={false}
          hasPagination={false}
          // isLoading={isLoading}
          hasFrontCustomChildren={false}
          handleSizeChange={() => {}}
          handlePageChange={() => {}}
        />}
      {(drawerVisible && mode === 'import') && <Form
        {...layout}
        name="basic"
        initialValues={{
          name: ''
        }}
        style={{ marginTop: '16px',}}
        onFinish={handleImportMember}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item 
              name="file"
              getValueFromEvent={normFile}
            >
              <Upload 
                accept=".xlsx"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button
                  loading={isLoading}
                  style={{
                    height: '40px',
                    backgroundColor: '#4A9E8F',
                    width: '128px',
                    borderRadius: '20px',
                    color: 'white',
                    border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                    marginRight: '30px'
                  }}
                >
                  {props.i18n.download_create_title}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: '50px',
              backgroundColor: '#4A9E8F',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              marginRight: '30px'
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: '50px',
              backgroundColor: '#D53838',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
            }}
          >
            
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>}
    </Drawer>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    courseDetail: _.get(state, 'Course.courseDetail', {}),
    pageTopicLogList: _.get(state, 'Course.pageTopicLogList', []),
    pageTopicLogPaging: _.get(state, 'Course.pageTopicLogPaging', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCourseById(payload, loading, callback) {
      dispatch({ type: 'GET_COURSE_BY_ID', payload, loading, callback});
    },
    handleImportCourseMember(payload, loading, callback) {
      dispatch({ type: 'HANDLE_IMPORT_COURSE_MEMBER', payload, loading, callback});
    },
    getCourseMember(payload, loading, callback) {
      dispatch({ type: 'GET_COURSE_MEMBER', payload, loading, callback});
    },
    exportCourseBuyMember(payload, loading) {
      dispatch({ type: 'EXPORT_COURSE_BUY_MEMBER', payload, loading});
    },
    getPageTopicLog(payload, loading, callback) {
      dispatch({ type: 'GET_PAGE_TOPIC_LOG', payload, loading, callback});
    },
    handleExportPageTopicLog(payload, loading, callback) {
      dispatch({ type: 'HANDLE_EXPORT_PAGE_TOPIC_LOG', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseView);
