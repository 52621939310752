import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Drawer, Row, Col, Form, Input } from 'antd';
import ReactECharts from 'echarts-for-react';
import Swal from 'sweetalert2';
import DataTable from "../components/common/DataTable";
import Images from "../themes/Images";

import "./Tag.less";
import _ from "lodash";
import moment from "moment";

let timer;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tabList = [{
    name: '單字',
    key: 'character',
    id: 1,
  }, {
    name: '字串',
    key: 'string',
    id: 2,
  }, {
    name: '文法',
    key: 'grammar',
    id: 3,
  }, {
    name: '文章',
    key: 'article',
    id: 4,
  }, {
    name: '題目',
    key: 'topic',
    id: 5,
  }, {
    name: '課程',
    key: 'course',
    id: 6,
  }, {
    name: '教科書',
    key: 'book',
    id: 7,
  }, {
    name: '測驗',
    key: 'exam',
    id: 8,
  }, {
    name: '闖關活動',
    key: 'game',
    id: 9,
  }];


const styles = {
  btnStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    width: '40px',
    height: '40px',
    backgroundColor: '#42C1B4',
    border: '1px solid #C1EFD6',
  },
  iconImgStyle: {
    width: '24px',
    height: '24px',
  }
};
const App = (props) => {
  let navigate = useNavigate();
  const eChartRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('create');
  const [search, setSearch] = useState('');
  const [formValue, setFormValue] = useState({tag_name: ''});
  const [sorts, setSorts] = useState([]);
  const [currentData, setCurrentData] = useState({ list: ['123', '1233', '123123', '123134', '43211', '12312']});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState('character');
  const [currentName, setCurrentName] = useState('單字');
  const [tagType, setTagType] = useState(1);
  const [hoverKey, setHoverKey] = useState('');
  const [showEChart, setShowEChart] = useState(false);
  
  
  
  useEffect(() => {
    handleGetData();
    setTimeout(() => {
      setShowEChart(true);
    }, 100);
  }, []);

  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      search: '',
      tagType: 1,
      page: 1,
      pageCount: 10,
      sorts,
    };
    if (param.get('search') !== null) {
      payload.search = param.get('search');
    }
    if (param.get('tagType') !== null) {
      tabList.map((item) => {
        if (item.id === parseInt(param.get('tagType'))) {
          setCurrentKey(item.key);
          setCurrentName(item.name);
          setTagType(item.id);
        }
      });
      payload.tagType = param.get('tagType');
    }
    if (param.get('page') !== null) {
      payload.page = param.get('page');
    }
    if (param.get('pageCount') !== null) {
      payload.pageCount = param.get('pageCount');
    }
    props.getTagList(payload, setIsLoading,);
    props.getTagRank(payload.tagType, setIsLoading);
  }
  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handleSetCurrentKey = (item) => {
    setCurrentKey(item.key);
    setCurrentName(item.name);
    setTagType(item.id);
    navigate(`/tag?tagType=${item.id}${search !== '' ? `&search=${search}`: ''}${props.paging.pageCount !== 1 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
  }

  const option = {
    title: {
      text: ''
    },
    legend: {
      show: false,
      itemHeight: '36px',
      top: '0px'
    },
    tooltip: {
      show: false,
      toolbox: {
        show: false,
      }
    },
    grid: {
      show: false,
      containLabel: true,
      left: '3%',
      right: '18%',
      // height: '558px',
    },
    xAxis: {
      name: '筆數',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#17A0AA',
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
    },
    yAxis: {
      data: props.rankTitle,
      axisLine: {
        show: true,
        lineStyle: {
          color: props.color.eChartTextColor,
          width: '36px'
        },
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
      nameTextStyle: {
        height: '36px'
      }
    },
    textStyle: {
      color: props.color.eChartTextColor,
      fontSize: '16px',
    },
    series: [{
      name: '索引數',
      type: 'bar',
      data: props.rankData,
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
              offset: 0, color: props.color.eChartItemLeft // 0% 处的颜色
          }, {
              offset: 1, color: props.color.eChartItemRight// 100% 处的颜色
          }],
        },
        borderRadius: [0,5,5,0],
        height: 36,
      }
    }]
  };

  const handleShowUpdate = (data) => {
    const { getTagById } = props;
    const callback = (value) => {
      setMode('edit');
      setCurrentData(value);
      setFormValue(value);
      setDrawerVisible(true);
    }
    getTagById(data.tag_id, setIsLoading, callback);
  }

  const handleShowView = (data) => {
    const { getTagById } = props;
    const callback = (value) => {
      setMode('view');
      setCurrentData(value);
      setDrawerVisible(true);
    }
    getTagById(data.tag_id, setIsLoading, callback);
  }

  const handleDeleteTag = (id) => {
    const { handleDeleteTag, paging } = props;

    let getData = {
      search: search,
      tagType: tagType,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        handleDeleteTag([id], setIsLoading, () => {}, getData);
      }
    });
  }
  const columns = [
    {
      title: props.i18n.table_tag_name,
      dataIndex: 'tag_name',
      key: 'tag_name',
    },
    {
      title: props.i18n.table_tag_count,
      dataIndex: 'count',
      key: 'count',
      sort: true,
      align: 'center',
    },
    {
      title: props.i18n.operation,
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 200,
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <Button
            style={styles.btnStyle}
            onClick={() => handleShowView(record)}
          >
            <img
              src={Images.eye_green}
              style={{ width: '22px', height: '14px'}}
              alt={props.i18n.edit}
            />
          </Button>
          <Button
            onClick={() => handleShowUpdate(record)}
            style={{...styles.btnStyle, marginLeft: '8px'}}
          >
            <img
              src={Images.edit}
              style={{ width: '20px', height: '20px'}}
              alt={props.i18n.edit}
            />
          </Button>
          <Button
            style={{
              ...styles.btnStyle,
              backgroundColor: '#D53838',
              border: '1px solid #F31616',
              marginLeft: '8px'
            }}
            onClick={() => handleDeleteTag(record.tag_id)}
          >
            <img
              src={Images.delete}
              style={{ width: '20px', height: '20px'}}
              alt={props.i18n.delete}
            />
          </Button>
        </div>
      ),
    },
  ];

  const renderDrawerView = () => {
    if (mode === 'view') {
      return drawerVisible && renderViewDrawer();
    } else {
      return drawerVisible && renderFormDrawer();
    }
  }

  const renderViewDrawer = () => {
    return <div>
      <div
        style={{
          color: props.color.inputTitleColor,
          fontSize: '18px',
          padding: '0px 16px',
          fontWeight: 'bold',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >{props.i18n.drawer_name}</div>
      <span
        style={{
          color: props.color.inputTitleColor,
          fontSize: '18px',
          marginTop: '16px',
          marginLeft: '16px',
          borderBottom: '2px solid #13D1AC',
        }}
      >{currentData.tag_name}</span>
      <div
        style={{
          color: props.color.inputTitleColor,
          fontSize: '18px',
          padding: '0px 16px',
          fontWeight: 'bold',
          marginTop: '16px',
        }}
      >{props.i18n.drawer_connect}</div>
      <div style={{
        backgroundColor: 'rgba(255,255,255,0.7)',
        width: '100%',
        height: '100%',
        maxHeight: '400px',
        minHeight: '400px',
        borderRadius: '15px',
        padding: '16px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'baseline',
      }}>
        {currentData.relations.map((item) => {
          return <div
            style={{
              height: '30px',
              borderRadius: '20px',
              backgroundColor: 'rgba(21,158,144,0.67)',
              lineHeight: '30px',
              padding: '0px 8px',
              color: 'white',
              marginRight: '8px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              marginBottom: '8px',
            }}>
            {item.relation_name}
            <img src={Images.close_white} alt="close" style={{ width: '14.5px', height: '14.5px', marginLeft: '4px', cursor: 'pointer'}}  />
          </div>
        })}
      </div>
    </div>
  }

  const renderFormDrawer = () => {
    return <Form
        {...layout}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        style={{ marginTop: '16px'}}
        onFinish={value => handleFinish(value)}
      >
        <Form.Item
          label={<div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding: '0px 16px',
              fontWeight: 'bold'
            }}>{props.i18n.drawer_name}</div>}
          name="tag_name"
          colon={false}
        >
          <Input
            autoComplete="off"
            style={{
              borderRadius: '15px',
              height: '45px',
              color: '#125346',
              border: '2px solid #13D1AC',
              padding: '0px 16px',
              backgroundColor: '#FFF',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
              width: '254px',
            }}
            placeholder={`${props.i18n.placeholder}${props.i18n.drawer_name}`}
          />
        </Form.Item>
        {mode === 'update' && <Form.Item
          label={<div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              padding: '0px 16px',
              fontWeight: 'bold'
            }}>{props.i18n.drawer_connect}</div>}
          name="relations"
          colon={false}
        >
          <div style={{
            backgroundColor: 'rgba(255,255,255,0.7)',
            width: '100%',
            height: '100%',
            maxHeight: '400px',
            minHeight: '400px',
            borderRadius: '15px',
            padding: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'baseline',
          }}>
            {currentData.list.map((item) => {
              return <div
                style={{
                  height: '30px',
                  borderRadius: '20px',
                  backgroundColor: 'rgba(21,158,144,0.67)',
                  lineHeight: '30px',
                  padding: '0px 8px',
                  color: 'white',
                  marginRight: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '18px',
                  marginBottom: '8px',
                }}>
                {item}
                <img src={Images.close_white} alt="close" style={{ width: '14.5px', height: '14.5px', marginLeft: '4px', cursor: 'pointer'}}  />
              </div>
            })}
          </div>
        </Form.Item>}
        <Form.Item style={{ marginTop: '30px', width: '100%', textAlign: 'center'}}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: '50px',
              backgroundColor: '#4A9E8F',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
              marginRight: '30px'
            }}
          >
            確定
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              setDrawerVisible(false);
              setFormValue({});
            }}
            style={{
              height: '50px',
              backgroundColor: '#D53838',
              width: '128px',
              borderRadius: '20px',
              color: 'white',
              border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
            }}
          >
            取消
          </Button>
        </Form.Item>
      </Form>
  }
  
  const handleFinish = (value) => {
    if (mode === 'create') {
      handleCreate(value);
    } else {
      handleUpdate(value);
    }
  }

  const handleCreate = (value) => {
    const { handleCreateTag, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }

    let getData = {
      search: search,
      tagType: tagType,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    handleCreateTag(
      { tag_type_id: tagType, tag_name: value.tag_name },
      setIsLoading,
      callback,
      getData,
    );
  }

  const handleUpdate = (value) => {
    const { handleUpdateTag, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }

    let getData = {
      search: search,
      tagType: tagType,
      page: paging.page,
      pageCount: paging.pageCount,
    }

    handleUpdateTag(
      {
        tag_type_id: tagType,
        tag_name: value.tag_name,
        tag_id: currentData.tag_id,
        removes: [],
      },
      setIsLoading,
      callback,
      getData,
    );
  }

  const handleSearch = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(`/tag?tagType=${tagType}${e !== '' ? `&search=${e}`: ''}${props.paging.pageCount !== 1 ? `&pageCount=${props.paging.pageCount}`: ''}&page=1`)
    }
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay)
    }
    debounce(getSearch, 500);
  }
  
  const handleSizeChange = (e) => {
    navigate(`/tag?tagType=${tagType}${search !== '' ? `&search=${search}`: ''}&page=1${e !== 10 ? `&pageCount=${e}`: ''}`)
  }

  const handlePageChange = (e) => {
    navigate(`/tag?tagType=${tagType}${search !== '' ? `&search=${search}`: ''}${props.paging.pageCount !== 1 ? `&pageCount=${props.paging.pageCount}`: ''}${e !== 10 ? `&page=${e}`: ''}`)
  }

  const handleSortChange = (e) => {
    setSorts(e);
    handleGetData();
  }

  const handlePatchDelete = (list, callback) => {
    const { handleDeleteTag } = props;
    let getData = {
      search,
      page: props.paging.page,
      pageCount: props.paging.pageCount,
      tagType: tagType,
      orderKeys: sorts,
    }
    handleDeleteTag(list, setIsLoading, callback, getData);
  }
  return <div id="tag">
    <div style={{ width: '100%', display: 'flex'}}>
      {tabList.map((item) => <div
        key={item.key}
        onMouseEnter={() => setHoverKey(item.key)}
        onMouseLeave={() => setHoverKey('')}
        onClick={() => handleSetCurrentKey(item)}
        style={{
          marginRight: '16px',
          width: '128px',
          height: '50px',
          background: 'transparent',
          border:(currentKey === item.key || hoverKey === item.key) ? '2px solid #17A0AA' : '2px solid #FFF',
          backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#CDF3DF' :'rgba(0,0,0,0.02)',
          color: (currentKey === item.key || hoverKey === item.key) ? '#4B2E03' : props.color.tagBarTextColor,
          textAlign: 'center',
          lineHeight: '50px',
          borderRadius: '20px',
          fontSize: '18px',
          backdropFilter: 'blur(30px)',
          boxShadow: 'inset 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16)',
          
        }}>
        {item.name}
        <div
          style={{
            width: '70px',
            height: '5px',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            margin: '-10px auto 0px auto',
            backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#17A0AA' :'#00FFCE',
          }}
        ></div>
      </div>)}
    </div>
    <Row gutter={24} style={{ marginTop: '30px'}}>
      <Col lg={12} xxl={12}>
        <div
          style={{
            backgroundColor: 'rgba(64,185,174, 0.3)',
            height: '100%',
            borderRadius: '15px',
            padding: '16px',
            width: '100',
            backdropFilter: 'blur(30px)',
          }}
        >
          <div style={{ fontSize: '24px', color: props.color.eChartTitleColor, textAlign: 'center' }}>資料統計</div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '100%', maxWidth: '150px', height: '1px', backgroundColor: '#129B80'}}></div>
            <div style={{ minWidth: '8px', height: '8px', backgroundColor: '#129B80', borderRadius: '2px', margin: '24px'}}></div>
            <div style={{ width: '100%', maxWidth: '150px', height: '1px', backgroundColor: '#129B80'}}></div>
          </div>
          {showEChart && <ReactECharts
            option={option}
            ref={eChartRef}
            style={{ maxHeight: 550, height: '100%', marginTop: '-30px', width: '100%' }}
            opts={{ renderer: 'svg' }}
          />}
        </div>
      </Col>
      <Col lg={12} xxl={12}>
        <DataTable
          column={columns}
          data={props.tagList}
          color={props.color}
          i18n={props.i18n}
          isRowSelection={true}
          showCreateButton={true}
          showPatchDeleteButton={true}
          showDeleteButton={false}
          openModal={() => setDrawerVisible(true)}
          showSearch={true}
          searchValue={search}
          hasPagination={true}
          paging={props.paging}
          isLoading={isLoading}
          hasFrontCustomChildren={true}
          scrollHeight={props.screenHeight - 445}
          handleSearch={handleSearch}
          handleSizeChange={handleSizeChange}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handlePatchDelete={handlePatchDelete}
        />
      </Col>
    </Row>

    {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_tag' : 'light_tag'}
      closable={false}
      maskClosable={false}
      onClose={() => {
        setDrawerVisible(false);
        setFormValue({});
      }} visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => {
            setDrawerVisible(false);
            setFormValue({});
      }}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{currentName}_索引值</div>
      </div>
      {renderDrawerView()}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    tagList: _.get(state, 'Tag.tagList', []),
    rankTitle: _.get(state, 'Tag.rankTitle', []),
    rankData: _.get(state, 'Tag.rankData', []),
    paging: _.get(state, 'Tag.paging', {}),
    screenWidth: _.get(state, 'Global.screenWidth', 1920),
    screenHeight: _.get(state, 'Global.screenHeight', 1080),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateTag(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback, getData});
    },
    handleUpdateTag(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_UPDATE_TAG', payload, loading, callback, getData});
    },
    handleDeleteTag(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_TAG', payload, loading, callback, getData});
    },
    getTagList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_LIST', payload, loading, callback});
    },
    getTagById(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_BY_ID', payload, loading, callback});
    },
    getTagRank(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_RANK', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
