import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import DataTable from "../components/common/DataTable";


import "./Statistics.less";
import _ from "lodash";
import moment from "moment";

const tabList = [
  {
    name: '登入',
    key: 'login',
    id: 0,
  },
  // {
  //   name: '課程',
  //   key: 'learn',
  //   id: 10,
  // },
  {
    name: '測驗',
    key: 'exam',
    id: 7,
  }];

const Statistics = (props) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [hoverKey, setHoverKey] = useState('');
  const [types, setTypes] = useState(0);
  const [currentKey, setCurrentKey] = useState('login');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetData();
  }, []);
  
  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      "types": 0,
      "schools": [],
      "grades": [],
      "members": [],
      "start": null,
      "end": null,
      "is_export": false,
      "search": null,
      "page": 1,
      "pageCount": 10,
      "orderKeys":[]
    };
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    if (param.get('types') !== null) {
      setTypes(parseInt(param.get('types')));
      tabList.map((item) => {
        if (item.id === parseInt(param.get('types'))) {
          setCurrentKey(item.key);
        }
      });
      payload.types = parseInt(param.get('types'));
    }
    props.getUseLogList(payload, setIsLoading);
  }

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  // const handleSortChange = (e) => {
  //   setSorts(e);
  //   handleGetData();
  // }

  const handlePageChange = (e) => {
    navigate(`/statistics?types=${types}${e !== 1 ? `&page=${e}`: ''}`)
  }
 
  const loginColumn = [
    {
      title: props.i18n.statistics_login_name,
      dataIndex: 'member_name',
      key: 'member_name',
    },
    {
      title: props.i18n.statistics_login_count,
      dataIndex: 'login_count',
      key: 'login_count',
      align: 'right',
    },
    {
      title: props.i18n.statistics_login_subtotal,
      dataIndex: 'login_time',
      key: 'login_time',
      align: 'right',
      render: (value, record) => <div>{parseInt(record.login_time)}</div>
    },
  ];
  const ExamColumn = [
    {
      title: props.i18n.statistics_exam_name,
      dataIndex: 'exam_title',
      key: 'exam_title',
    },
    {
      title: props.i18n.statistics_exam_member,
      dataIndex: 'member_name',
      key: 'member_name',
      align: 'right',
    },
    {
      title: props.i18n.statistics_exam_time,
      dataIndex: 'time',
      key: 'time',
      align: 'right',
    },
    {
      title: props.i18n.statistics_exam_score,
      dataIndex: 'score',
      key: 'score',
      align: 'right',
    },
    {
      title: props.i18n.statistics_exam_end_time,
      dataIndex: 'end',
      key: 'end',
      align: 'right',
      render: (value, record) => <div>{value !== null ? moment(value).format('YYYY-MM-DD HH:mm:ss'): '-'}</div>
    },
  ];

  const handleSetCurrentKey = (item) => {
    setCurrentKey(item.key);
    setTypes(item.id);
    navigate(`/statistics?types=${item.id}&page=1`)
  }

  const handleExportBtn = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      "types": 0,
      "schools": [],
      "grades": [],
      "members": [],
      "start": null,
      "end": null,
      "is_export": true,
      "search": null,
      "page": 1,
      "pageCount": 10,
      "orderKeys":[]
    };
    if (param.get('page') !== null) {
      payload.page = parseInt(param.get('page'));
    }
    if (param.get('types') !== null) {
      payload.types = parseInt(param.get('types'));
    }
    props.handleExportLog(payload, setIsLoading);

  }

  const handleColumn = () => {
    if (types === 0) {
      return loginColumn;
    } else if (types === 7) {
      return ExamColumn;
    } else if (types === 10) {
      return ExamColumn;
    }
  }

  const handleData = () => {
    // 0 登入; 7 測驗 10 課程
    if (types === 0) {
      return props.authList;
    } else if (types === 7) {
      return props.examList;
    } else if (types === 10) {
      return props.learnList;
    }
  }
  return <div id="statistics">
    <Row>
      <Col span={16}>
        <div style={{ width: '100%', display: 'flex'}}>
          {tabList.map((item) => <div
            key={item.key}
            onMouseEnter={() => setHoverKey(item.key)}
            onMouseLeave={() => setHoverKey('')}
            onClick={() => handleSetCurrentKey(item)}
            style={{
              marginRight: '16px',
              width: '128px',
              height: '50px',
              background: 'transparent',
              border:(currentKey === item.key || hoverKey === item.key) ? '2px solid #17A0AA' : '2px solid #FFF',
              backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#CDF3DF' :'rgba(0,0,0,0.02)',
              color: (currentKey === item.key || hoverKey === item.key) ? '#4B2E03' : props.color.tagBarTextColor,
              textAlign: 'center',
              lineHeight: '50px',
              borderRadius: '20px',
              fontSize: '18px',
              backdropFilter: 'blur(30px)',
              boxShadow: 'inset 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16)',
              
            }}>
            {item.name}
            <div
              style={{
                width: '70px',
                height: '5px',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
                margin: '-10px auto 0px auto',
                backgroundColor: (currentKey === item.key || hoverKey === item.key) ? '#17A0AA' :'#00FFCE',
              }}
            ></div>
          </div>)}
        </div>
      </Col>
      <Col span={8} style={{ textAlign: 'right'}}>
        <Button
          onClick={handleExportBtn}
          style={{
            width: '128px',
            height: '50px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4A9E8F',
            border: `2px solid ${props.color.createBtnBorder}`,
            borderRadius: '15px',
            fontSize: '18px'
          }}
          >
            {props.i18n.export_btn}
        </Button>
      </Col>
    </Row>
    <DataTable
      column={handleColumn()}
      data={handleData()}
      color={props.color}
      i18n={props.i18n}
      isRowSelection={false}
      showCreateButton={false}
      showPatchDeleteButton={false}
      showDeleteButton={false}
      showSearch={false}
      searchValue={search}
      hasPagination={true}
      showChangePageSize={false}
      paging={props.paging}
      isLoading={isLoading}
      hasFrontCustomChildren={false}
      scrollHeight={props.screenHeight - 350}
      // handleSortChange={handleSortChange}
      handlePageChange={handlePageChange}
    />
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    authList: _.get(state, 'Statistics.authList', []),
    examList: _.get(state, 'Statistics.examList', []),
    learnList: _.get(state, 'Statistics.learnList', []),
    paging: _.get(state, 'Statistics.paging', {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUseLogList(payload, loading, callback) {
      dispatch({ type: 'GET_USE_LOG_LIST', payload, loading, callback});
    },
    handleExportLog(payload, loading, callback) {
      dispatch({ type: 'HANDLE_EXPORT_LOG', payload, loading, callback});
    },
    handleExportLotteryLog(payload, loading, callback) {
      dispatch({ type: 'HANDLE_EXPORT_LOTTERY_LOG', payload, loading, callback});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
