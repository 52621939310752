/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { getTheme, setTheme } from "../utils/theme";

function* CHANGE_WindowScreenSize({ payload: windowSize }) {
  const mobileWidth = 768;
  const isMobile = yield select((state) => state.Global.isMobile);

  if (windowSize <= mobileWidth && isMobile !== true)
    yield put({ type: "SAVE_isMobile", payload: true });

  if (windowSize > mobileWidth && isMobile !== false)
    yield put({ type: "SAVE_isMobile", payload: false });
}

function* INITIAL_Theme() {
  const theme = yield call(getTheme);
  let result;

  if (theme === "" || theme === "undefined" || theme === "null")
    result = "light";
  else result = theme;

  yield setTheme(result);
  yield put({ type: "SAVE_Theme", payload: result });
}

function* CHANGE_Theme() {
  const theme = yield call(getTheme);
  let result;

  if (theme === "dark") result = "light";
  else result = "dark";

  yield setTheme(result);
  yield put({ type: "SAVE_Theme", payload: result });
}

export default function* Example() {
  yield takeLatest("CHANGE_WindowScreenSize", CHANGE_WindowScreenSize);
  yield takeLatest("INITIAL_Theme", INITIAL_Theme);
  yield takeLatest("CHANGE_Theme", CHANGE_Theme);
}
