import React from 'react';
import { Form, Input } from 'antd';
import './FormTextarea.less';
const { TextArea } = Input;

const FormTextarea = (props) => {
  return (
    <Form.Item
      label={<div
        style={{
          color: props.textColor,
          fontSize: '18px',
          padding: '0px 16px',
          fontWeight: 'bold'
        }}>{props.title}</div>}
      name={props.name}
      colon={false}
    >
      <TextArea
        id="formTextarea"
        autoComplete="new-account"
        style={{
          borderRadius: '15px',
          color: '#125346',
          padding: '0px 16px',
          backgroundColor: '#FFF',
          paddingTop: '8px',
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
          width: '100%',
        }}
        rows={3}
        placeholder={props.placeholder}
      />
    </Form.Item>
  );
}

export default FormTextarea;