import request from "../utils/request";

export function CREATE_Lottery(token, payload) {
  return request.post("/activity", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_Lottery(token, payload) {
  return request.put("/activity", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_Lottery(token, payload) {
  return request.post(`/activity/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_LotteryById(token, payload) {
  return request.get(`/activity?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Lottery(token, payload) {
  return request.delete(`/activity?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_LotteryLog(token, payload) {
  return request.post(`/activity/lottery/dashboard/log`, payload, {
    headers: {Authorization: token },
  });
}

export function POST_ExportLog(token, payload) {
  return request.post(`/activity/lottery/export`, payload, {
    headers: {Authorization: token },
  });
}
