import request from "../utils/request";

export function CREATE_Topic(token, payload) {
  return request.post("/topic", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function UPDATE_Topic(token, payload) {
  return request.put("/topic", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function POST_ImportTopic(token, payload) {
  return request.post("/topic/import", payload, {
    headers: {
      Authorization: token,
      ContentType: 'multipart/form-data',
    },
  });
}

export function GET_Topic(token, payload) {
  return request.post(`/topic/all`, payload, {
    headers: {Authorization: token },
  });
}

export function GET_TopicById(token, payload) {
  return request.get(`/topic?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function DELETE_Topic(token, payload) {
  return request.delete(`/topic?id=${payload}`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TopicByForm(token,) {
  return request.get(`/topic/form`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TopicTypeList(token,) {
  return request.post(`/topictype/all`, {}, {
    headers: {Authorization: token },
  });
}

export function GET_TopicTypeByForm(token,) {
  return request.get(`/topictype/form`, {}, {
    headers: {Authorization: token },
  });
}