import React from 'react';
import Images from "../../themes/Images";
import { Input } from 'antd';
import './Search.less';

const { Search } = Input;
const SearchInput = (props) => {
  return (
    <Search
      enterButton={<img alt="search" src={Images.search} />}
      id="searchInput"
      value={props.searchValue}
      style={{
        height: '50px',
      }}
      onChange={(e) => props.handleSearch(e.target.value)}
    />
  );

}

export default SearchInput;
