/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_School,
  CREATE_School,
  UPDATE_School,
  DELETE_School,
  GET_SchoolById,
  GET_SchoolByForm,
  GET_GradeBySchoolId,
  POST_ImportMember,
  GET_RoleList,
  GET_SchoolPermissionForm,
  PUT_ChangePermission,
} from "../services/School";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getSchoolList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_School, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_SCHOOL_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getSchoolListByForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_SchoolByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_SCHOOL_LIST_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getGradeBySchoolId({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_GradeBySchoolId, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_GRADE_BY_SCHOOL_ID_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getSchoolById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_SchoolById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateSchool({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_School, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_SCHOOL_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.create_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateSchool({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_School, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_SCHOOL_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteSchool({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_School, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_SCHOOL_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleImportSchoolMember({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(POST_ImportMember, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.import_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getRoleList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_RoleList, token);
    if (res.isSuccess) {
      yield put({ type: 'GET_ROLE_LIST_SUCCESS', payload: res.results });
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getSchoolPermissionForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_SchoolPermissionForm, token, payload);
    if (res.isSuccess) {
      yield put({ type: 'GET_SCHOOL_PERMISSION_FORM_SUCCESS', payload: res.result });
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleChangePermission({ payload, id, callback, loading,  }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(PUT_ChangePermission, token, payload);
    if (res.isSuccess) {
      showMessage({ type: 'success', content: '編輯成功' });
      yield put({ type: 'GET_SCHOOL_PERMISSION_FORM', payload: id});
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


export default function* Example() {
  yield takeLatest("GET_SCHOOL_LIST", getSchoolList);
  yield takeLatest("GET_ROLE_LIST", getRoleList);
  yield takeLatest("HANDLE_CHANGE_PERMISSION", handleChangePermission);
  yield takeLatest("GET_SCHOOL_PERMISSION_FORM", getSchoolPermissionForm);
  yield takeLatest("GET_SCHOOL_BY_ID", getSchoolById);
  yield takeLatest("HANDLE_CREATE_SCHOOL", handleCreateSchool);
  yield takeLatest("HANDLE_UPDATE_SCHOOL", handleUpdateSchool);
  yield takeLatest("HANDLE_DELETE_SCHOOL", handleDeleteSchool);
  yield takeLatest("GET_SCHOOL_LIST_BY_FORM", getSchoolListByForm);
  yield takeLatest("GET_GRADE_BY_SCHOOL_ID", getGradeBySchoolId);
  yield takeLatest("HANDLE_IMPORT_SCHOOL_MEMBER", handleImportSchoolMember);
}
