/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable require-yield */
import _ from "lodash";
import { message } from "antd";
import { showMessage } from "./message";

export function* handleError(error) {
  const content = _.get(error, "response.data.message");
  const status = error.response.status;
  if (status === 401) {
    showMessage({ type: "error", content: "登入權限過期" });
    window.localStorage.setItem("DTS_DASHBOARD_LOGIN", "false");
    window.location.href = "/login";
  } else showMessage({ type: "error", content });
}

export function* handleSuccess(response) {
  const title = _.get(response, "title", "操作成功！");
  const content = _.get(response, "content", "");
  message.success(title, content);
}
