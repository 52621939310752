import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input, Row, Col, Checkbox, Pagination, Select } from 'antd';
import './DataTable.less';
import Images from "../../themes/Images";
import _ from 'lodash';
import Swal from 'sweetalert2';

const { Search } = Input;
const sizeList = [
  {
    id: 10,
    name: 10,
  },
  {
    id: 20,
    name: 20,
  },
  {
    id: 50,
    name: 50,
  },
]

class DataTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    showPayButton: PropTypes.bool,
    showDisableButton: PropTypes.bool,
    column: PropTypes.array,
    handleChange: PropTypes.func,
    isRowSelection: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    showCreateButton: PropTypes.bool,
    showPointButton: PropTypes.bool,
    expandable: PropTypes.object,
    showSearch: PropTypes.bool,
    // showSelect: PropTypes.bool,
    customChildren: PropTypes.element,
    hasCustomChildren: PropTypes.bool,
    showClassSettingButton: PropTypes.bool,
    handleClassSetting: PropTypes.func,
    isLoading: PropTypes.bool,
    paging: PropTypes.object,
    hasPagination: PropTypes.bool,
    rowClassName: PropTypes.string,
  };

  static defaultProps = {
    showPayButton: false,
    showDisableButton: false,
    handleChange: () => { },
    isRowSelection: true,
    showDeleteButton: true,
    searchPlaceholder: '輸入搜尋文字',
    showCreateButton: true,
    showPointButton: false,
    expandable: {},
    showSearch: true,
    // showSelect: false,
    hasCustomChildren: false,
    showClassSettingButton: false,
    hasPagination: false,
    handleClassSetting: () => { },
    isLoading: false,
    paging: {
      total: 1,
      now_page: 1,
      page_size: 10,
    },
    rowClassName: '',
  };

  state = {
    selected: [],
    classOptions: [],
    selectedRowKeys: [],
    visible: false,
    allData: [],
    modalVisible: false,


    imgSrc: '',
    showModal: false,
    cropResult: '',
    hover: false,
    src: '',
    cropTime: false,
    sort: [],
    checkList: [],
  };

  componentDidMount() {
    const {
      options,
      value,
      checkList = [],
    } = this.props;

    this.setState({
      classOptions: options,
      searchValue: value,
      checkList: checkList,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkList !== this.props.checkList) {
      this.setState({
        checkList: nextProps.checkList,
      });
    }

  }


  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onChange = (e) => {
    e.preventDefault();
    let files = '';
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    this.setState({
      cropTime: true,
    });
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  handleSort = (data) => {
    const { sort } = this.state;
    const { handleSortChange } = this.props;
    let tempSort = JSON.parse(JSON.stringify(sort));
    let temp = sort.filter((item) => item.key === data.key);
    if (temp.length > 0) {
      tempSort.map((item, index) => {
        if (item.key === data.key) {
          if (item.sort === 0) {
            tempSort[index].sort = 1;
          } else if (item.sort === 1) {
            tempSort[index].sort = -1;
          } else {
            tempSort[index].sort = 0;
          }
        }
      });
    } else {
      tempSort.push({
        key: data.key,
        sort: 1,
      });
    }
    handleSortChange(tempSort);
    this.setState({
      sort: tempSort,
    });
  }

  handleCheckChange = (selected, key) => {
    const { checkList } = this.state;
    const { handleCheckChange } = this.props;
    let tempCheck = JSON.parse(JSON.stringify(checkList));
    if (selected) {
      if (tempCheck.filter((item) => item === key).length === 0) {
        tempCheck.push(key);
      }
    } else {
      tempCheck = tempCheck.filter((item) => item !== key);
    }

    if (handleCheckChange) {
      handleCheckChange(tempCheck);
    }

    this.setState({
      checkList: tempCheck,
    });
  }

  handleCheckAllChange = (selected) => {
    const { checkList } = this.state;
    const { data, handleCheckChange } = this.props;

    let tempCheck = JSON.parse(JSON.stringify(checkList));
    if (selected) {
      data.map((child) => {
        if (tempCheck.filter((item) => item === child.key).length === 0) {
          tempCheck.push(child.key);
        }
      });
    } else {
      data.map((child) => {
       tempCheck = tempCheck.filter((item) => item !== child.key);
      });
    }
    if (handleCheckChange) {
      handleCheckChange(tempCheck);
    }
    this.setState({
      checkList: tempCheck,
    });
  }

  handleCheckAllStatus = (key) => {
    const {
      checkList,
    } = this.state;
    const { data } = this.props;
    if (key === 'checked') {
      let isAll = true;

      data.map((item) => {
        if (checkList.indexOf(item.key) < 0) {
          isAll = false;
        }
      });
      return isAll;
    } else {
      let isIndeterminate = false;
      let isAll = true;

      data.map((item) => {
        if (checkList.indexOf(item.key) < 0) {
          isAll = false;
        }
      });
      data.map((item) => {
        if (checkList.indexOf(item.key) > -1) {
          isIndeterminate = true;
        }
      });
      if (isAll) {
        return false;
      } else {
        return isIndeterminate;
      }
    }

  }

  handleSortImg = (data, key) => {
    const { sort } = this.state;
    const { color } = this.props;
    let theme = `_${color.currentTheme}`;
    let temp = sort.filter((item) => item.key === data.key);
    if (temp.length > 0) {
      if (temp[0].sort === 0) {
        if (key === 'up') {
          return Images[`up${theme}`];
        } else {
          return Images[`down${theme}`];
        }
      } else if (temp[0].sort === 1) {
        if (key === 'up') {
          return Images[`up_check${theme}`];
        } else {
          return Images[`down${theme}`];
        }
      } else if (temp[0].sort === -1) {
        if (key === 'up') {
          return Images[`up${theme}`];
        } else {
          return Images[`down_check${theme}`];
        }
      } 
    } else {
      if (key === 'up') {
        return Images[`up${theme}`];
      } else {
        return Images[`down${theme}`];
      }
    }
  }

  handlePatchDelete = () => {
    const { i18n, handlePatchDelete } = this.props;
    const { checkList } = this.state;
    Swal.fire({
      title: i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: i18n.swal_no,
      confirmButtonText: i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        const callback = () => {
          this.setState({
            checkList: [],
          });
        }
        handlePatchDelete(checkList, callback);
      }
    });

  }


  render() {
    let {
      checkList,
    } = this.state;
    const {
      column,
      data,
      searchValue,
      handleSearch,
      openModal,
      showCreateButton,
      showSearch,
      color,
      paging,
      handlePageChange,
      handlePatchDelete,
      isRowSelection,
      handleSizeChange,
      hasFrontCustomChildren,
      frontCustomChildren,
      showPatchDeleteButton,
      hasCenterCustomChildren,
      centerCustomChildren,
      i18n,
      scrollHeight,
      hasPagination = true,
      showChangePageSize = true,
      tableLineHeight= 70,
    } = this.props;

    return (
      <div id="DataTable">
        <Row style={{ width: '100%', marginBottom: '30px'}} >
          <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
            {
              hasFrontCustomChildren && frontCustomChildren
            }
            {showSearch && <Search
              enterButton={<img alt="search" src={Images.search} />}
              size="large"
              id="searchInput"
              value={searchValue}
              style={{
                height: '50px',
              }}
              onChange={(e) => handleSearch(e.target.value)}
            />}
            {
              hasCenterCustomChildren && centerCustomChildren
            }
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {showPatchDeleteButton && <div
              style={{
                fontSize: '18px',
                lineHeight: '50px',
                color: color.currentTheme === 'dark' ? 'white' : '#242933',
                marginRight: '16px',
              }}
            >{i18n.select} {checkList.length} {i18n.item}</div>}
            {
              showPatchDeleteButton && <Button
                onClick={this.handlePatchDelete}
                style={{
                  width: '138px',
                  height: '50px',
                  textAlign: 'center', 
                  color: 'white',
                  backgroundColor: checkList.length === 0 ? '#707070' :'#D53838',
                  border: `2px solid ${color.createBtnBorder}`,
                  borderRadius: '15px',
                  fontSize: '18px',
                  marginRight: '8px',
                }}
                disabled={checkList.length === 0}
              >
                {i18n.patch_delete_btn}
            </Button>
            }
            {
              showCreateButton && <Button
                onClick={openModal}
                style={{
                  width: '138px',
                  height: '50px',
                  textAlign: 'center',
                  color: 'white',
                  backgroundColor: '#4A9E8F',
                  border: `2px solid ${color.createBtnBorder}`,
                  borderRadius: '15px',
                  fontSize: '18px'
                }}
              >
                {i18n.create_btn}
            </Button>
            }
          </Col>
        </Row>
        <div
          style={{
            backgroundColor: color.currentTheme === 'dark' ? '#002C51': '#C2EBE8',
            height: '70px',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            display: 'flex',
            padding: '0px 30px',
            alignItems: 'center',
            width: '100%',
          }}>
          {isRowSelection &&<div style={{ minWidth: '30px'}}>
            <Checkbox
              indeterminate={this.handleCheckAllStatus('indeterminate')}
              checked={this.handleCheckAllStatus('checked')}
              onChange={(e) => this.handleCheckAllChange(e.target.checked)}
              className={color.currentTheme === 'dark' ? 'dark-checkbox' : 'light-checkbox'} />
          </div>}
          <div style={{ display: 'flex', width: '100%'}}>
            {column.map((item) => <div
              style={{
                color: color.currentTheme === 'dark' ? 'white': '#242933',
                fontSize: '18px',
                maxWidth: _.has(item, 'width') ? item.width: '100%',
                flex: 1,
                alignItems: 'center',
                textAlign: _.has(item, 'align') ? item.align : 'left'
              }}>
              <div
                style={{
                  lineHeight: '70px',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}>
                {item.title}
                {item.sort && <div
                  style={{
                    width: '12px',
                    lineHeight: '8px',
                    marginLeft: '8px',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  onClick={() => this.handleSort(item)}
                >
                  <img src={this.handleSortImg(item, 'up')} alt="sort_up" />
                  <img src={this.handleSortImg(item, 'down')} alt="sort_down" />
                </div>}
              </div>
            </div>)}
          </div>
        </div>
        <div style={{ width: '100%', height: scrollHeight, overflowX: 'auto' }}>
          {data.map((current) => {
            return <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.11)',
                height: `${tableLineHeight}px`,
                display: 'flex',
                alignItems: 'center',
                padding: '0px 30px',
                borderBottom: '1px solid #267E6D',
              }}>
              {isRowSelection && <div style={{ minWidth: '30px'}}>
                <Checkbox
                  className={color.currentTheme === 'dark' ? 'dark-checkbox' : 'light-checkbox'}
                  checked={checkList.indexOf(current.key) > -1}
                  onChange={(e) => this.handleCheckChange(e.target.checked, current.key)}
                />
              </div>}
              {column.map((item) => <div
                style={{
                  color: color.currentTheme === 'dark' ? 'white': '#242933',
                  fontSize: '18px',
                  flex: 1,
                  maxWidth: _.has(item, 'width') ? item.width: '100%',
                  // lineHeight: '70px',
                  alignItems: 'center',
                  textAlign: _.has(item, 'align') ? item.align: 'left'
                }}>
                {_.has(item, 'render') ? item.render(current[item.key], current) :current[item.key]}
              </div>)}
            </div>
          })}
        </div>
        {hasPagination && <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
          <Pagination
            defaultCurrent={paging.page}
            total={paging.total}
            current={paging.page}
            pageSize={paging.pageCount}
            showSizeChanger={false}
            onChange={handlePageChange}
          />
         {showChangePageSize && <Select
            id="selectList"
            style={{ width: '112px', marginLeft: '16px'}}
            defaultValue={paging.pageCount}
            onChange={handleSizeChange}
          >
            {
              sizeList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name} / {i18n.page}</Select.Option>)
            }
          </Select>}
        </div>}
      </div>
    );
  }
}

export default DataTable;
