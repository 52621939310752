/* eslint-disable require-yield */
/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import {
  GET_Exam,
  GET_ExamForm,
  CREATE_Exam,
  UPDATE_Exam,
  DELETE_Exam,
  UPDATE_Part,
  DELETE_Part,
  DELETE_PartItem,
  COPY_Exam,
  UPDATE_ExamTopic,
  SORT_Part,
  GET_ExamById,
  GET_ExamPartTopic,
  GET_ExamByForm, 
  GET_ExamTypeByForm,
  GET_ExamTypeList,
  
} from "../services/Exam";
import { put, takeLatest, select, call } from "redux-saga/effects";
import { handleError } from "../utils/error";
import { showMessage } from "../utils/message";

function* getExamList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_Exam, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_EXAM_LIST_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getExamFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_EXAM_FORM_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getExamListByForm({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_EXAM_LIST_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getExamById({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamById, token, payload);
    if (res.isSuccess) {
      if (callback) { callback(res.result) };
      yield put({ type: 'GET_EXAM_BY_ID_SUCCESS', payload: res.result });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCreateExam({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(CREATE_Exam, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.create_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleCopyExam({ payload, loading, callback }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(COPY_Exam, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type: 'success', content: i18n.copy_success });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateExam({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Exam, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_BY_ID', payload: payload.exam_id });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdatePart({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_Part, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_BY_ID', payload: payload.exam_id });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeletePartItem({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_PartItem, token, payload.part_item_id);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_BY_ID', payload: payload.exam_id });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleUpdateExamTopic({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(UPDATE_ExamTopic, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getExamPartTopic({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamPartTopic, token, payload);
    if (res.isSuccess) {
      yield put({ type: 'GET_EXAM_TOPIC_SUCCESS', payload: res.result });
      if (callback) { callback(res.result) };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* handleSortPart({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(SORT_Part, token, {list: payload.sort});
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_BY_ID', payload: payload.exam_id });
      showMessage({ type:'success',content: i18n.update_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteExam({ payload, callback, loading, getData }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Exam, token, payload);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_LIST', payload: getData });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* handleDeleteExamPart({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(DELETE_Part, token, payload.part_id);
    if (res.isSuccess) {
      const i18n = yield select((state) => state.Global.i18n);
      yield put({ type: 'GET_EXAM_BY_ID', payload: payload.exam_id });
      showMessage({ type:'success',content: i18n.delete_success})
      if (callback) { callback() };
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

function* getExamTypeFormList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamTypeByForm, token, payload);
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_EXAM_TYPE_BY_FORM_SUCCESS', payload: res });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}


function* getExamTypeList({ payload, callback, loading }) {
  try {
    const token = yield select((state) => state.User.token);
    if (loading) loading(true);
    const res = yield call(GET_ExamTypeList, token, {});
    if (res.isSuccess) {
      if (callback) { callback() };
      yield put({ type: 'GET_EXAM_TYPE_LIST_SUCCESS', payload: res.results });
    }
  } catch (error) {
    yield handleError(error);
  } finally {
    if (loading) loading(false);
  }
}

export default function* Example() {
  yield takeLatest("GET_EXAM_LIST", getExamList);
  yield takeLatest("GET_EXAM_FORM_LIST", getExamFormList);
  yield takeLatest("GET_EXAM_PART_TOPIC", getExamPartTopic);
  yield takeLatest("GET_EXAM_BY_ID", getExamById);
  yield takeLatest("HANDLE_CREATE_EXAM", handleCreateExam);
  yield takeLatest("HANDLE_UPDATE_EXAM", handleUpdateExam);
  yield takeLatest("HANDLE_UPDATE_PART", handleUpdatePart);
  yield takeLatest("HANDLE_COPY_EXAM", handleCopyExam);
  yield takeLatest("HANDLE_UPDATE_EXAM_TOPIC", handleUpdateExamTopic);
  yield takeLatest("HANDLE_SORT_PART", handleSortPart);
  yield takeLatest("HANDLE_DELETE_EXAM", handleDeleteExam);
  yield takeLatest("HANDLE_DELETE_EXAM_PART", handleDeleteExamPart);
  yield takeLatest("HANDLE_DELETE_PART_ITEM", handleDeletePartItem);
  yield takeLatest("GET_EXAM_LIST_BY_FORM", getExamListByForm);
  yield takeLatest("GET_EXAM_TYPE_FORM_LIST", getExamTypeFormList);
  yield takeLatest("GET_EXAM_TYPE_LIST", getExamTypeList);
}
