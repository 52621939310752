import React from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import {  ConfigProvider } from "antd";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "./EmptyLayout.less";
import zh_TW from "antd/es/locale/zh_TW";
import _ from "lodash";
import background from '../assets/login_background.png';

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const App = (props) => {
  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={props.theme}>
      <ConfigProvider locale={zh_TW} componentSize="middle">
        <div
          id="Layout"
          style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          // backgroundColor: '#313131',
          // backgroundPosition: 'bottom',
          }}
        >
          <div id="content">
            <p style={{ fontSize: '40px', color: 'white', marginBottom: '30px', textAlign: 'center'}}>數位教科書系統</p>
            <div style={{
              width: '450px',
              height: '417px',
              backgroundColor: '#0A0A0C',
              borderRadius: '20px',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
              padding: '0px 30px',
            }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px'}}>
                <div style={{ width: '198px', height: '8px', backgroundColor: '#F39C16', borderRadius: '4px', marginTop: '-4px' }}></div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </ThemeSwitcherProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CHANGE_Theme(payload, callback, loading) {
      dispatch({ type: 'CHANGE_Theme' });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
