/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import "./ChangePassword.less";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const App = (props) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleChangeNewPassword = (value) => {
    let code = window.location.pathname.split('/change/password/')[1];
    const callback = () => {
      navigate('/login');
    }
    props.handleChangeNewPassword({...value, code}, callback, setIsLoading);
  }

  return <div id="Home">   
    <div style={{ width: '100%', textAlign: 'center', fontSize: '18px', color: '#D9D9D9'}}>變更新密碼</div>
    <Form
      {...layout}
      name="basic"
      initialValues={{
        account: '',
        password: '',
      }}
      style={{ marginTop: '16px'}}
      onFinish={value => handleChangeNewPassword(value)}
    >
      <Form.Item
        label={<div style={{ color: '#2EC1A4', fontSize: '16px', padding: '0px 16px'}}>新密碼</div>}
        name="password"
        colon={false}
      >
        <Input
          autoComplete="new-account"
          style={{
            borderRadius: '40px',
            height: '45px',
            color: 'white',
            border: '0px',
            padding: '0px 16px',
            backgroundColor: '#44474B',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
            width: '100%',
          }}
          placeholder='請輸入新密碼'
          hasFeedback
        />
      </Form.Item>
      <Form.Item
        label={<div style={{ color: '#2EC1A4', fontSize: '16px', padding: '0px 16px'}}>確認新密碼</div>}
        name="passwordConfirm"
        colon={false}
        hasFeedback
        className="change_password"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密碼輸入不一致!'));
            },
          }),
        ]}
      >
        <Input
          autoComplete="new-account"
          style={{
            borderRadius: '40px',
            height: '45px',
            color: 'white',
            border: '0px',
            padding: '0px 16px',
            backgroundColor: '#44474B',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
            width: '100%',
          }}
          placeholder='請確認新密碼'
        />
      </Form.Item>
      <Form.Item style={{ marginTop: '30px'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{ height: '40px', backgroundColor: '#5BDFC6', width: '100%', borderRadius: '20px', color: 'black' }}
        >
          確定
        </Button>
      </Form.Item>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeNewPassword(payload, callback, loading) {
      dispatch({ type: 'HANDLE_ChangePassword', payload, callback, loading });
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
