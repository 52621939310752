import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Upload } from 'antd';
import ReactECharts from 'echarts-for-react';
import FormInput from "../components/common/FormInput";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import "./BookView.less";
import _ from "lodash";
import { PlusOutlined } from '@ant-design/icons';

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const normFile = e => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BookView = (props) => {
  let navigate = useNavigate();
  const eChartRef = useRef(null);
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    is_random: false,
    is_practice: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    props.getTopicTypeFormList();
    props.getTagFormList(8);
    let url = window.location.pathname.split('/book/view/')[1];
    const callback = (value) => {
      setItems(value.parts);
    }
    props.getBookById(url, setIsLoading, callback);
  }, []);

  useEffect(() => {
    setItems(props.bookDetail.parts);
  }, [props.bookDetail]);
  
  const handlePreview = (file) => {
    if (_.has(file, 'thumbUrl')) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  }
  const handleImageChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      let temp = newFileList.splice(1, 1);
      createForm.current.setFieldsValue({ img: temp });
      setImageData(temp);
    } else {
      createForm.current.setFieldsValue({ img: newFileList });
      setImageData(newFileList);
    }
  };

  const renderBookDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleBookFinish}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_name}
            name="book_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_name}`}
          />
        </Col>
        <Col span={12}>
          <Form.Item 
            valuePropName="imgs"
            name="imgs"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.img}`,
              },
            ]}
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                padding:'0px',
                fontWeight: 'bold'
              }}>{props.i18n.book_img}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload 
              listType="picture-card"
              accept=".PNG,.JPG,.JPEG"
              fileList={imageData}
              onChange={handleImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#292C33'}}>{props.i18n.upload}</div>
              </div>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.book_effective}
            name="book_effective"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_effective}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.origin_price}
            name="book_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>
        <Col span={6}>
          <FormInput
            title={props.i18n.discount_price}
            name="book_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>
        <Col span={12} style={{ display: 'flex'}}>
          <Form.Item
            label={<div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '18px',
                fontWeight: 'bold'
              }}>上架狀態</div>}
            name="is_sell"
            valuePropName="checked"
          >
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col span={24}>  
          <HtmlEditor
            title={props.i18n.book_desc}
            i18nMode={props.i18nMode}
            propName="book_desc"
            value={formValue.book_desc}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const renderCreateChapter = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          imgs: imageData,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleCreateChapter}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.book_part_content}
            name="chapter_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.book_part_content}`}
          />
        </Col>

        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 7,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }

  const handleDeleteChapter = (item) => {
    let payload = {
      chapter_id: item.chapter_id,
      book_id: props.bookDetail.book_id,
    };
    props.handleDeleteChapter(payload, setIsLoading);
  }
  
  const handleFormChange = (change, all) => {
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleGetExamPartTopic = (item) => {
    // let tempTag = [];
    // let tempAbility = [];
    // items[key].tags.map((item) => {
    //   tempTag.push(item.tag_id);
    // })
    // items[key].abilities.map((item) => {
    //   tempAbility.push(item.ability_id);
    // })
    // let payload = {
    //   id: items[key].part_id,
    //   type: item.part_item_type,
    //   tags: tempTag,
    //   abilities: tempAbility,
    // };
    // const callback = () => {
    //   navigate(`/exam/topic/${props.bookDetail.exam_id}/${items[key].part_id}/${item.part_item_type}`);
    // }
    // props.getExamPartTopic(payload, setIsLoading, callback);
  }

  const handleCreateChapter = (value) => {
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleCreateChapter({
      ...value,
      book_id: props.bookDetail.book_id,
    }, setIsLoading, callback);
  }

  const handleBookFinish = (value) => {
    let formData = new FormData();
    formData.append("book_title", formValue.book_title);
    formData.append("book_id", props.bookDetail.book_id);
    formData.append("book_desc", _.isObject(formValue.book_desc) ? formValue.book_desc.toHTML(): formValue.book_desc);
    if (formValue.book_effective !== undefined) {
      formData.append("book_effective", formValue.book_effective);
    } 
    formData.append("book_price", parseInt(formValue.book_price));
    if (formValue.book_discount !== undefined) {
      formData.append("book_discount", formValue.book_discount);
    } 
    formData.append("is_sell", formValue.is_sell);
    if (imageData.length > 0) {
      if (_.has(imageData[0], 'originFileObj')) {
        formData.append("imgs[0].file", imageData[0].originFileObj);
      }
      formData.append("imgs[0].file_id", props.bookDetail.imgs[0].file_id);
      formData.append("imgs[0].file_order", 1);
    }
    formData.append("book_title", formValue.book_title);
    formValue.chapters.map((item, index) => {
      formData.append(`chapters[${index}].chapter_title`, item.chapter_title);
      item.tags.map((child) => {
        formData.append(`chapters[${index}].tags`, child);
      });
      item.abilities.map((child) => {
        formData.append(`chapters[${index}].abilities`, child);
      });
    });
    formValue.tags.map((child) => {
      formData.append(`tags`, child);
    });
    formValue.abilities.map((child) => {
      formData.append(`abilities`, child);
    });
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleUpdateBook(formData, setIsLoading, callback, props.bookDetail.book_id);
  }

  const monthOption = {
    title: {
      text: ''
    },
    legend: {
      show: false,
      itemHeight: '36px',
      top: '0px'
    },
    tooltip: {
      show: false,
      toolbox: {
        show: false,
      }
    },
    grid: {
      show: false,
      containLabel: true,
      left: '3%',
      right: '18%',
      // height: '558px',
    },
    xAxis: {
      name: '月份',
      type: 'category',
      data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      axisLine: {
        show: true,
        lineStyle: {
          color: '#17A0AA',
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: props.color.eChartTextColor,
          width: '36px'
        },
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
      nameTextStyle: {
        height: '36px'
      }
    },
    textStyle: {
      color: props.color.eChartTextColor,
      fontSize: '16px',
    },
    series: [{
      // name: '',
      type: 'bar',
      data: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 1000, 2000, 3000, 4000],
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
              offset: 0, color: props.color.eChartItemLeft // 0% 处的颜色
          }, {
              offset: 1, color: props.color.eChartItemRight// 100% 处的颜色
          }],
        },
        borderRadius: [0,5,5,0],
        height: 36,
      }
    }]
  };

  const yearOption = {
    title: {
      text: ''
    },
    legend: {
      show: false,
      itemHeight: '36px',
      top: '0px'
    },
    tooltip: {
      show: false,
      toolbox: {
        show: false,
      }
    },
    grid: {
      show: false,
      containLabel: true,
      left: '3%',
      right: '18%',
      // height: '558px',
    },
    xAxis: {
      name: '年份',
      type: 'category',
      data: ['2022', '2023'],
      axisLine: {
        show: true,
        lineStyle: {
          color: '#17A0AA',
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: props.color.eChartTextColor,
          width: '36px'
        },
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
      nameTextStyle: {
        height: '36px'
      }
    },
    textStyle: {
      color: props.color.eChartTextColor,
      fontSize: '16px',
    },
    series: [{
      // name: '',
      type: 'bar',
      data: [100000, 200000],
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
              offset: 0, color: props.color.eChartItemLeft // 0% 处的颜色
          }, {
              offset: 1, color: props.color.eChartItemRight// 100% 处的颜色
          }],
        },
        borderRadius: [0,5,5,0],
        height: 36,
      }
    }]
  };

  const useOption = {
    title: {
      text: ''
    },
    legend: {
      show: false,
      itemHeight: '36px',
      top: '0px'
    },
    tooltip: {
      show: false,
      toolbox: {
        show: false,
      }
    },
    grid: {
      show: false,
      containLabel: true,
      left: '3%',
      right: '18%',
      // height: '558px',
    },
    yAxis: {
      name: '課程',
      type: 'category',
      data: ['大一英文', '大二英文', '大三英文', '大四英文'],
      axisLine: {
        show: true,
        lineStyle: {
          color: '#17A0AA',
        },
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
    },
    xAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: props.color.eChartTextColor,
          width: '36px'
        },
      },
      axisLabel: {
        fontSize: '16px',
      },
      axisTick: {
        show: true,
        alignWidthLabel: true
      },
      nameTextStyle: {
        height: '36px'
      }
    },
    textStyle: {
      color: props.color.eChartTextColor,
      fontSize: '16px',
    },
    series: [{
      // name: '',
      type: 'bar',
      data: ['100', '80', '75', '92'],
      itemStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
              offset: 0, color: props.color.eChartItemLeft // 0% 处的颜色
          }, {
              offset: 1, color: props.color.eChartItemRight// 100% 处的颜色
          }],
        },
        borderRadius: [0,5,5,0],
        height: 36,
      }
    }]
  };

  const useRecord = [
    {
      name: '張安迪',
      number: 'STU001',
      year: '2022',
      studyTime: '200'
    },
    {
      name: '陳強生',
      number: 'STU002',
      year: '2022',
      studyTime: '180'
    },
    {
      name: '林新迪',
      number: 'STU003',
      year: '2022',
      studyTime: '190'
    },
    {
      name: '吳茱迪',
      number: 'STU004',
      year: '2022',
      studyTime: '200'
    },
    {
      name: '李山姆',
      number: 'STU006',
      year: '2022',
      studyTime: '200'
    },
    {
      name: '陳林達',
      number: 'STU007',
      year: '2022',
      studyTime: '180'
    },
    {
      name: '班',
      number: 'STU008',
      year: '2022',
      studyTime: '200'
    },
    {
      name: '林吉姆',
      number: 'STU009',
      year: '2022',
      studyTime: '180'
    },
    {
      name: '張凱西',
      number: 'STU010',
      year: '2022',
      studyTime: '170'
    },
  ]

  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px' }} className="scrollList">
    <div style={{ display: 'flex',}}>
      <div style={{ width: '228px', marginRight: '16px' }}>
        {(_.has(props.bookDetail, 'imgs') && props.bookDetail.imgs.length > 0) && <img alt="book" src={props.bookDetail.imgs[0].file_url} style={{ height: '153px', width: '228px', objectFit: 'cover'}} />}
      </div>
      <div style={{ width: '100%'}}>
        <div style={{ fontSize: '18px', color: props.color.textColor}}>
          {props.bookDetail.book_title}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
          <img alt="book" src={Images.book_icon} style={{ width: '26.5px', height: '25px', marginRight: '8px' }} />
          <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>{props.bookDetail.is_sell ? '上架中': '已下架'}/有效天數： {props.bookDetail.book_effective} 天</span>
          <img alt="money" src={Images.money} style={{ width: '26px', height: '26px', marginRight: '8px', marginLeft: '16px' }} />
          {props.bookDetail.book_discount !== null ? <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>${props.bookDetail.book_discount} / $<span style={{ textDecoration: 'line-through'}}>{props.bookDetail.book_price}</span></span> : <span>${props.bookDetail.book_price}</span>}
        </div>
        <div style={{ fontSize: '18px', color: props.color.textColor, height: '32px', overflow: 'hidden'}} dangerouslySetInnerHTML={{ __html: props.bookDetail.book_desc}}>
        </div>
        <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center'}}>
          <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.tags.map((child, index) => {
                return index < 3 && <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCF1DF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.tag_name}
                  </div>
              })
            }
            {props.bookDetail.tags.length > 3 && <div style={{
              width: '36px',
              height: '40px',
              lineHeight: '32px',
              borderRadius: '15px',
              backgroundColor: '#FCF1DF', 
              color: '#242933',
              textAlign: 'center',
              marginRight: '16px',
            }}>
              ...
            </div>}
          </div>
          {(props.bookDetail.tags.length > 0 && props.bookDetail.abilities.length > 0) &&<span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
          <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            {
              props.bookDetail.abilities.map((child, index) => {
                return <div 
                  style={{ 
                    fontSize: '16px', 
                    padding: '0px 12px', 
                    backgroundColor: '#FCD9BF', 
                    marginRight: '12px',
                    color: '#242933',
                    height: '40px',
                    lineHeight: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}
                  >
                  {child.ability_name}
                  </div>
              })
            }
          </div>
        </div>
      </div>
    </div>
    <div style={{ width: '100%', height: '1px', backgroundColor: '#26695C', marginTop: '8px', marginBottom: '16px' }}></div>

    <Row>
      <Col span={24} style={{height: '100%'}}>
        <div style={{ fontSize: '24px', color: props.color.eChartTitleColor, textAlign: 'center' }}>購買紀錄</div>
        <Row style={{height: '100%'}}>
          <Col span={16} style={{height: '500px'}}>
            <ReactECharts
              option={monthOption}
              ref={eChartRef}
              style={{ height: '100%', marginTop: '-30px', width: '100%' }}
              opts={{ renderer: 'svg' }}
            />
          </Col>
          <Col span={8} style={{height: '500px'}}>
            <ReactECharts
              option={yearOption}
              ref={eChartRef}
              style={{ height: '100%', marginTop: '-30px', width: '100%' }}
              opts={{ renderer: 'svg' }}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <div style={{ fontSize: '24px', color: props.color.eChartTitleColor, textAlign: 'center' }}>使用紀錄</div>
        <Row>
          <Col span={24} style={{height: '500px'}}>
            <ReactECharts
              option={useOption}
              ref={eChartRef}
              style={{ height: '100%', marginTop: '-30px', width: '100%' }}
              opts={{ renderer: 'svg' }}
            />
          </Col>
        </Row>
        <div style={{
          width: '100%',
          backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          height: '70px',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>姓名</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '400px', minWidth: '400px', textAlign: 'left'}}>學號</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%', textAlign: 'left'}}>學年度</div>
          <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '100px', textAlign: 'center'}}>總時間(分)</div>
        </div>
        {/* {props.bookDetail.chapters.map((item, index) => { */}
          {useRecord.map((item, index) => {
            return <div style={{ display: 'flex', alignItems: 'center', width: '100%',padding: '0px 16px 16px 8px', marginBottom: '16px', borderBottom: '1px solid #45C5AC'}}>
              <div style={{ fontSize: '16px', color: props.color.inputTitleColor, width: '100%', paddingLeft: '16px', }}>
                {item.name}
              </div>
              <div style={{ display: 'flex', color: props.color.inputTitleColor, minWidth: '400px', width: '400px', alignItems: 'center', overflow: 'hidden'}}>
                {item.number}
              </div>
              <div style={{ display: 'flex', color: props.color.inputTitleColor, alignItems: 'center', overflow: 'hidden', width: '100%'}}>
                {item.year}
              </div>
              <div style={{ minWidth: '100px', color: props.color.inputTitleColor, height: '50px', display: 'flex', alignItems: 'center'}}>
                {item.studyTime}
              </div>
            </div>
          })
        }


      </Col>
    </Row>

  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    bookDetail: _.get(state, 'Book.bookDetail', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    handleDeletePartItem(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART_ITEM', payload, loading, callback});
    },
    getBookById(payload, loading, callback) {
      dispatch({ type: 'GET_BOOK_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleUpdateBook(payload, loading, callback, id) {
      dispatch({ type: 'HANDLE_UPDATE_BOOK', payload, loading, callback, id});
    },
    getExamPartTopic(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_PART_TOPIC', payload, loading, callback});
    },
    handleDeletePart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART', payload, loading, callback});
    },
    handleDeleteChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_CHAPTER', payload, loading, callback});
    },
    handleCreateChapter(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_CHAPTER', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    handleSortPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_SORT_PART', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookView);
