const Reducer = (state = {
  courseList: [],
  paging: {
    page: 1,
    pageCount: 12,
  },
  courseFormList: [],
  courseDetail: {
    tags: [],
    abilities: [],
    chapters: [],
  },
  pageTopicLogList: [],
  pageTopicLogPaging: {
    page: 1,
    pageCount: 12,
  },
}, { type, payload }) => {
  switch (type) {
    case "GET_COURSE_LIST_SUCCESS":
      let temp = [];
      let tempList = [];
      payload.results.map((item) => {
        temp.push({
          ...item,
          key: item.course_id,
        });
        tempList.push({
          id: item.course_id,
          name: item.course_title
        })
      })
      
      return { ...state, courseList: temp, paging: payload.paging, courseFormList: tempList };
    case "GET_COURSE_FORM_LIST_SUCCESS":
      return { ...state, courseFormList: payload.results };
    case "GET_COURSE_BY_ID_SUCCESS":
      return { ...state, courseDetail: payload };
    case "GET_PAGE_TOPIC_LOG_SUCCESS":
      return { ...state, pageTopicLogList: payload.results, pageTopicLogPaging: payload.paging };
    default:
      return state;
  }
};

export default Reducer;
